import { sector0 } from '../data/sector0'
import { sector1 } from '../data/sector1'
import { sector2 } from '../data/sector2'
import { sector3 } from '../data/sector3'
import { sector4 } from '../data/sector4'
import { sector5 } from '../data/sector5'

export const buildPlanBattles = () => {
  let buildBattles = []

  let buildDayBattles = {
    day1: [],
    day2: [],
    day3: [],
    day4: [],
    day5: [],
    day6: [],
    day7: [],
    day8: [],
    day9: [],
    day10: [],
    day11: [],
    day12: [],
    day13: [],
    day14: []
  }

  const mergeFeats = [...sector1, ...sector2, ...sector3, ...sector4, ...sector5, ...sector0]

  mergeFeats.forEach(feat => {
    const getDefaultTeam = feat.teams.filter(team => team.default === true)

    getDefaultTeam.forEach(team => {
      buildBattles.push({
        featPlan: feat.plan,
        featKeycards: feat.keycards,
        featQty: feat.qty,
        sector: feat.sector,
        featTitle: team.boss ? `Sector ${feat.sector} - ${feat.title}` : feat.title,
        toons: team.toons,
        cheese: team.cheese,
        lowGear: team.lowGear,
        boss: team.boss,
        feats: team.feats,
        global: team.global,
        videoThumb: team.videoThumb,
        name: team.name
      })
    })
  })

  buildBattles.forEach(battle => {
    const getPlan = battle.featPlan
    const isBoss = battle.boss

    if (isBoss) {
      const findBoss = getPlan && buildDayBattles[getPlan].find(dayPlan => dayPlan.boss === isBoss)
      if (findBoss) {
        findBoss.squads.push({
          ...battle,
          name: battle.name,
          numOfBattles: 1
        })
      } else {
        buildDayBattles[getPlan].push({
          title: battle.featTitle,
          boss: battle.boss,
          sector: battle.sector,
          squads: [{
            ...battle,
            name: battle.name,
            numOfBattles: 1
          }]
        })
      }
    } else {
      getPlan && getPlan.forEach(plan => {
        let buildSquads = []
        const { qty, day, sector } = plan
        const findDayPlan = buildDayBattles[`day${day}`].find(dayPlan => dayPlan.sector === sector)

        buildSquads.push({
          ...battle,
          name: `${qty}x`,
          numOfBattles: qty
        })
        if (findDayPlan) {
          findDayPlan.squads.push(...buildSquads)
        } else {
          buildDayBattles[`day${day}`].push({
            title: `Sector ${sector} - Battles`,
            sector: sector,
            squads: buildSquads
          })
        }
      })
    }
  })

  return buildDayBattles
}