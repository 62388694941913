const day9 = [
  /* {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 901,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Tenacity Down', complete: true },
      { name: 'Decay', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Morgan', current: 10, target: 10 },
    ]
  },


  {
    title: `Sector 3 - Datacron Farming`,
    teams: [
      {
        id: 902,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        id: 903,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Geonosians', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Evasion Up', complete: true },
      { name: 'Retaliate', complete: true },
      { name: 'Acolyte', current: 14, target: 15 },
      { name: 'Geonosians', current: 10, target: 15 }
    ]
  },

  {
    title: `Sector 4 - Datacron Farming`,
    teams: [
      {
        id: 904,
        name: '2x',
        videoThumb: 'sgycYTd2Zbo?t=70&si=MTAlFTQuYoiHpTw1',
        feats: [
          { name: 'Tenacity Up', qty: 5 },
          { name: 'Kelleran', qty: 1 }
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kelleran', name: 'Kelleran', main: true },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jka', name: 'Jedi Anakin' },
        ]
      },
      {
        id: 905,
        name: '2x',
        videoThumb: 'sgycYTd2Zbo?t=302&si=yM8VDC95F5cLkqrV',
        feats: [
          { name: 'Blight', qty: 5 },          
          { name: 'Nightsisters', qty: 5 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Tenacity Up', complete: true },
      { name: 'Blight', current: 40, target: 40 },
      { name: 'Kelleran', current: 10, target: 15 },
      { name: 'Nightsisters', current: 15, target: 30 }
    ]
  },

  {
    title: `Sector 5 - Datacron Farming`,
    teams: [
      {
        id: 906,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=378&si=Nrcy93gMlcTW1Ueh',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Accuracy Up', complete: true },
      { name: 'Geo Spy', current: 8, target: 15 },
      { name: 'Plague', current: 40, target: 100 },
      { name: 'Peridea', current: 6, target: 25 }
    ]
  },


  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true },
      { name: 'Imperial Remnants', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Master QGJ', current: 11, target: 15 },
    ]
  } */
];

export {
  day9
}