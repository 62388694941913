const sector0 = [
  {
    title: 'Challenge Path',
    text: 'Defeat 250 enemies on the golden Challenge Path',
    ignore: true,
    keycards: 15,
    qty: 250,
    sector: 0,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  },
  {
    title: 'Marrok & Shin',
    text: 'Win 15 battles with Marrok & Shin in your squad',
    keycards: 15,
    qty: 15,
    sector: 0,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=1126&si=NCGln3G4z5Et7MNJ',
        global: [
          { name: 'Marrok & Shin' },
          { name: 'Dark UFUs' }
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'kru', name: 'KRU' },
          { image: 'mara', name: 'Mara' },
          { image: 'marrok', name: 'Marrok', main: true },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=991&si=qG-f61ZtfDn9BtCt',
        default: true,
        global: [{ name: 'Marrok & Shin' }],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'han', name: 'Han' },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'marrok', name: 'Marrok', main: true },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=5303&si=ko8pt89wVd0s-XsF',
        default: true,
        global: [{ name: 'Marrok & Shin' }],
        toons: [
          { image: 'lv', name: 'Lord Vader' },
          { image: 'rg', name: 'Royal Guard' },
          { image: 'piett', name: 'Piett' },
          { image: 'marrok', name: 'Marrok', main: true },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=2845&si=1SpJhE1Ndfe2rbIi',
        global: [
          { name: 'Marrok & Shin' },
          { name: 'Dark UFUs' }
        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok', main: true },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
    ]
  },
  {
    title: 'Crit Chance Down',
    text: 'Attempt to inflict Critical Chance Down 100 times',
    keycards: 15,
    qty: 100,
    sector: 0,
    plan: [
      { day: 1, qty: 2, sector: 1 },
      { day: 2, qty: 2, sector: 2 },
    ],
    teams: [
      {
        videoThumb: 'ptb8p75cmwk?t=872&si=bHcVgkwsEUlXKRp8',
        global: [
          { name: 'Nightsisters' },
          { name: 'Crit Chance Down', qty: 5 },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'morgan', name: 'Morgan' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=4480&si=ZpkatLdQmTUQRvS4',
        global: [
          { name: 'Crit Chance Down', qty: 20 },
        ],
        toons: [
          { image: 'nebit', name: 'Nebit', main: true },
          { image: 't3', name: 'T3', main: true },
          { image: 'r2', name: 'R2' },
          { image: 'bb', name: 'BB-8' },
          { image: 'gg', name: 'GG' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=4671&si=HNG5548f0TFjersw',
        default: true,
        global: [
          { name: 'Crit Chance Down', qty: 10 },
        ],
        toons: [
          { image: 'gba', name: 'GBA' },
          { image: 'sun-fac', name: 'Sun Fac', main: true },
          { image: 'poggle', name: 'Poggle' },
          { image: 'geo-soldier', name: 'Solider' },
          { image: 'geo-spy', name: 'Geo Spy' },
        ]
      },
    ]
  },
  {
    title: 'Dark UFUs',
    text: 'Win 15 battles with a full squad of Dark Side Unaligned Force Users',
    keycards: 15,
    qty: 15,
    sector: 0,
    plan: [
      { day: 1, qty: 2, sector: 1 }
    ],
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=1231&si=lqai_HUBNHavSO2B',
        global: [
          { name: 'Dark UFUs' }
        ],
        toons: [
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'sk', name: 'Starkiller' },
          { image: 'malicos', name: 'Malicos' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'eighth-brother', name: '8th Bro' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=2845&si=E2ot5-yETTwHd5RB',
        default: true,
        global: [
          { name: 'Marrok & Shin' },
          { name: 'Dark UFUs' }
        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=4775&si=lv5mIQC36_pDcqic',
        global: [
          { name: 'Dark UFUs' }
        ],
        toons: [
          { image: 'reva', name: 'Reva' },
          { image: 'gi', name: 'Grand Inquisitor' },
          { image: 'seventh-sister', name: '7th Sister' },
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'eighth-brother', name: '8th Bro' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=4910&si=WwuUUnB9kBxoicnI',
        global: [
          { name: 'Dark UFUs' }
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'starkiller', name: 'Starkiller' },
          { image: 'mara', name: 'Mara' },
          { image: 'malicos', name: 'Malicos' },
          { image: 'asajj', name: 'Asajj' }
        ]
      }
    ]
  },
  {
    title: 'Nightsisters',
    text: 'Win 15 battles with at a full squad of Nightsisters',
    keycards: 1,
    qty: 15,
    sector: 0,
    plan: [
      { day: 1, qty: 2, sector: 1 }
    ],
    dataDisk: 'War of Attrition',
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=1161&si=E0pucWH2Ykm5s_C8',
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'morgan', name: 'Morgan' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=3608&si=_RcQLy0uWTQ5-ulz',
        global: [
          { name: 'Nightsisters' }
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'morgan', name: 'Morgan' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'spirit', name: 'Spirit' },
          { image: 'peridea', name: 'Peridea' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=1597&si=aZHj9X3lI85v8F1c',
        default: true,
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'morgan', name: 'Morgan' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Galactic Republic',
    text: 'Win 15 battles with a full squad of Galactic Republic',
    keycards: 1,
    qty: 15,
    sector: 0,
    plan: [
      { day: 1, qty: 2, sector: 1 },
      { day: 2, qty: 2, sector: 2 },
      { day: 3, qty: 2, sector: 3 },
    ],
    dataDisk: 'Deployable Cooling Systems',
    teams: [
      {
        videoThumb: 'ptb8p75cmwk?t=5005&si=t2fhdI7leBY3aYpf',
        default: true,
        global: [
          { name: 'Galactic Republic' }
        ],
        toons: [
          { image: 'gas', name: 'GAS' },
          { image: 'rex', name: 'Rex' },
          { image: 'fives', name: 'Fives' },
          { image: 'echo', name: 'Echo' },
          { image: 'arc', name: 'ARC' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=5724&si=XUprGEcWmM4PeecG',
        default: true,
        global: [
          { name: 'Galactic Republic' }
        ],
        toons: [
          { image: 'padme', name: 'Padme' },
          { image: 'ahsoka', name: 'Ahsoka' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: '3po', name: 'C-3PO' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=5598&si=fxpsh8agr-zcOqHP',
        global: [
          { name: 'Galactic Republic' }
        ],
        toons: [
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'BB Echo' },
          { image: 'tech', name: 'Tech' },
          { image: 'wrecker', name: 'Wrecker' },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=5200&si=kUw17p7NSZX11gYy',
        global: [
          { name: 'Galactic Republic' }
        ],
        toons: [
          { image: 'amidala', name: 'Amidala' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'yoda', name: 'Master Yoda' },
          { image: 'padawan-obi', name: 'Padawan Obi' },
          { image: 'mace', name: 'Mace' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=5329&si=67yLLjSL9aJQB4Ne',
        global: [
          { name: 'Galactic Republic' }
        ],
        toons: [
          { image: 'jmk', name: 'Master Kenobi' },
          { image: 'plo', name: 'Plo' },
          { image: 'aayla', name: 'Aayla' },
          { image: 'cat', name: 'Commander Ahsoka' },
          { image: 'r2', name: 'R2' },
        ]
      }
    ]
  },
  {
    title: `War of Attrition`,
    text: 'Remove Turn Meter 60 times with War of Attrition',
    keycards: 15,
    qty: 20,
    ignore: true,
    sector: 0,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  },
  {
    title: `Deployable Cooling Systems`,
    text: 'Complete the battle with Deployable Cooling Systems active',
    keycards: 15,
    qty: 1,
    ignore: true,
    sector: 0,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  }
];

export {
  sector0
}