const sector4 = [
  {
    title: 'Speed Down',
    text: 'Attempt to infclit Speed Down 50 times',
    keycards: 10,
    qty: 50,
    plan: [
      { day: 4, qty: 2, sector: 4 },
      { day: 5, qty: 2, sector: 4 },
    ],
    sector: 4,
    teams: [
      {
        videoThumb: 'uWWCyolW_bQ?t=957&si=yTYBTqb6imYfbRQn',
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Night Trooper & Enoch', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'enoch', name: 'Enoch' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1224&si=IlPTIVhdWBxty4F6',
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'reva', name: 'Reva' },
          { image: 'gi', name: 'Grand Inquisitor' },
          { image: 'seventh-sister', name: '7th Sister', main: true },
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'eighth-brother', name: '8th Bro' },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1316&si=pO6qFfB7XTnv5RVX',
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'spirit', name: 'Spirit', main: true },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1449&si=h-cmSe2bHOmnaVk3',
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Galactic Republic' },
        ],
        toons: [
          { image: 'gas', name: 'GAS' },
          { image: 'fives', name: 'Fives', main: true },
          { image: 'echo', name: 'Echo' },
          { image: 'rex', name: 'Rex' },
          { image: 'arc', name: 'ARC' },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1534&si=BxYyZHQ5JA5isR_9',
        default: true,
        feats: [
          { name: 'Speed Down', qty: 20 },
          { name: 'Flawless', qty: 1 },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'vet-chewie', name: 'Vet Chewie', main: true },
          { image: 'cal', name: 'Cal', main: true },
          { image: 'jedi-cal', name: 'Jedi Cal', main: true },
          { image: 'cls', name: 'CLS', main: true  },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1780&si=Wi-kr_kXYW4UQJ-r',
        feats: [
          { name: 'Speed Down', qty: 20 },
          { name: 'Flawless', qty: 1 },
        ],
        toons: [
          { image: 'see', name: 'SEE' },
          { image: 'vader', name: 'Vader', main: true  },
          { image: 'fallen-basti', name: 'Fallen Basti', main: true },
          { image: 'phasma', name: 'Phasma', main: true },
          { image: 'malak', name: 'Malak'}
        ]
      },
    ]
  },
  {
    title: 'Night Trooper & Enoch',
    text: 'Win 10 battles with Night Trooper & Enoch in your squad',
    keycards: 10,
    qty: 10,
    plan: [
      { day: 4, qty: 4, sector: 4 },
      { day: 5, qty: 4, sector: 4 },
    ],
    sector: 4,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=588&si=KArzXGYlGTuVpfX6',
        feats: [
          { name: 'Night Trooper & Enoch', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'enoch', name: 'Enoch', main: true  },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=957&si=yTYBTqb6imYfbRQn',
        default: true,
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Night Trooper & Enoch', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'enoch', name: 'Enoch', main: true  },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=2070&si=ht1GwSv_9dSgg2Ng',
        default: true,
        feats: [
          { name: 'Night Trooper & Enoch', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'enoch', name: 'Enoch', main: true  },
          { image: 'peridea', name: 'Peridea' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'tfp', name: 'Tie Fighter' },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1990&si=A61LyQ8bkQWfg5XO',
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Night Trooper & Enoch', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'enoch', name: 'Enoch', main: true  },
          { image: 'hux', name: 'Hux' },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'phasma', name: 'Phasma' },
        ]
      },
    ]
  },
  {
    title: 'Shin',
    text: 'Win 10 battles with Shin surviving',
    keycards: 10,
    qty: 10,
    plan: [
      { day: 4, qty: 2, sector: 4 },
      { day: 5, qty: 2, sector: 4 },
      { day: 13, qty: 2, sector: 4 },
      { day: 14, qty: 2, sector: 4 },
    ],
    sector: 4,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=487&si=x5DaBvGeZ5tNEa8m',
        feats: [
          { name: 'Shin', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'padme', name: 'Padme' },
          { image: 'wat', name: 'Wat' },
          { image: 'fives', name: 'Fives' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=3415&si=COBEDbXqsUQSw-1m',
        feats: [
          { name: 'Shin', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'wat', name: 'Wat' },
          { image: 'canderous', name: 'Candy' },
          { image: 'jango', name: 'Jango' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=4072&si=j7d4ktTgr3izNRYv',
        feats: [
          { name: 'Shin', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'lv', name: 'LV' },
          { image: 'rg', name: 'Royal Guard' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=1990&si=KeTFPp6rR7laxAoz',
        default: true,
        feats: [
          { name: 'Shin', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
          { name: 'Dark UFUs' },

        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
    ]
  },
  {
    title: 'Flawless',
    text: 'Win 10 battles without losing any units',
    keycards: 10,
    qty: 10,
    plan: [
      { day: 4, qty: 2, sector: 4 },
      { day: 5, qty: 2, sector: 4 },
    ],
    sector: 4,
    teams: [
      {
        videoThumb: 'uWWCyolW_bQ?t=1449&si=h-cmSe2bHOmnaVk3',
        default: true,
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Galactic Republic' },
        ],
        toons: [
          { image: 'gas', name: 'GAS' },
          { image: 'fives', name: 'Fives', main: true },
          { image: 'echo', name: 'Echo' },
          { image: 'rex', name: 'Rex' },
          { image: 'arc', name: 'ARC' },
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Dash without using Jedi, Sith or UFUs and defeat an enemy with any Kylo Ren',
    keycards: 9,
    sectorBoss: true,
    plan: 'day4',
    sector: 4,
    teams: [
      {
        name: 'Hokey Religions',
        videoThumb: 'uWWCyolW_bQ?t=2530&si=F2Qzngy_TiOdAZoI',
        boss: 'dash',
        default: true,
        feats: [
          { name: 'Speed Down', qty: 5 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'spirit', name: 'Spirit', main: true },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        name: 'Kylo Ren',
        videoThumb: 'uWWCyolW_bQ?t=2684&si=X-YvRlVqRJbgJ4Ah',
        boss: 'dash',
        default: true,
        feats: [
          { name: 'Shin', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
          { name: 'Dark UFUs' },

        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Finn with Huyang surviving and a full squad of UFUs',
    keycards: 11,
    sectorBoss: true,
    plan: 'day5',
    sector: 4,
    teams: [
      {
        name: 'Huyang',
        videoThumb: 'uWWCyolW_bQ?t=4768&si=1d9cRcaAwjOoy6VN',
        boss: 'finn',
        default: true,
        feats: [
          { name: 'Flawless', qty: 1 },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'wat', name: 'Wat' },
          { image: 'canderous', name: 'Candy' },
          { image: 'jango', name: 'Jango' },
          { image: 'huyang', name: 'Huyang', main: true },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=5155&si=CvAVfafJis49OlcU',
        boss: 'finn',
        feats: [
          { name: 'Flawless', qty: 1 },
        ],
        toons: [
          { image: 'rjt', name: 'RJT' },
          { image: 'bb', name: 'BB-8' },
          { image: 'r2', name: 'R2' },
          { image: '3po', name: 'C-3PO' },
          { image: 'huyang', name: 'Huyang', main: true },
        ]
      },
      {
        videoThumb: 'uWWCyolW_bQ?t=5249&si=gqZUIqYxGcXTUB3z',
        boss: 'finn',
        feats: [
          { name: 'Flawless', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: '3po', name: 'C-3PO' },
          { image: 'huyang', name: 'Huyang', main: true },
        ]
      },
      {
        name: 'UFUs',
        videoThumb: 'uWWCyolW_bQ?t=4854&si=eR5WWSrtVNeyOSHc',
        boss: 'finn',
        default: true,
        feats: [
          { name: 'Shin', qty: 1 },
          { name: 'Flawless', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
          { name: 'Dark UFUs' },

        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      },
    ]
  }
];

export {
  sector4
}