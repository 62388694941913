import { buildPlanBattles } from './planBattles'
import { buildPlanProgress } from './planProgress'

export const buildCustomPlan = () => {
  const getBattles = buildPlanBattles()
  const getProgress = buildPlanProgress()

  let mergeData = {
    day1: [],
    day2: [],
    day3: [],
    day4: [],
    day5: [],
    day6: [],
    day7: [],
    day8: [],
    day9: [],
    day10: [],
    day11: [],
    day12: [],
    day13: [],
    day14: []
  }

  Object.keys(getBattles).forEach(key => {
    const getDay = getBattles[key]
    let buildSections = []
    getDay.forEach(section => {
      const findProgress = getProgress.sectorProgres[`sector${section.sector}`][key]
      buildSections.push(section)
      if (!buildSections.find(item => item.progress && section.sector === item.sector)) {
        buildSections.push({
          title: `Sector ${section.sector} - Progress`,
          sector: section.sector,
          progress: findProgress
        })
      }
    })

    const sortSections = buildSections.sort((a, b) => a.title.localeCompare(b.title))

    sortSections.push({
      title: 'Global Feats - Progress',
      sector: 0,
      progress: getProgress.globalProgress[key]
    })

    mergeData[key] = sortSections
  })

  return mergeData
}