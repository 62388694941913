export const videos = [ 
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/1VC3B96UgZg?t=816&si=gh5JyS2xvsGegYtL', creator: 'vex',
    counter: 'dt-gideon', banners: 54, squad: ['omi-bo-mandalor', 'bam', 'ig-12'], defense: ['dt-gideon', 'scout-trooper', 'gideon']
  },
  {
    name: 'Leia', value: 'gl-leia', url: 'https://youtu.be/1VC3B96UgZg?t=167&si=e4C8Cs1NaEMLvDJJ', creator: 'vex',
    counter: 'rey', banners: 57, squad: ['gl-leia', 'omi-drogan', 'r2'], defense: ['rey', 'omi-ezra-exile', 'omi-ben-solo']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/TqbqZZfzZT0?t=4951&si=FkK9HpA7uiADcjIT', creator: 'tas',
    counter: 'qgj', banners: 57, squad: ['omi-iden', 'shoretrooper', 'stormtrooper'], defense: ['omi-qgj', 'kam', 'jka']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/TqbqZZfzZT0?t=1959&si=DlrftB86R5h-rzRI', creator: 'tas',
    counter: 'malgus', banners: 55, squad: ['lv', 'rg', 'piett'], defense: ['omi-malgus', 'malak', 'dr']
  },
  {
    name: 'Maul', value: 'mando-maul', url: 'https://youtu.be/TqbqZZfzZT0?t=1527&si=I9EsqmA3DmQduRxq', creator: 'tas',
    counter: 'gas', banners: 56, squad: ['mando-maul', 'gar', 'isc'], defense: ['gas', 'ahsoka', 'eeth']
  },
  {
    name: 'Jabba', value: 'jabba', url: 'https://youtu.be/fAQTXfIDe4g?t=1840&si=Ejy4ERBQiZe0Y12O', creator: 'songeta',
    counter: 'malgus', banners: 57, squad: ['jabba', 'omi-krrsantan', 'boussh-leia'], defense: ['omi-malgus', 'marauder', 'omi-talon']
  },
  {
    name: 'Boss Nass', value: 'boss-nass', url: 'https://youtu.be/fAQTXfIDe4g?t=1450&si=c_OzLzAAMYotoYR2',creator: 'songeta',
    counter: 'finn', banners: 57, squad: ['boss-nass', 'omi-jar-jar', 'tarpals'], defense: ['finn', 'hero-finn', 'omi-zorii']
  },
  {
    name: 'lv', value: 'LV', url: 'https://youtu.be/fAQTXfIDe4g?t=260&si=-81mC9QN3vWr1w6-', creator: 'songeta',
    counter: 'gm', banners: 56, squad: ['lv', 'maul', 'rg'], defense: ['gm', 'omi-morgan', 'spirit']
  },
  {
    name: 'Amidala', value: 'amidala', url: 'https://youtu.be/w03Zzt8DBZU?t=2680&si=gPF2txwIJrTEUGFe', creator: 'vex',
    counter: 'dt-gideon', banners: 57, squad: ['omi-amidala', 'master-qgj', 'padawan-obi'], defense: ['dt-gideon', 'scout-trooper', 'gideon']
  },
  {
    name: 'Padme', value: 'padme', url: 'https://youtu.be/w03Zzt8DBZU?t=1919&si=LhNiBDHZ58rOGxvI', creator: 'vex',
    counter: 'cere', banners: 56, squad: ['padme', 'gideon', 'shaak-ti'], defense: ['omi-cere', 'omi-malicos', 'cal']
  },
  {
    name: 'Baylen', value: 'baylen', url: 'https://youtu.be/w03Zzt8DBZU?t=1239&si=JLLPtdfNSJ6z5xoJ', creator: 'vex',
    counter: 'leia-organa', banners: 52, squad: ['omi-baylen', 'shin', 'marrok'], defense: ['gl-leia', 'drogan', 'sth']
  },
  {
    name: 'Leia', value: 'gl-leia', url: 'https://youtu.be/w03Zzt8DBZU?t=1053&si=n92BCPADlEtYdZZZ', creator: 'vex',
    counter: 'jml', banners: 57, squad: ['gl-leia', 'omi-drogan', 'r2'], defense: ['jml', 'plo', 'omi-ezra-exile']
  },
  {
    name: 'Mando Maul', value: 'mando-maul', url: 'https://youtu.be/DFlsFQz4HRs?t=7075&si=-nN_gm0A6AOqkSze', creator: 'tas',
    counter: 'kelleran', banners: 54, squad: ['mando-maul', 'gar', 'isc'], defense: ['kelleran', 'gmy', 'eeth']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/DFlsFQz4HRs?t=6162&si=QYMPoQqvhQzRbchh', creator: 'tas',
    counter: 'tusken-chief', banners: 56, squad: ['lv', 'rg', 'piett'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/DFlsFQz4HRs?t=5728&si=T2n6CLqzWdYEoXRd', creator: 'tas',
    counter: 'saw', banners: 51, squad: ['see', 'wat', 'set'], defense: ['omi-saw', 'omi-luthen', 'kyle']
  },
  {
    name: 'Enoch', value: 'enoch', url: 'https://youtu.be/DFlsFQz4HRs?t=2008&si=c-tJm0rsw_HrfuRD', creator: 'tas',
    counter: 'jabba', banners: 54, squad: ['enoch', 'omi-night-trooper', 'peridea'], defense: ['jabba', 'krrsantan', 'boussh-leia']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/amhEPFWOnBE?t=1860&si=bLbDZ6cmNf2tG26a',
    counter: 'gas', banners: 59, creator: 'songeta',
    squad: ['lv'], defense: ['gas', 'echo', 'arc']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/amhEPFWOnBE?t=1640&si=9R0DQq0nduDNPQdX', creator: 'songeta',
    counter: 'finn', banners: 54, squad: ['omi-bo-mandalor', 'paz', 'ig-12'], defense: ['finn', 'hero-finn', 'omi-zorii']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/amhEPFWOnBE?t=1195&si=-5Ij2T5ens1nAAB1', creator: 'songeta',
    counter: 'saw', banners: 57, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['omi-saw', 'omi-luthen', 'omi-drogan']
  },
  {
    name: 'Boss Nass', value: 'boss-nass', url: 'https://youtu.be/pqyogEphh24?t=2255&si=fgdoiqehu6rUoEgq', creator: 'songeta',
    counter: 'kelleran', banners: 57, squad: ['boss-nass', 'omi-jar-jar', 'tarpals'], defense: ['kelleran', 'shaak-ti', 'eeth']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://youtu.be/pqyogEphh24?t=2040&si=yNJNsEb5pdjvhi3T', creator: 'songeta',
    counter: 'tusken-chief', banners: 57, squad: ['jkr', 'jedi-cal', 'jolee'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/pqyogEphh24?t=720&si=yGgYb9j7Q_f0W1YK', creator: 'songeta',
    counter: 'rey', banners: 52, squad: ['omi-cere', 'omi-malicos', 'omi-starkiller'], defense: ['rey', 'omi-ezra-exile', 'omi-ben-solo']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://youtu.be/BWJoDuDVbXk?t=1922&si=RCTllV3miCa1EqdX', creator: 'vex',
    counter: 'mando-maul', banners: 53, squad: ['omi-qgj', 'jka', 'kam'], defense: ['mando-maul', 'gar', 'isc']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/UG26Kn-5RVM?t=1868&si=scNFDEVpmMrlBtqX', creator: 'vex',
    counter: 'malgus', banners: 59, squad: ['see'], defense: ['omi-malgus', 'marauder', 'set']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/8cmm4QKUkv4?t=2010&si=dFbO71SmYb-Oz4CW', creator: 'vex',
    counter: 'tusken-chief', banners: 59, squad: ['lv'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/UG26Kn-5RVM?t=439&si=lhKhR7b4vAXHU6yr', creator: 'vex',
    counter: 'bo-mandalor', banners: 57, squad: ['omi-traya', 'omi-savage', 'nihilus'], defense: ['omi-bo-mandalor', 'paz', 'ig-12']
  },
  {
    name: 'DR', value: 'dr', url: 'https://youtu.be/UG26Kn-5RVM?t=128&si=Zjzm4FG6RE5EDHGo', creator: 'vex',
    counter: 'gm', banners: 57, squad: ['dr', 'malak', 'fallen-basti'], defense: ['gm', 'morgan', 'omi-night-trooper']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/K-aIYy-SJJA?t=2255&si=Qi2py4uXhOmTSprk', creator: 'songeta',
    counter: 'boss-nass', banners: 57, squad: ['jml', 'jedi-cal', 'hermit'], defense: ['boss-nass', 'phalanx', 'omi-jar-jar']
  },  
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/K-aIYy-SJJA?t=1720&si=szglaM-R4SEgFash', creator: 'songeta',
    counter: 'talzin', banners: 57, squad: ['gas', 'ahsoka', 'arc'], defense: ['talzin', 'omi-merrin', 'zombie']
  },
  {
    name: 'Trench', value: 'trench', url: 'https://youtu.be/K-aIYy-SJJA?t=1520&si=LnkdxN5OMhFFQK5J', creator: 'songeta',
    counter: 'qgj', banners: 56, squad: ['trench', 'wat', 'jango'], defense: ['omi-qgj', 'jka', 'gk']
  },  
  {
    name: 'Cere', value: 'cere', url: 'https://www.youtube.com/watch?v=VndmhasflP0&t=5637s', creator: 'tas',
    counter: 'tusken-chief', banners: 57, squad: ['omi-cere', 'omi-malicos', 'fulcrum'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'Talzin', value: 'talzin', url: 'https://www.youtube.com/watch?v=VndmhasflP0&t=5270s', creator: 'tas',
    counter: 'finn', banners: 57, squad: ['talzin', 'omi-merrin', 'zombie'], defense: ['finn', 'omi-rose', 'omi-zorii']
  },
  {
    name: 'DTMG', value: 'dt-gideon', url: 'https://youtu.be/RWMGBUXHmWc?t=6451&si=A0xw3QLJPJVh9mBb', creator: 'tas',
    counter: 'dash', banners: 57, squad: ['dt-gideon', 'gideon', 'scout-trooper'], defense: ['dash', 'omi-hondo', 'vandor-chewie']
  },
  {
    name: 'GG', value: 'gg', url: 'https://youtu.be/RWMGBUXHmWc?t=5755&si=wpdCPDq8sGKKoRrT', creator: 'tas',
    counter: 'qgj', banners: 52, squad: ['gg', 'omi-stap', 'b1'], defense: ['omi-qgj', 'kam', 'jka']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/RWMGBUXHmWc?t=4953&si=141FzTF0FCEFbOEe', creator: 'tas',
    counter: 'gm', banners: 57, squad: ['omi-bane', 'set'], defense: ['gm', 'morgan', 'night-trooper']
  },
  {
    name: 'Trench', value: 'trench', url: 'https://youtu.be/DnBv2ZPE2HQ?t=2460&si=9wintvOGnrp8PLE2', creator: 'songeta',
    counter: 'talzin', banners: 57, squad: ['trench', 'wat', 'jango'], defense: ['talzin', 'omi-merrin', 'daka']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://youtu.be/h0mFm--JW6g?t=2281&si=nbTyi5uzGYFJzMsQ', creator: 'vex',
    counter: 'admiralraddus', banners: 57, squad: ['jkr', 'jedi-cal', 'gas'], defense: ['omi-raddus', 'jyn', 'k2']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/h0mFm--JW6g?t=2137&si=C4sN3DeIMt4B2jz7', creator: 'vex',
    counter: 'leia-organa', banners: 55, squad: ['jml', 'jkl', 'plo'], defense: ['leia-organa', 'kanan', 'omi-drogan']
  },
  {
    name: 'Baylen', value: 'baylen', url: 'https://youtu.be/h0mFm--JW6g?t=1869&si=BtUuPTW6v406K-Hz', creator: 'vex',
    counter: 'gm', banners: 57, squad: ['omi-baylen', 'shin', 'marrok'], defense: ['gm', 'omi-morgan', 'spirit']
  },
  {
    name: 'Boss Nass', value: 'boss-nass', url: 'https://youtu.be/h0mFm--JW6g?t=1748&si=ZzevBeA38lVGh0ma', creator: 'vex',
    counter: 'dash', banners: 57, squad: ['boss-nass', 'phalanx', 'omi-jar-jar'], defense: ['dash', 'omi-hondo', 'vandor-chewie']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/oEIZiZ8Y8fs?t=2520&si=pWm_T3-N2VW7RnLx', creator: 'vex',
    counter: 'enoch', banners: 54, squad: ['cls', 'threepio', '3po'], defense: ['enoch', 'peridea', 'night-trooper']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/h0mFm--JW6g?t=621&si=BF5iWgjwwwyzMLp1', creator: 'vex',
    counter: 'kelleran', banners: 57, squad: ['omi-malgus', 'dr', 'fallen-basti'], defense: ['kelleran', 'mace', 'eeth']
  },
  {
    name: 'Enoch', value: 'enoch', url: 'https://youtu.be/h0mFm--JW6g?t=257&si=g08yiPVdBLcmWhnc', creator: 'vex',
    counter: 'bossk', banners: 55, squad: ['enoch', 'peridea', 'night-trooper'], defense: ['bossk', 'omi-zam', 'dengar']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/DnBv2ZPE2HQ?t=920&si=ox9AmIite7GqHDLW', creator: 'songeta',
    counter: 'tusken-chief', banners: 55, squad: ['omi-bo-mandalor', 'ig-12', 'bam'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/j1OrZoavd9s?t=8164&si=855oSx47TFd8ldl7', creator: 'tas',
    counter: 'kelleran', banners: 57, squad: ['lv', 'rg', 'mando-maul'], defense: ['kelleran', 'mace', 'eeth']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/j1OrZoavd9s?t=7341&si=Iig-f1aI2t95eBqY', creator: 'tas',
    counter: 'leia-organa', banners: 55, squad: ['slkr', 'omi-malicos', 'kru'], defense: ['leia-organa', 'r2', 'omi-drogan']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/j1OrZoavd9s?t=6004&si=jJOxhorA0DJhbLHz', creator: 'tas',
    counter: 'saw', banners: 57, squad: ['jml', 'jkr', 'jedi-cal'], defense: ['omi-saw', 'omi-luthen', 'kyle']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/j1OrZoavd9s?t=3310&si=qVv38-7Xx2X1Fy35', creator: 'tas',
    counter: 'baylen', banners: 54, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['omi-baylen', 'shin', 'marrok']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/j1OrZoavd9s?t=1633&si=ZXAsnYEWp4SsBMo3', creator: 'tas',
    counter: 'rey', banners: 50, squad: ['palpatine', 'mara', 'omi-starkiller'], defense: ['rey', 'omi-ezra-exile', 'omi-ben-solo']
  },
  {
    name: 'Enoch', value: 'enoch', url: 'https://youtu.be/AuzvlkOjvUQ?t=1196&si=dU8g4Ch1H4Yf2UE_', creator: 'vex',
    counter: 'trench', banners: 54, squad: ['enoch', 'peridea', 'night-trooper'], defense: ['trench', 'nute', 'jango']
  },
  {
    name: 'DTMG', value: 'dt-gideon', url: 'https://youtu.be/AuzvlkOjvUQ?t=1082&si=ke4dUvnLdJPgZ33x', creator: 'vex',
    counter: 'saw', banners: 56, squad: ['dt-gideon', 'gideon', 'scout-trooper'], defense: ['omi-saw', 'omi-luthen', 'kyle']
  },
  {
    name: 'JMK', value: 'jmk', url: 'https://youtu.be/AuzvlkOjvUQ?t=682&si=KOcOULma_xyvpwzZ', creator: 'vex',
    counter: 'leia-organa', banners: 57, squad: ['jmk', 'cat', 'plo'], defense: ['leia-organa', 'r2', 'omi-drogan']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://youtu.be/AuzvlkOjvUQ?t=589&si=pcFA_3psG1K_6sjG', creator: 'vex',
    counter: 'palpatine', banners: 54, squad: ['jkr', 'jedi-cal', 'jedi-basti'], defense: ['palpatine', 'mara', 'omi-savage']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/oEIZiZ8Y8fs?t=2635&si=G4eWFiXoBdASaNgM', creator: 'songeta',
    counter: 'rex', banners: 57, squad: ['omi-cere', 'kru', 'omi-malicos'], defense: ['rex', 'fives', 'omi-captain-rex']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/oEIZiZ8Y8fs?t=2520&si=pWm_T3-N2VW7RnLx', creator: 'songeta',
    counter: 'enoch', banners: 56, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['enoch', 'peridea', 'stormtrooper']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/oEIZiZ8Y8fs?t=1660&si=lPOcjijJs2WZaRbL', creator: 'songeta',
    counter: 'kelleran', banners: 54, squad: ['cls', 'threepio', '3po'], defense: ['kelleran', 'shaak-ti', 'eeth']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/x8l8D84FKCM?t=2225&si=vejslW9SDmwJ6tnl', creator: 'songeta',
    counter: 'admiralraddus', banners: 55, squad: ['omi-bane', 'set'], defense: ['omi-raddus', 'jyn', 'pao']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/x8l8D84FKCM?t=2145&si=na8C89sjGw7MXLw7', creator: 'songeta',
    counter: 'rex', banners: 56, squad: ['omi-reva', 'seventh-sister', 'gi'], defense: ['rex', 'fives', 'omi-captain-rex']
  },
  {
    name: 'Aphra', value: 'aphra', url: 'https://youtu.be/x8l8D84FKCM?t=1920&si=VP2bbjDqO28NQiG1', creator: 'songeta',
    counter: 'gas', banners: 51, squad: ['omi-aphra', 'omi-hondo', 'bt-1'], defense: ['gas', 'echo', 'arc']
  },
  {
    name: 'Great Mothers', value: 'gm', url: 'https://youtu.be/x8l8D84FKCM?t=950&si=LuIEvQmjIOvKVIbW', creator: 'songeta',
    counter: 'boss-nass', banners: 57, squad: ['gm', 'morgan', 'omi-merrin'], defense: ['boss-nass', 'phalanx', 'omi-jar-jar']
  },  
  {
    name: 'Boss Nass', value: 'boss-nass', url: 'https://youtu.be/x8l8D84FKCM?t=165&si=12lDq0QGuoyxWDvM', creator: 'songeta',
    counter: 'baylen', banners: 57, squad: ['boss-nass', 'phalanx', 'omi-jar-jar'], defense: ['omi-baylen', 'shin', 'marrok']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/_aZaf942vnQ?t=2706&si=wCFJ9YekZITylaw1', creator: 'vex',
    counter: 'admiralraddus', banners: 55, squad: ['omi-bo-mandalor', 'ig-12', 'paz'], defense: ['omi-raddus', 'jyn', 'pathfinder']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/j1OrZoavd9s?t=6004&si=bH6yUzxuE_gxpqMc', creator: 'vex',
    counter: 'saw', banners: 57, squad: ['jml', 'jkl', 'shaak-ti'], defense: ['omi-saw', 'omi-drogan', 'pathfinder']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/_aZaf942vnQ?t=1834&si=DmgTBq1Ei3FmEIA-', creator: 'vex',
    counter: 'boss-nass', banners: 56, squad: ['mothma', 'pao', 'pathfinder'], defense: ['boss-nass', 'phalanx', 'omi-jar-jar']
  },  
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/osyooZNXSIo?t=5607&si=Mg-9yYNgDk9C1V0K',
    counter: 'trench', banners: 57, squad: ['omi-traya', 'omi-savage', 'nihilus'], defense: ['trench', 'dooku', 'wat']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/osyooZNXSIo?t=5336&si=I8L-eCGk-jI9a6Jx', creator: 'tas',
    counter: 'kelleran', banners: 57, squad: ['see', 'set'], defense: ['kelleran', 'mace', 'eeth']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/osyooZNXSIo?t=5073&si=lX5WqlTGKmc6WLmS', creator: 'tas',
    counter: 'ben', banners: 57, squad: ['cls', 'han', 'chewie'], defense: ['ben', 'omi-hondo', 'l3']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/osyooZNXSIo?t=5073&si=JCRA6oxY3rt6Gkz2', creator: 'tas',
    counter: 'qgj', banners: 55, squad: ['omi-bo-mandalor', 'ig-12', 'paz'], defense: ['omi-qgj', 'kam', 'jka']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/osyooZNXSIo?t=4790&si=S0bmetM60w-obHy4', creator: 'tas',
    counter: 'talzin', banners: 59, squad: ['omi-wampa'], defense: ['talzin', 'daka', 'omi-asajj']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/osyooZNXSIo?t=1802&si=4BAIwKs-8Xbasi4P', creator: 'tas',
    counter: 'leia-organa', banners: 55, squad: ['omi-bane', 'malak',], defense: ['leia-organa', 'r2', 'omi-drogan']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/Li1oT4qGM5U?t=2130&si=EZgW2dkUC96jcHev', creator: 'songeta',
    counter: 'saw', banners: 54, squad: ['omi-bo-mandalor', 'ig-12', 'paz'], defense: ['omi-saw', 'omi-luthen', 'kyle']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/Li1oT4qGM5U?t=2000&si=7zxb7arfI1yFKO_v', creator: 'songeta',
    counter: 'kelleran', banners: 57, squad: ['dt-gideon', 'scout-trooper', 'gideon'], defense: ['kelleran', 'gk', 'eeth']
  },
  {
    name: 'Boss Nass', value: 'boss-nass', url: 'https://youtu.be/Li1oT4qGM5U?t=1915&si=js9NCDBoFiHpMjjs', creator: 'songeta',
    counter: 'qgj', banners: 57, squad: ['boss-nass', 'tarpals', 'omi-jar-jar'], defense: ['omi-qgj', 'jka', 'gk']
  },  
  {
    name: 'Aphra', value: 'aphra', url: 'https://youtu.be/Li1oT4qGM5U?t=1530&si=jEsv6pMFpbktTWWp', creator: 'songeta',
    counter: 'palpatine', banners: 54, squad: ['aphra', 'trip', 'bt-1'], defense: ['palpatine', 'mara', 'omi-savage']
  },
  {
    name: 'Raddus', value: 'raddus', url: 'https://youtu.be/Li1oT4qGM5U?t=1315&si=_ZxYgwStZcejlw2D', creator: 'songeta',
    counter: 'dt-gideon', banners: 57, squad: ['omi-raddus', 'jynn', 'k2'], defense: ['dt-gideon', 'scout-trooper', 'gideon']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/OfCEU3k-gEY?t=154&si=9UvGRw6YjxJVPJfz', creator: 'songeta',
    counter: 'jabba', banners: 57, squad: ['lv', 'mando-maul', 'thrawn'], defense: ['jabba', 'omi-krrsantan', 'boussh-leia']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/dJ6vSWqyGfQ?t=2435&si=Dc7XvBPXoM4ltUc1', creator: 'vex',
    counter: '50rt', banners: 50, squad: ['gas', 'ahsoka', 'arc'], defense: ['50rt', 'l3', 'ipd']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/dJ6vSWqyGfQ?t=2315&si=5y-RnatOdp_vlSCu', creator: 'vex',
    counter: 'trench', banners: 58, squad: ['see'], defense: ['trench', 'dooku', 'jango']
  },
  {
    name: 'Leia', value: 'leia-organa', url: 'https://youtu.be/8cmm4QKUkv4?t=1359&si=D_ysDL4-i2qWsInJ', creator: 'vex',
    counter: 'amidala', banners: 57, squad: ['leia-organa', 'r2', 'omi-drogan'], defense: ['omi-amidala', 'master-qgj', 'padawan-obi']
  },
  {
    name: 'Savage', value: 'savage', url: 'https://youtu.be/dJ6vSWqyGfQ?t=1638&si=HB6ihrymKk1B6AOJ', creator: 'vex',
    counter: 'boss-nass', banners: 57, squad: ['omi-savage', 'omi-traya', 'talon'], defense: ['boss-nass', 'phalanx', 'omi-jar-jar']
  },  
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/dJ6vSWqyGfQ?t=1503&si=l1qmHNpgHCylspd_', creator: 'vex',
    counter: 'saw', banners: 55, squad: ['omi-bane', 'sith-assassin'], defense: ['omi-saw', 'omi-luthen', 'pathfinder']
  },
  {
    name: 'Dark Gideon', value: 'dt-gideon', url: 'https://youtu.be/dJ6vSWqyGfQ?t=1292&si=dDOjl6JYqEJABbsF', creator: 'vex',
    counter: 'gg', banners: 56, squad: ['dt-gideon', 'scout-trooper', 'death-trooper'], defense: ['gg', 'omi-stap', 'b2']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/dJ6vSWqyGfQ?t=1139&si=8-Fj_ukXd59ciQI9', creator: 'vex',
    counter: 'baylen', banners: 57, squad: ['omi-cere', 'omi-malicos', 'cal'], defense: ['omi-baylen', 'shin', 'dash']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/dJ6vSWqyGfQ?t=342&si=Jutfc4BkwHUc-55r', creator: 'vex',
    counter: 'lv', banners: 55, squad: ['omi-bo-mandalor', 'ig-12', 'paz'], defense: ['lv', 'mando-maul', 'ninth-sister']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/zIPWnCBAXKg?t=3163&si=1IHmk5y5puJboQ04', creator: 'vex',
    counter: 'kelleran', banners: 57, squad: ['omi-cere', 'omi-malicos', 'cal'], defense: ['kelleran', 'mace', 'shaak-ti']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/zIPWnCBAXKg?t=3054&si=Sl6lEOMPodVrrdcn', creator: 'vex',
    counter: 'gas', banners: 55, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['gas', 'ahsoka', 'eeth']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/zIPWnCBAXKg?t=2705&si=8Ch9C33ijeB2-En1', creator: 'vex',
    counter: 'carth', banners: 57, squad: ['omi-traya', 'nihilus', 'omi-savage'], defense: ['carth', 'zaalbar', 'mission']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/zIPWnCBAXKg?t=2598&si=j17v49L55zA6q-uU', creator: 'vex',
    counter: 'boss-nass', banners: 54, squad: ['omi-bo-mandalor', 'ig-12', 'paz'], defense: ['boss-nass', 'phalanx', 'omi-jar-jar']
  },  
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/zIPWnCBAXKg?t=1906&si=yYM41HqFrS2YA1DG', creator: 'vex',
    counter: 'bastila', banners: 58, squad: ['see'], defense: ['jedi-basti', 'jml', 'wat']
  },
  {
    name: 'Mirror', value: 'leia', url: 'https://youtu.be/zIPWnCBAXKg?t=724&si=ZGfKMSQGiRALl9kM', creator: 'vex',
    counter: 'leia-organa', banners: 57, squad: ['leia-organa', 'r2', 'omi-drogan'], defense: ['leia-organa', 'r2', 'omi-drogan']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/zIPWnCBAXKg?t=588&si=6f7IOC_qAduYa5oe', creator: 'vex',
    counter: 'baylen', banners: 51, squad: ['slkr', 'hux', 'kru'], defense: ['omi-baylen', 'shin', 'marrok']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/8cmm4QKUkv4?t=2771&si=6phFITKhmG__fd-t',
    counter: 'qgj', banners: 59, squad: ['lv'], defense: ['omi-qgj', 'kam', 'jka']
  },
  {
    name: 'Maul', value: 'maul', url: 'https://youtu.be/8cmm4QKUkv4?t=2711&si=atzVWP4Jw7VnqPCS',
    counter: 'finn', banners: 56, squad: ['mando-maul', 'gar', 'isc'], defense: ['finn', 'hero-finn', 'omi-zorii']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/8cmm4QKUkv4?t=2623&si=d6757Q7_UDPbH2_y',
    counter: 'kelleran', banners: 54, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['kelleran', 'gmy', 'plo']
  },
  {
    name: 'Raddus', value: 'raddus', url: 'https://youtu.be/8cmm4QKUkv4?t=2429&si=QGsaycJz0buWZcDu',
    counter: 'boss-nass', banners: 54, squad: ['omi-raddus', 'baze', 'jyn'], defense: ['boss-nass', 'boomadier', 'omi-jar-jar']
  },  
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/8cmm4QKUkv4?t=2010&si=dFbO71SmYb-Oz4CW',
    counter: 'tusken-chief', banners: 57, squad: ['jml', 'jkr', 'jkl'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/8cmm4QKUkv4?t=1883&si=1VHdtU5WRMnsCvDW',
    counter: 'saw', banners: 55, squad: ['omi-cere', 'omi-starkiller', 'omi-malicos'], defense: ['omi-saw', 'omi-drogan', 'omi-luthen']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/8cmm4QKUkv4?t=1740&si=biQYlg8qUfcjdbBQ',
    counter: 'enoch', banners: 56, squad: ['omi-malgus', 'malak', 'dr'], defense: ['enoch', 'peridea', 'omi-night-trooper']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/8cmm4QKUkv4?t=1359&si=D_ysDL4-i2qWsInJ',
    counter: 'amidala', banners: 55, squad: ['omi-bane', 'sith-trooper'], defense: ['omi-amidala', 'master-qgj', 'padawan-obi']
  },
  {
    name: 'Bo Mandalor', value: 'bo-mandalor', url: 'https://youtu.be/8cmm4QKUkv4?t=977&si=scm23BiRkJ59P1DN',
    counter: 'reva', banners: 54, squad: ['omi-bo-mandalor', 'ig-12', 'bam'], defense: ['omi-reva', 'gi', 'seventh-sister']
  },
  {
    name: 'JMK', value: 'jmk', url: 'https://youtu.be/8cmm4QKUkv4?t=416&si=iPpGc2DxMDLiLDIW',
    counter: 'gm', banners: 53, squad: ['jmk', 'cat', 'ahsoka'], defense: ['gm', 'omi-morgan', 'spirit']
  },
  {
    name: 'Raddus', value: 'raddus', url: 'https://youtu.be/Al6_n-Ek2X8?t=1612&si=UiYMy3JaMnMqq8kE',
    counter: 'qgj', banners: 56, squad: ['omi-raddus', 'jyn', 'cassian'], defense: ['omi-qgj', 'jka', 'gk']
  },
  {
    name: 'Aphra', value: 'aphra', url: 'https://youtu.be/Al6_n-Ek2X8?t=1496&si=aTJiAM1JC0wHTDiZ',
    counter: 'saw', banners: 53, squad: ['aphra', 'bt-1', 'trip'], defense: ['omi-saw', 'kyle', 'cara']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://youtu.be/Al6_n-Ek2X8?t=1396&si=M8v5VM35L3en5jQ1',
    counter: 'trench', banners: 57, squad: ['jkr', 'jkl', 'jedi-cal'], defense: ['trench', 'nute', 'wat']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/Al6_n-Ek2X8?t=1045&si=DPHDNhryQ9LSVPYD',
    counter: 'dt-gideon', banners: 57, squad: ['omi-malgus', 'malak', 'dr'], defense: ['dt-gideon', 'scout-trooper', 'omi-night-trooper']
  },
  {
    name: 'Leia', value: 'leia-organa', url: 'https://youtu.be/Al6_n-Ek2X8?t=681&si=oSCjsOTHFZuwbGov',
    counter: 'boss-nass', banners: 56, squad: ['leia-organa', 'r2', 'drogan'], defense: ['boss-nass', 'phalanx', 'omi-jar-jar']
  },  
  {
    name: 'Dark Gideon', value: 'dt-gideon', url: 'https://youtu.be/EAEwaUuIxNg?t=920&si=hnCZxpZRdzyxANVk',
    counter: 'cls', banners: 57, squad: ['dt-gideon', 'scout-trooper', 'gideon'], defense: ['cls', 'threepio', '3po']
  },
  {
    name: 'Talzin', value: 'talzin', url: 'https://youtu.be/sAXmThXbrYo?t=300&si=mWcDjVWIZ7dRjMkN',
    counter: 'jabba', banners: 51, squad: ['talzin', 'omi-merrin', 'asajj'], defense: ['jabba', 'krrsantan', 'boussh-leia']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/OfCEU3k-gEY?t=1074&si=jTukJjClNqwjxGL4',
    counter: 'rex', banners: 54, squad: ['omi-malgus', 'sith-assassin', 'marauder'], defense: ['rex', 'fives', 'omi-captain-rex']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/OfCEU3k-gEY?t=1005&si=s2EY_wKVeCTIgEN7',
    counter: 'kelleran', banners: 56, squad: ['jml', 'gmy', 'shaak-ti'], defense: ['kelleran', 'gmy', 'kam']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/OfCEU3k-gEY?t=840&si=OiHAbJNINziHP3TQ',
    counter: 'leia-organa', banners: 51, squad: ['slkr', 'armorer', 'gba'], defense: ['leia-organa', 'r2', 'drogan']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/OfCEU3k-gEY?t=774&si=kxOnv7jZPKcQNgeN',
    counter: 'reva', banners: 57, squad: ['omi-cere', 'omi-malicos', 'cal'], defense: ['omi-reva', 'gi', 'eighth-brother']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/OfCEU3k-gEY?t=625&si=-s7k_RiVQwkBCvtk',
    counter: 'rey', banners: 56, squad: ['omi-cere', 'omi-malicos', 'omi-starkiller'], defense: ['rey', 'omi-ben-solo', 'cal']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/OfCEU3k-gEY?t=543&si=EBczanBAlbmd5BMT',
    counter: 'saw', banners: 55, squad: ['omi-traya', 'nihilus', 'omi-savage'], defense: ['omi-saw', 'chirrut', 'baze']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/OfCEU3k-gEY?t=471&si=ZM-zRPNszSRoLWWN',
    counter: 'cere', banners: 58, squad: ['see'], defense: ['omi-cere', 'omi-malicos', 'cal']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/OfCEU3k-gEY?t=267&si=pAtk8G6sxqXZopIV',
    counter: 'rey', banners: 54, squad: ['omi-bane', 'set'], defense: ['rey', 'omi-ben-solo', 'cal']
  },
  {
    name: 'Raddus', value: 'raddus', url: 'https://youtu.be/oaXmbdPuyi8?t=744&si=Adsv1VPaRzCDibKn',
    counter: 'vader', banners: 57, squad: ['omi-raddus', 'jyn', 'pathfinder'], defense: ['vader', 'talon', 'malak']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/oaXmbdPuyi8?t=670&si=G0cTd0f7EVSp3AD_',
    counter: 'rex', banners: 57, squad: ['jkr', 'jkl', 'jedi-cal'], defense: ['rex', 'fives', 'omi-captain-rex']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://youtu.be/oaXmbdPuyi8?t=542&si=rA6d9aLhgacARY5D',
    counter: 'mando-maul', banners: 55, squad: ['jkr', 'jkl', 'jolee'], defense: ['mando-maul', 'gar', 'isc']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/oaXmbdPuyi8?t=473&si=F46VWo2y-gr0OGFw',
    counter: 'tusken-chief', banners: 50, squad: ['gas', 'fives', 'rex'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'Talzin', value: 'talzin', url: 'https://youtu.be/d1p_MFe5X4s?t=1230&si=eAYSYS-l8Cyg1nqS',
    counter: 'cls', banners: 56, squad: ['talzin', 'omi-merrin', 'zombie'], defense: ['cls', '3po', 'threepio']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/d1p_MFe5X4s?t=891&si=DevA6m2gpqVyHJ3y',
    counter: 'gas', banners: 57, squad: ['omi-traya', 'nihilus', 'omi-savage'], defense: ['gas', 'echo', 'arc']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/d1p_MFe5X4s?t=742&si=XuW25fRAWPrp_iVT',
    counter: 'dt-gideon', banners: 57, squad: ['dr', 'malak', 'fallen-basti'], defense: ['dt-gideon', 'scout-trooper', 'death-trooper']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/d1p_MFe5X4s?t=551&si=5UspB-ymcMA2VXFC',
    counter: 'reva', banners: 52, squad: ['see', 'daka', 'zombie'], defense: ['omi-reva', 'gi', 'eighth-brother']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/d1p_MFe5X4s?t=467&si=5odDuBn_7opp1WfV',
    counter: 'bo-mandalor', banners: 53, squad: ['see', 'wat',], defense: ['omi-bo-mandalor', 'paz', 'ig-12']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/d1p_MFe5X4s?t=389&si=klsNWJJb4O3f9VfK',
    counter: 'cere', banners: 56, squad: ['omi-bane', 'maul',], defense: ['omi-cere', 'omi-malicos', 'kru']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/d1p_MFe5X4s?t=304&si=9y1osbSjKcS_0Q2Q',
    counter: 'leia-organa', banners: 55, squad: ['omi-bane', 'maul',], defense: ['leia-organa', 'r2', 'omi-drogan']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/d1p_MFe5X4s?t=245&si=PAIXhonLGsjcgvRt',
    counter: 'reva', banners: 55, squad: ['omi-bane', 'maul',], defense: ['omi-reva', 'gi', 'eighth-brother']
  },
  {
    name: 'Raddus', value: 'raddus', url: 'https://youtu.be/_lhQ_srFHeM?t=886&si=oe1NJBikjmHaWQT0',
    counter: 'gi', banners: 57, squad: ['omi-raddus', 'jyn', 'baze'], defense: ['gi', 'seventh-sister', 'second-sister']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/_lhQ_srFHeM?t=814&si=G9a5h0LlcuHpimX7',
    counter: 'trench', banners: 57, squad: ['cls', '3po', 'threepio'], defense: ['trench', 'sun-fac', 'gba']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/_lhQ_srFHeM?t=738&si=LC0A4xZjfGZw4asL',
    counter: 'savage', banners: 57, squad: ['hunter', 'tech', 'bb-echo'], defense: ['omi-savage', 'talon', 'marauder']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/_lhQ_srFHeM?t=664&si=rzY-qBf2nx1P3B4W',
    counter: 'dt-gideon', banners: 57, squad: ['hunter', 'tech', 'bb-echo'], defense: ['dt-gideon', 'stormtrooper', 'dark-trooper']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/_lhQ_srFHeM?t=550&si=GNAOVM6peCRs_g2l',
    counter: 'cere', banners: 56, squad: ['omi-malgus', 'malak', 'dr'], defense: ['omi-cere', 'omi-malicos', 'kylo']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/_lhQ_srFHeM?t=422&si=DBFEp2fN_pKf17mu',
    counter: 'malgus', banners: 57, squad: ['jedi-basti', 'mace', 'jedi-cal'], defense: ['omi-malgus', 'dr', 'fallen-basti']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/tyiUW3dJDho?t=1535&si=wFGeGs1kEpe8TXhC',
    counter: 'hondo', banners: 57, squad: ['hunter', 'bb-echo', 'tech'], defense: ['hondo', 'kuiil', 'ig-11']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/tyiUW3dJDho?t=1463&si=5vJFQcBz4rzs-AmR',
    counter: 'tarfful', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['tarfful', 'vet-chewie', 'zaalbar']
  },
  {
    name: 'Talzin', value: 'talzin', url: 'https://youtu.be/tyiUW3dJDho?t=1403&si=lWDfuI_ioSImmk5n',
    counter: '50rt', banners: 56, squad: ['talzin', 'daka', 'omi-merrin'], defense: ['50rt', 'gg', 'droideka']
  },
  {
    name: 'Darth Revan', value: 'dr', url: 'https://youtu.be/tyiUW3dJDho?t=1283&si=ARGRAQyD-6sK6nlb',
    counter: 'cere', banners: 57, squad: ['dr', 'fallen-basti', 'malak'], defense: ['omi-cere', 'cal', 'fulcrum']
  },
  {
    name: 'Jedi Master Luke', value: 'jml', url: 'https://youtu.be/tyiUW3dJDho?t=1145&si=kYTsmSzvAe61CyCO',
    counter: 'lv', banners: 54, squad: ['jml', 'jkr', 'hermit'], defense: ['lv', 'mando-maul', 'thrawn']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/tyiUW3dJDho?t=1088&si=iz8zjU4fFMTsmHZz',
    counter: 'finn', banners: 54, squad: ['omi-malgus', 'talon', 'marauder'], defense: ['finn', 'hero-finn', 'omi-zorii']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/tyiUW3dJDho?t=981&si=nGn5GBGijs41l38R',
    counter: 'malgus', banners: 56, squad: ['jedi-basti', 'mace', 'jedi-cal'], defense: ['omi-malgus', 'talon', 'marauder']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/tyiUW3dJDho?t=694&si=7pUMyw2okILai29P',
    counter: 'leia-organa', banners: 56, squad: ['omi-reva', 'omi-starkiller', 'omi-savage'], defense: ['leia-organa', 'r2', 'omi-drogan']
  },
  {
    name: 'Jedi Bastila', value: 'bastila', url: 'https://www.youtube.com/live/PE2Hmx_mIvM?t=6543&si=Zhjp-6CABwzlOf-X',
    counter: 'qira', banners: 57, squad: ['bastila', 'jedi-cal', 'mace'], defense: ['qira', 'nest', 'hondo']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://www.youtube.com/live/PE2Hmx_mIvM?t=3487&si=W2WkLz0J-UeAbszX',
    counter: 'trench', banners: 57, squad: ['hunter', 'tech', 'bb-echo'], defense: ['trench', 'dooku', 'gba']
  },
  {
    name: 'Admiral Raddus', value: 'admiralraddus', url: 'https://www.youtube.com/live/PE2Hmx_mIvM?t=2965&si=0Xz86BRi9AtBXxdd',
    counter: 'iden', banners: 57, squad: ['omi-raddus', 'jyn', 'baze'], defense: ['omi-iden', 'scout-trooper', 'snowtrooper']
  },
  {
    name: 'Rey', value: 'rey', url: 'https://www.youtube.com/live/PE2Hmx_mIvM?t=2622&si=qN6xa9mIJu5jDuZz',
    counter: 'rey', title: 'Rey Mirror', banners: 57, squad: ['rey', 'omi-ben-solo', 'cal'], defense: ['rey', 'omi-ben-solo', 'cal']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://www.youtube.com/live/PE2Hmx_mIvM?t=1643&si=7LhFgqeMzyV0hZsu',
    counter: 'rex', banners: 53, squad: ['omi-qgj', 'kam', 'jka'], defense: ['rex', 'fives', 'omi-captain-rex']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/V9cMPA4sBqY?t=1354&si=voAXMM7WmQZiYAzi',
    counter: 'dash', banners: 57, squad: ['hunter', 'tech', 'bb-echo'], defense: ['dash', 'l3', 'vandor-chewie']
  },
  {
    name: 'Wampa', value: 'jkr', url: 'https://youtu.be/V9cMPA4sBqY?t=1286&si=G5BK0S5SUWFupgAG',
    counter: '50rt', banners: 58, squad: ['omi-wampa'], defense: ['50rt', 'gg', 'droideka']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/V9cMPA4sBqY?t=1233&si=E_WeMLopT5YBgvtL',
    counter: 'finn', banners: 57, squad: ['palpatine', 'mara', 'omi-starkiller'], defense: ['finn', 'hero-finn', 'omi-zorii']
  },
  {
    name: 'Padme', value: 'padme', url: 'https://youtu.be/V9cMPA4sBqY?t=1164&si=3OyIkpP4J-RNjFtF',
    counter: 'hondo', banners: 57, squad: ['padme', 'aayla', 'gideon'], defense: ['hondo', 'kuiil', 'ig-11']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/V9cMPA4sBqY?t=1093&si=RgP_cBM15KurD_Tu',
    counter: 'jkr', banners: 55, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['jkr', 'jedi-basti', 'jolee']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/V9cMPA4sBqY?t=1011&si=YhnC5NoBlcMgHpTb',
    counter: 'reva', banners: 56, squad: ['omi-traya', 'nihilus', 'omi-savage'], defense: ['omi-reva', 'gi', 'eighth-brother']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/V9cMPA4sBqY?t=825&si=HY9Mh1AXe223Uuc4',
    counter: 'cere', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['omi-cere', 'cal', 'fulcrum']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/V9cMPA4sBqY?t=237&si=7SIna1zm5SAEmbj8',
    counter: 'malgus', banners: 52, squad: ['gas', 'rex', 'fives'], defense: ['omi-malgus', 'talon', 'marauder']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/0JiZ_m4RscE?t=1828',
    counter: '50rt', banners: 57, squad: ['jkr', 'jedi-basti', 'gmy'], defense: ['50rt', 'l3', 't3']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/0JiZ_m4RscE?t=1396',
    counter: 'cere', banners: 57, squad: ['omi-traya', 'nihilus', 'omi-savage'], defense: ['omi-cere', 'cal', 'fulcrum']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/0JiZ_m4RscE?t=1341',
    counter: 'talzin', banners: 57, squad: ['veers', 'piett', 'dark-trooper'], defense: ['talzin', 'omi-asajj', 'omi-merrin']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/0JiZ_m4RscE?t=1023',
    counter: 'tusken-chief', banners: 55, squad: ['omi-qgj', 'kam', 'jka'], defense: ['tusken-chief', 'tusken-warrior', 'old-boba']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/0JiZ_m4RscE?t=872',
    counter: 'uror', banners: 57, squad: ['omi-iden', 'shoretrooper', 'death-trooper'], defense: ['uror', 'tusken-shaman', 'omi-tusken-raider']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/0JiZ_m4RscE?t=693',
    counter: 'malgus', banners: 57, squad: ['jml', 'jkl', 'shaak-ti'], defense: ['omi-malgus', 'omi-talon', 'marauder']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/SNZlyxP3odQ?t=2295',
    counter: 'phasma', banners: 57, squad: ['veers', 'piett', 'dark-trooper'], defense: ['phasma', 'kylo', 'fox']
  },
  {
    name: 'Dash', value: 'dash', url: 'https://youtu.be/SNZlyxP3odQ?t=2268',
    counter: 'hux', banners: 57, squad: ['dash', 'han', 'chewie'], defense: ['hux', 'sith-trooper', 'omi-fotp']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/SNZlyxP3odQ?t=2194',
    counter: 'sana', banners: 57, squad: ['hunter', 'bb-echo', 'tech'], defense: ['sana', 'cara', 'sth']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/SNZlyxP3odQ?t=2055',
    counter: 'trench', banners: 58, squad: ['omi-wampa'], defense: ['trench', 'dooku', 'droideka']
  },
  {
    name: 'General Skywalker', value: 'gas', url: 'https://youtu.be/SNZlyxP3odQ?t=1866',
    counter: 'vader', banners: 52, squad: ['gas', 'rex', 'fives'], defense: ['vader', 'talon', 'malak']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/SNZlyxP3odQ?t=1620',
    counter: 'tusken-chief', banners: 57, squad: ['omi-traya', 'nihilus', 'omi-savage'], defense: ['tusken-chief', 'tusken-warrior', 'omi-tusken-raider']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/SNZlyxP3odQ?t=1516',
    counter: 'finn', banners: 57, squad: ['jkr', 'jkl', 'gmy'], defense: ['finn', 'hero-finn', 'omi-zorii']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/SNZlyxP3odQ?t=1412',
    counter: 'jml', banners: 57, squad: ['omi-reva', 'gi', 'seventh-sister'], defense: ['jml', 'jedi-basti', 'jkl']
  },
  {
    name: 'Rey', value: 'rey', url: 'https://youtu.be/SNZlyxP3odQ?t=1063',
    counter: 'jmk', banners: 57, squad: ['rey', 'omi-ben-solo', 'cal'], defense: ['jmk', 'gk', 'cat']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/SNZlyxP3odQ?t=887',
    counter: 'jabba', banners: 54, squad: ['slkr', 'hux', 'kru'], defense: ['jabba', 'omi-krrsantan', 'boussh-leia']
  },
  {
    name: 'Fennec', value: 'fennec', url: 'https://youtu.be/SNZlyxP3odQ?t=679',
    counter: 'lv', banners: 53, squad: ['fennec', 'bam', 'greef'], defense: ['lv', 'mando-maul', 'rg']
  },
  {
    name: 'BAM', value: 'bam', url: 'https://youtu.be/mtN6IX94X20?t=1135',
    counter: 'phasma', banners: 57, squad: ['bam', 'kuiil', 'ig-11'], defense: ['phasma', 'kru', 'omi-fotp']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/mtN6IX94X20?t=1023',
    counter: '50rt', banners: 57, squad: ['hunter', 'bb-echo', 'tech'], defense: ['50rt', 'bb', 'ig-11']
  },
  {
    name: 'Padme', value: 'padme', url: 'https://youtu.be/mtN6IX94X20?t=963',
    counter: 'gg', banners: 54, squad: ['padme', 'ahsoka', 'mace'], defense: ['gg', 'magna', 'b2']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/mtN6IX94X20?t=858',
    counter: 'dash', banners: 58, squad: ['omi-wampa'], defense: ['dash', 'l3', 'vandor-chewie']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/mtN6IX94X20?t=559',
    counter: 'rey', banners: 54, squad: ['palpatine', 'mara', 'omi-starkiller'], defense: ['rey', 'omi-ben-solo', 'rjt']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/mtN6IX94X20?t=481',
    counter: 'jabba', banners: 51, squad: ['slkr', 'wat', 'fost'], defense: ['jabba', 'krrsantan', 'boussh-leia']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/mtN6IX94X20?t=394',
    counter: 'jabba', banners: 50, squad: ['slkr', 'daka', 'zombie'], defense: ['jabba', 'krrsantan', 'boussh-leia']
  },
  {
    name: 'Admiral Raddus', value: 'admiralraddus', url: 'https://youtu.be/mtN6IX94X20?t=313',
    counter: 'ninth-sister', banners: 57, squad: ['omi-raddus', 'jyn', 'pathfinder'], defense: ['ninth-sister', 'eighth-brother', 'second-sister']
  },
  {
    name: 'Admiral Raddus', value: 'admiralraddus', url: 'https://youtu.be/mtN6IX94X20?t=255',
    counter: 'fifth-brother', banners: 57, squad: ['omi-raddus', 'jyn', 'pathfinder'], defense: ['fifth-brother', 'eighth-brother', 'seventh-sister']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/mtN6IX94X20?t=182',
    counter: 'gi', banners: 55, squad: ['omi-traya', 'nihilus', 'sion'], defense: ['gi', 'ninth-sister', 'second-sister']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/mtN6IX94X20?t=128',
    counter: 'mando-maul', banners: 54, squad: ['omi-qgj', 'jka', 'kam'], defense: ['mando-maul', 'canderous', 'bokatan']
  },
  {
    name: 'Nute', value: 'nute', url: 'https://youtu.be/bUxkmIqXdos?t=1012',
    counter: 'qgj', banners: 53, squad: ['nute', 'jango', 'b1'], defense: ['omi-qgj', 'jka', 'kam']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/bUxkmIqXdos?t=798',
    counter: 'qira', banners: 58, squad: ['omi-wampa'], defense: ['qira', 'nest', 'visas']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/bUxkmIqXdos?t=524',
    counter: 'gas', banners: 54, squad: ['cls', 'han', 'chewie'], defense: ['gas', 'rex', 'fives']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/bUxkmIqXdos?t=455',
    counter: 'gas', banners: 56, squad: ['jml', 'hermit', 'gmy'], defense: ['gas', 'rex', 'fives']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/bUxkmIqXdos?t=120',
    counter: 'malgus', banners: 51, squad: ['see', 'set', 'dooku'], defense: ['omi-malgus', 'talon', 'marauder']
  },
  {
    name: 'JKL', value: 'jkl', url: 'https://youtu.be/bUxkmIqXdos?t=185',
    counter: 'malgus', banners: 57, squad: ['jkl', 'mace', 'hermit'], defense: ['omi-malgus', 'talon', 'marauder']
  },
  {
    name: 'JKL', value: 'jkl', url: 'https://youtu.be/EkIh5ly1A_Q?t=680',
    counter: 'palpatine', banners: 56, squad: ['jkl', 'hermit', 'shaak-ti'], defense: ['palpatine', 'mara', 'malak']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/EkIh5ly1A_Q?t=642',
    counter: 'mothma', banners: 57, squad: ['omi-iden', 'range', 'death-trooper'], defense: ['mothma', 'cara', 'kyle']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/EkIh5ly1A_Q?t=568',
    counter: 'savage', banners: 57, squad: ['jml', 'jkr', 'gmy'], defense: ['omi-savage', 'omi-starkiller', 'visas']
  },
  {
    name: 'Lord Vader', value: 'lv', url: 'https://youtu.be/EkIh5ly1A_Q?t=435',
    counter: 'rey', banners: 51, squad: ['lv', 'mando-maul', 'rg'], defense: ['rey', 'hermit', 'omi-ben-solo']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/EkIh5ly1A_Q?t=374',
    counter: '50rt', banners: 56, squad: ['cls', '3po', 'threepio'], defense: ['50rt', 'ig-11', 'kuiil']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/EkIh5ly1A_Q?t=302',
    counter: 'second-sister', banners: 57, squad: ['hunter', 'bb-echo', 'tech'], defense: ['second-sister', 'eighth-brother', 'ninth-sister']
  },
  {
    name: 'JMK', value: 'jmk', url: 'https://youtu.be/EkIh5ly1A_Q?t=36',
    counter: 'jabba', banners: 57, squad: ['jmk', 'gk', 'cat'], defense: ['jabba', 'krrsantan', 'boussh-leia']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/xSnU102roeY?t=1665',
    counter: 'gg', banners: 56, squad: ['hunter', 'bb-echo', 'tech'], defense: ['gg', 'magna', 'b2']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/xSnU102roeY?t=1503',
    counter: 'admiralraddus', banners: 57, squad: ['hunter', 'bb-echo', 'tech'], defense: ['omi-raddus', 'jyn', 'pathfinder']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/xSnU102roeY?t=1357',
    counter: 'admiralraddus', banners: 56, squad: ['bossk', 'mando', 'boba'], defense: ['omi-raddus', 'jyn', 'pathfinder']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/xSnU102roeY?t=1178',
    counter: 'mothma', banners: 55, squad: ['traya', 'nihilus', 'sion'], defense: ['mothma', 'cara', 'kyle']
  },
  {
    name: 'Rey (JT)', value: 'rjt', url: 'https://youtu.be/xSnU102roeY?t=1032',
    counter: 'fifth-brother', banners: 57, squad: ['rjt', 'bb', 'r2'], defense: ['fifth-brother', 'ninth-sister', 'seventh-sister']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/xSnU102roeY?t=829',
    counter: 'malgus', banners: 51, squad: ['palpatine', 'omi-starkiller', 'mara'], defense: ['omi-malgus', 'talon', 'set']
  },
  {
    name: 'Fennec', value: 'fennec', url: 'https://youtu.be/xSnU102roeY?t=418',
    counter: 'lv', banners: 52, squad: ['fennec', 'omi-zam', 'greef'], defense: ['lv', 'thrawn', 'rg']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/xSnU102roeY?t=218',
    counter: 'dash', banners: 52, squad: ['qgj', 'jka', 'kam'], defense: ['dash', 'han', 'chewie']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/s_1skWIfQQg?t=742',
    counter: 'admiralraddus', banners: 53, squad: ['traya', 'sion', 'nihilus'], defense: ['omi-raddus', 'jyn', 'cassian']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/s_1skWIfQQg?t=629',
    counter: 'palpatine', banners: 53, squad: ['cls', 'threepio', '3po'], defense: ['palpatine', 'mara', 'omi-starkiller']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/s_1skWIfQQg?t=343',
    counter: 'malgus', banners: 53, squad: ['slkr', 'zombie', 'daka'], defense: ['omi-malgus', 'malak', 'dr']
  },
  {
    name: 'Qi\'ra', value: 'qira', url: 'https://youtu.be/s_1skWIfQQg?t=225',
    counter: 'mando-maul', banners: 54, squad: ['qira', 'nest'], defense: ['mando-maul', 'canderous', 'droideka']
  },
  {
    name: 'Fennec', value: 'fennec', url: 'https://youtu.be/s_1skWIfQQg?t=60',
    counter: 'lv', banners: 55, squad: ['fennec', 'bossk', 'greef'], defense: ['lv', 'vader', 'rg']
  },
  {
    name: 'Rey (JT)', value: 'rjt', url: 'https://youtu.be/2UojyJLAsNQ?t=1145',
    counter: 'cls', banners: 55, squad: ['rjt', 'bb', 'r2'], defense: ['cls', 'threepio', '3po']
  },
  {
    name: 'Rex', value: 'rex', url: 'https://youtu.be/2UojyJLAsNQ?t=1012',
    counter: 'mothma', banners: 57, squad: ['rex', 'fives', 'wat'], defense: ['mothma', 'pathfinder', 'cara']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/2UojyJLAsNQ?t=913',
    counter: 'padme', banners: 57, squad: ['palpatine', 'vader', 'thrawn'], defense: ['padme', 'gk', 'ahsoka']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/2UojyJLAsNQ?t=795',
    counter: 'palpatine', banners: 52, squad: ['traya', 'sion', 'nihilus'], defense: ['palpatine', 'mara', 'shoretrooper']
  },
  {
    name: 'Beskar Mando', value: 'bam', url: 'https://youtu.be/2UojyJLAsNQ?t=566',
    counter: 'kru', banners: 57, squad: ['bam', 'ig-11', 'kuiil'], defense: ['kru', 'sith-trooper', 'kylo']
  },
  {
    name: 'Geos', value: 'geos', url: 'https://youtu.be/2UojyJLAsNQ?t=422',
    counter: 'hux', banners: 54, squad: ['gba', 'sun-fac', 'geo-spy'], defense: ['hux', 'omi-fotp', 'fox']
  },
  {
    name: 'Hux', value: 'hux', url: 'https://youtu.be/2UojyJLAsNQ?t=342',
    counter: 'mando-maul', banners: 54, squad: ['hux', 'omi-fotp', 'sith-trooper'], defense: ['mando-maul', 'canderous', 'wat']
  },
  {
    name: 'Hux', value: 'hux', url: 'https://youtu.be/2UojyJLAsNQ?t=260',
    counter: 'gg', banners: 52, squad: ['hux', 'omi-fotp', 'sith-trooper'], defense: ['gg', 'magna', 'b2']
  },
  {
    name: 'Hux', value: 'hux', url: 'https://youtu.be/2UojyJLAsNQ?t=166',
    counter: 'shaak', banners: 55, squad: ['hux', 'omi-fotp', 'sith-trooper'], defense: ['shaak-ti', 'bb-echo', 'arc']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/2UojyJLAsNQ?t=68',
    counter: 'iden', banners: 58, squad: ['omi-wampa'], defense: ['omi-iden', 'magma', 'death-trooper']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/p4NCr0Z7CmI?t=513',
    counter: 'gideon', banners: 54, squad: ['jedi-basti', 'kam', 'ben'], defense: ['gideon', 'death-trooper', 'range']
  },
  {
    name: 'Gideon', value: 'gideon', url: 'https://youtu.be/p4NCr0Z7CmI?t=398',
    counter: 'rjt', banners: 56, squad: ['gideon', 'death-trooper', 'range'], defense: ['rjt', 'bb', 'r2']
  },
  {
    name: 'Gideon', value: 'gideon', url: 'https://youtu.be/p4NCr0Z7CmI?t=308',
    counter: 'boba', banners: 57, squad: ['gideon', 'death-trooper', 'range'], defense: ['boba', 'dengar', 'omi-zam']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/1O4_5tx7R88?t=920',
    counter: 'qira', banners: 57, squad: ['bossk', 'mando', 'greef'], defense: ['qira', 'nest', 'armorer']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', url: 'https://youtu.be/1O4_5tx7R88?t=808',
    counter: 'nute', banners: 57, squad: ['jkl', 'kam', 'ben'], defense: ['nute', 'jango', 'b1']
  },
  {
    name: 'traya', value: 'traya', url: 'https://youtu.be/1O4_5tx7R88?t=693',
    counter: 'padme', banners: 55,
    squad: ['traya', 'sion', 'nihilus'], defense: ['padme', 'mace', 'ahsoka']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/1O4_5tx7R88?t=554',
    counter: 'rjt', banners: 57,
    squad: ['mothma', 'pao', 'hoth-scout'], defense: ['rjt', 'bb', 'r2']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/1O4_5tx7R88?t=427',
    counter: 'cls', banners: 57, squad: ['omi-iden', 'range', 'death-trooper'], defense: ['cls', '3po', 'threepio']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/1O4_5tx7R88?t=350',
    counter: 'bossk', banners: 58, squad: ['omi-wampa'], defense: ['bossk', 'omi-zam', 'boba']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/1O4_5tx7R88?t=273',
    counter: 'qgj', banners: 58, squad: ['omi-wampa'], defense: ['omi-qgj', 'kam', 'jka']
  },
  {
    name: 'Jedi Master Kenobi', value: 'jml', url: 'https://youtu.be/eJZvQ4ikqEs?t=875',
    counter: 'rey', banners: 55, squad: ['jmk', 'cat', 'gk'], defense: ['rey', 'hermit', 'armorer']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/eJZvQ4ikqEs?t=742',
    counter: 'jmk', banners: 53, squad: ['see', 'daka', 'zombie'], defense: ['jmk', 'cat', 'gk']
  },
  {
    name: 'SLKR Undersize', value: 'slkr', url: 'https://youtu.be/eJZvQ4ikqEs?t=615',
    counter: 'lv', banners: 55, squad: ['slkr', 'wat'], defense: ['lv', 'rg', 'stormtrooper']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/ebooAWv2sNs?t=1139',
    counter: 'jango', banners: 57, squad: ['jkr', 'gk', 'yoda'], defense: ['jango', 'bossk', 'omi-zam']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/ebooAWv2sNs?t=1032',
    counter: 'rjt', banners: 54, squad: ['traya', 'sion', 'nihilus'], defense: ['rjt', 'bb', 'r2']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/ebooAWv2sNs?t=922',
    counter: 'mothma', banners: 56, squad: ['palpatine', 'vader', 'mara'], defense: ['mothma', 'kyle', 'cara']
  },
  {
    name: 'Geos', value: 'geos', url: 'https://youtu.be/ebooAWv2sNs?t=800',
    counter: 'bam', banners: 56, squad: ['gba', 'sun-fac', 'geo-spy'], defense: ['bam', 'ig-11', 'kuiil']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/ebooAWv2sNs?t=504',
    counter: 'gg', banners: 59, squad: ['omi-wampa'], defense: ['gg', 'b2', 'magna']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/ebooAWv2sNs?t=415',
    counter: 'nute', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['nute', 'nest', 'dooku']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/ebooAWv2sNs?t=325',
    counter: 'dengar', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['dengar', 'nest', 'omi-zam']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/ebooAWv2sNs?t=234',
    counter: 'dash', banners: 54, squad: ['jkr', 'jolee', 'yoda'], defense: ['dash', 'han', 'chewie']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/ebooAWv2sNs?t=144',
    counter: 'mando-maul', banners: 55, squad: ['veers', 'dark-trooper', 'piett'], defense: ['mando-maul', 'canderous', 'bokatan']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/ebooAWv2sNs?t=27',
    counter: 'palpatine', banners: 54, squad: ['cls', 'han', 'chewie'], defense: ['palpatine', 'mara', 'omi-starkiller']
  },
  {
    name: 'Padme', value: 'pdame', url: 'https://youtu.be/kAk6VMXzGOQ?t=1047',
    counter: 'nute', banners: 54, squad: ['padme', 'gk', 'cat'], defense: ['nute', 'jango', 'nest']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/kAk6VMXzGOQ?t=947',
    counter: 'nute', banners: 56, squad: ['mothma', 'cara', 'kyle'], defense: ['nute', 'jango', 'magna']
  },
  {
    name: 'Jedi Knight Revan', value: 'jkr', url: 'https://youtu.be/kAk6VMXzGOQ?t=862',
    counter: 'bam', banners: 57, squad: ['jkr', 'jkl', 'hermit'], defense: ['bam', 'ig-11', 'kuiil']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', url: 'https://youtu.be/kAk6VMXzGOQ?t=749',
    counter: 'bossk', banners: 55, squad: ['jkl', 'ben', 'ahsoka'], defense: ['bossk', 'boba', 'omi-zam']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/kAk6VMXzGOQ?t=672',
    counter: 'kru', banners: 56, squad: ['hunter', 'bb-echo', 'tech'], defense: ['kru', 'sith-trooper', 'kylo']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/kAk6VMXzGOQ?t=562',
    counter: 'hux', banners: 55, squad: ['hunter', 'bb-echo', 'tech'], defense: ['hux', 'sith-trooper', 'kylo']
  },
  {
    name: 'Troopers', value: 'veers', url: 'https://youtu.be/kAk6VMXzGOQ?t=485',
    counter: 'boba', banners: 57, squad: ['veers', 'piett', 'dark-trooper'], defense: ['boba', 'dengar', 'omi-zam']
  },
  {
    name: 'Troopers', value: 'veers', url: 'https://youtu.be/kAk6VMXzGOQ?t=400',
    counter: 'dr', banners: 56, squad: ['veers', 'piett', 'dark-trooper'], defense: ['dr', 'fallen-basti', 'malak']
  },
  {
    name: 'Troopers', value: 'veers', url: 'https://youtu.be/kAk6VMXzGOQ?t=308',
    counter: 'cls', banners: 55, squad: ['veers', 'piett', 'dark-trooper'], defense: ['cls', 'threepio', '3po']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/kAk6VMXzGOQ?t=218',
    counter: 'mothma', banners: 58, squad: ['omi-wampa'], defense: ['mothma', 'kyle', 'cara']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/kAk6VMXzGOQ?t=64',
    counter: 'dr', banners: 58, squad: ['omi-wampa'], defense: ['dr', 'fallen-basti', 'marauder']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/2hCf5h6z8WI?t=865',
    counter: 'jmk', banners: 49, squad: ['slkr', 'daka', 'zombie'], defense: ['jmk', 'cat', 'gk']
  },
  {
    name: 'Lord Vader', value: 'lv', url: 'https://youtu.be/2hCf5h6z8WI?t=610',
    counter: 'palpatine', banners: 53, squad: ['lv', 'thrawn', 'rg'], defense: ['palpatine', 'mara', 'omi-starkiller']
  },
  {
    name: 'Lord Vader', value: 'lv', url: 'https://youtu.be/2hCf5h6z8WI?t=703',
    counter: 'vader', banners: 55, squad: ['lv', 'shoretrooper', 'rg'], defense: ['vader', 'gk', 'omi-starkiller']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/2hCf5h6z8WI?t=982',
    counter: 'lv', banners: 50, squad: ['slkr', 'wat', 'fost'], defense: ['lv', 'mando-maul', 'rg']
  },
  {
    name: 'Jedi Master Kenobi', value: 'jmk', url: 'https://youtu.be/2hCf5h6z8WI?t=346',
    counter: 'lv', banners: 56, squad: ['jmk', 'cat', 'gk'], defense: ['lv', 'mando-maul', 'rg']
  },
  {
    name: 'Old Ben', value: 'ben', url: 'https://youtu.be/Rgeoo3cFM4M?t=1787',
    counter: 'dooku', banners: 57, squad: ['ben', 'han', 'chewie'], defense: ['dooku', 'sun-fac', 'droideka']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/Rgeoo3cFM4M?t=972',
    counter: 'traya', banners: 57, squad: ['jedi-basti', 'kam', 'shaak-ti'], defense: ['traya', 'sion', 'nihilus']
  },
  {
    name: 'Sith Eternal', value: 'see', url: 'https://youtu.be/Rgeoo3cFM4M?t=726',
    counter: 'dr', banners: 58,
    squad: ['see'], defense: ['dr', 'malak', 'fallen-basti']
  },
  {
    name: 'Jedi Master Luke', value: 'jml', url: 'https://youtu.be/Rgeoo3cFM4M?t=203', counter: 'slkr',
    banners: 56, squad: ['jml', 'jkl', 'hermit'], defense: ['slkr', 'hux', 'fost']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/Ln21M1aNVxI?t=1895',
    counter: 'geos', banners: 56, squad: ['jkr', 'gmy', 'jolee'], defense: ['gba', 'geo-spy', 'sun-fac']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/Ln21M1aNVxI?t=1414',
    counter: 'kru', banners: 57, squad: ['jedi-basti', 'kam', 'ben'], defense: ['kru', 'fox', 'kylo']
  },
  {
    name: 'Thrawn', value: 'thrawn', url: 'https://youtu.be/Ln21M1aNVxI?t=1151',
    counter: 'nute', banners: 55, squad: ['thrawn', 'gideon', 'rg'], defense: ['nute', 'b1', 'jango']
  },
  {
    name: 'Rey (JT)', value: 'rjt', url: 'https://youtu.be/Ln21M1aNVxI?t=631',
    counter: 'bossk', banners: 56, squad: ['rjt', 'bb', 'r2'], defense: ['bossk', 'mando', 'greef']
  },
  {
    name: 'Jedi Master Kenobi', value: 'jmk', url: 'https://youtu.be/Ln21M1aNVxI?t=177',
    counter: 'bastila', banners: 57,
    squad: ['jmk', 'cat', 'mace'], defense: ['jedi-basti', 'jml', 'wat']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/P-oARStZgaI?t=1215',
    counter: 'bam', banners: 56,
    squad: ['traya', 'sion', 'nihilus'], defense: ['bam', 'ig-11', 'kuiil']
  },
  {
    name: 'Jedi Master Luke', value: 'jml', url: 'https://youtu.be/P-oARStZgaI?t=537',
    counter: 'dr', banners: 56,
    squad: ['jml', 'jkl'], defense: ['dr', 'malak', 'fallen-basti']
  },
  {
    name: 'Sith Eternal', value: 'see', url: 'https://youtu.be/P-oARStZgaI?t=284',
    counter: 'gas', banners: 57,
    squad: ['see'], defense: ['gas', 'echo', 'arc']
  },
  {
    name: 'Thrawn', value: 'thrawn', url: 'https://youtu.be/P-oARStZgaI?t=284',
    counter: 'cls', banners: 57,
    squad: ['thrawn', 'gideon', 'death-trooper'], defense: ['cls', '3po', 'threepio']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/47pH3ZfGKhs?t=2038',
    counter: 'darth-maul', banners: 57,
    squad: ['bossk', 'dengar', 'boba'], defense: ['maul', 'set', 'sid']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/47pH3ZfGKhs?t=525',
    counter: 'rjt', banners: 57,
    squad: ['veers', 'piett', 'gideon'], defense: ['rjt', 'bb', 'r2']
  },
  {
    name: 'Shaak Ti', value: 'shaak-ti', url: 'https://youtu.be/47pH3ZfGKhs?t=147',
    counter: 'nute', banners: 56,
    squad: ['shaak-ti', 'rex', 'fives'], defense: ['nute', 'dooku', 'b1']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/AEW7OMKORyw?t=689',
    counter: 'darth-maul', banners: 54,
    squad: ['traya', 'nihilus', 'sion'], defense: ['maul', 'dooku', 'sid']
  },
  {
    name: 'Wedge', value: 'wedge', url: 'fD_so-lNr3c',
    counter: 'shaak', banners: 56,
    squad: ['wedge', 'han', 'chewie'], defense: ['shaak-ti', 'clone-sergeant', 'echo']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/AEW7OMKORyw?t=368',
    counter: 'rex', banners: 56,
    squad: ['veers', 'piett', 'dark-trooper'], defense: ['rex', 'arc', 'fives']
  },
  {
    name: 'JMK R2 Mirror', value: 'jmk', url: 'https://youtu.be/AEW7OMKORyw?t=188',
    counter: 'jmk', banners: 56, squad: ['jmk', 'r2', 'cat'], defense: ['jmk', 'gk', 'cat']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/XepE1mhect8?t=1530',
    counter: 'hunter', banners: 57, squad: ['veers', 'piett', 'dark-trooper'], defense: ['hunter', 'tech', 'wrecker']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/XepE1mhect8?t=1450',
    counter: 'shaak', banners: 56, squad: ['jkr', 'yoda', 'jolee'], defense: ['shaak-ti', 'fives', 'arc']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', url: 'https://youtu.be/XepE1mhect8?t=1012',
    counter: 'kru', banners: 58, squad: ['jkl'], defense: ['kru', 'kylo', 'sith-trooper']
  },
  {
    name: 'Shaak Ti', value: 'shaak-ti', url: 'https://youtu.be/XepE1mhect8?t=838', counter: 'bam', banners: 57, squad: ['shaak-ti', 'rex', 'fives'], defense: ['bam', 'ig-11', 'kuiil']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/XepE1mhect8?t=676',
    counter: 'gg', banners: 56, squad: ['mothma', 'cassian', 'k2'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/XepE1mhect8?t=523',
    counter: 'asajj', banners: 55, squad: ['traya', 'nihilus', 'sion'], defense: ['asajj', 'daka', 'spirit']
  },
  {
    name: 'Sith Eternal', value: 'see', url: 'https://youtu.be/XepE1mhect8?t=335',
    counter: 'jml', banners: 56, squad: ['see', 'wat'], defense: ['jml', 'jedi-basti', 'hermit']
  },
  {
    name: 'Rey (JT)', value: 'rjt', url: 'https://youtu.be/KUSXmhVlsnw?t=1819',
    counter: 'kru', banners: 57, squad: ['rjt', 'r2', 'bb'], defense: ['kru', 'kylo', 'fox']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/KUSXmhVlsnw?t=1703',
    counter: 'asajj', banners: 55,
    squad: ['bossk', 'dengar', 'boba'], defense: ['asajj', 'talzin', 'spirit']
  },
  {
    name: 'Leia', value: 'leia', url: 'https://youtu.be/KUSXmhVlsnw?t=1590',
    counter: 'hux', banners: 57, squad: ['leia', 'han', 'chewie'], defense: ['hux', 'sith-trooper', 'fost']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/KUSXmhVlsnw?t=586',
    counter: 'dooku', banners: 56, squad: ['mothma', 'cassian', 'k2'], defense: ['dooku', 'magna', 'geo-spy']
  },
  {
    name: 'Thrawn', value: 'thrawn', url: 'https://youtu.be/KUSXmhVlsnw?t=341',
    counter: 'gg', banners: 56, squad: ['thrawn', 'gideon', 'rg'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'SEE Solo', value: 'see', url: 'https://youtu.be/R2PBoUVFgxI?t=893',
    counter: 'geos', banners: 58, squad: ['see'], defense: ['gba', 'geo-spy', 'sun-fac']
  },
  {
    name: 'JMK Mirror', value: 'jmk', url: 'https://youtu.be/R2PBoUVFgxI?t=135',
    counter: 'jmk', banners: 54, squad: ['jmk', 'mace', 'cat'], defense: ['jmk', 'mace', 'cat']
  },
  {
    name: 'Talzin', value: 'talzin', desc: 'vs GG, B1 and B2', id: 'SYhMznFXZhc', counter: 'gg',
    title: 'Talzin vs GG', banners: 54, squad: ['talzin', 'zombie', 'daka'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Dengar, Nest and L3', id: 'k65oqYn84v4',
    counter: 'dengar', title: 'Traya vs Dengar', banners: 55, squad: ['traya', 'nihilus', 'sion'], defense: ['dengar', 'nest', 'l3']
  },
  {
    name: 'Bossk', value: 'bossk', desc: 'vs GG, B1 and B2', id: '84gS1TFylcI', counter: 'gg',
    title: 'Bossk vs GG', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Maul, Sith Empire Trooper and Marauder', id: '8YmzwfXp8oY', counter: 'darth-maul',
    title: 'Rey (JT) vs Maul', banners: 55, squad: ['rjt', 'hero-finn', 'hero-poe'], defense: ['maul', 'marauder', 'set']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'vs Beskar Mando, IG-11 and Kuiil', id: 'poKjSoOm4pc', counter: 'bam',
    title: 'Palp vs BAM', banners: 55, squad: ['palpatine', 'vader', 'thrawn'], defense: ['bam', 'ig-11', 'kuiil']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', desc: 'vs CLS, 3PO and Chewie', id: 'nU3_rWEjQEU', counter: 'cls', title: 'JKL vs CLS', banners: 56, squad: ['jkl', 'shaak-ti', 'ben'], defense: ['cls', '3po', 'threepio']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs KRU, Kylo and Sith Trooper', id: 'ugv5q9ptHX4',
    counter: 'kru', title: 'Geos vs KRU', banners: 57, squad: ['gba', 'sun-fac', 'geo-spy'], defense: ['kru', 'sith-trooper', 'kylo']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs SLKR, Daka and Zombie', id: 'bu28pwJVWIc',
    counter: 'slkr', title: 'JKR vs SLKR + Zombie Cheese', banners: 56, squad: ['jkr', 'hermit', 'rey'], defense: ['slkr', 'daka', 'zombie']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Beskar Mando, IG-11 and Kuiil', id: '72Bvt6EcUtk', counter: 'bam',
    title: 'JKL vs BAM', banners: 55, squad: ['rjt', 'hero-finn', 'hero-poe'], defense: ['bam', 'ig-11', 'kuiil']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', desc: 'vs Beskar Mando, Han and Chewie', id: '8yuBUmOYkGU', counter: 'bam',
    title: 'JKL vs BAM', banners: 56, squad: ['jkl', 'ben', 'shaak-ti'], defense: ['bam', 'chewie', 'han']
  },
  {
    name: 'Eternal Emperor Solo', value: 'see', desc: 'vs CLS, 3PO and Chewie', id: 'QQhXeHiEdGg', counter: 'cls',
    title: 'SEE Solo vs CLS', banners: 58, squad: ['see'], defense: ['cls', '3po', 'threepio']
  },
  {
    name: 'Old Ben', value: 'ben', desc: 'vs Hux, FOX and FOST', id: 'ELg7_fhrEEg',
    counter: 'hux', title: 'Old Ben vs Hux', banners: 57, squad: ['ben', 'han', 'chewie'], defense: ['hux', 'fox', 'fost']
  },
  {
    name: 'Tarkin', value: 'tarkin', desc: 'vs Hux, Sith Trooper and FOST', id: 'kPG18zm2ZAY',
    counter: 'hux', title: 'Tarkin vs Hux', banners: 57, squad: ['tarkin', 'vader', 'thrawn'], defense: ['hux', 'sith-trooper', 'fost']
  },
  {
    name: 'Bossk', value: 'bossk', desc: 'vs KRU, Kylo and FOX', id: 'ett24XKOmwc',
    counter: 'kru', title: 'Bossk vs KRU', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['kru', 'fox', 'kylo']
  },
  {
    name: 'Jedi Luke', value: 'jkl', desc: 'vs Veers, Gideon and Piett', id: 'XFGNbr7PWTU',
    counter: 'veers', title: 'Jedi Luke vs Veers', banners: 55, squad: ['jkl', 'shaak-ti', 'ezra'], defense: ['veers', 'gideon', 'piett']
  },
  {
    name: 'Veers', value: 'veers', desc: 'vs Mon Mothma, Wedge and Biggs', id: 'vD0aYAuYYpw',
    counter: 'mothma', title: 'Veers vs Mon Mothma', banners: 55, squad: ['veers', 'piett', 'range'], defense: ['mothma', 'wedge', 'biggs']
  },
  {
    name: 'Eternal Emperor Solo', value: 'see', desc: 'vs Revan, Jolee and Yoda', id: 'sC7mGp6zyvE', counter: 'jkr',
    title: 'SEE Solo vs JKR', banners: 58, squad: ['see'], defense: ['jkr', 'yoda', 'jolee']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', desc: 'vs Veers, Piett and Starck', id: 'o6YOjfYtMzA', counter: 'veers',
    title: 'KRU vs Veers', banners: 55, squad: ['kru', 'kylo', 'hux'], defense: ['veers', 'piett', 'starck']
  },
  {
    name: 'Jedi Master Luke', value: 'jml', desc: 'vs SRLK, Zombie and Daka', id: 'OT4sGc2ExSM', counter: 'slkr',
    title: 'JML vs SLKR + Zombie', banners: 56, squad: ['jml', 'jkl', 'shaak-ti'], defense: ['slkr', 'daka', 'zombie']
  },
  {
    name: 'Jedi Bastila', value: 'bastila', desc: 'vs GG, B1 and B2', id: 'K6fPJ0eavEc', counter: 'gg',
    title: 'Bastila vs GG', banners: 55, squad: ['jedi-basti', 'jolee', 'yoda'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Jedi Bastila', value: 'bastila', desc: 'vs Dooku, Magna and Droideka', id: 'wT_M_fWTWvY', counter: 'dooku',
    title: 'Bastila vs Dooku', banners: 57, squad: ['jedi-basti', 'hermit', 'ezra'], defense: ['dooku', 'magna', 'droideka']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs CLS, C-3PO and Threepio', id: 'Xuz7CGRmeUI',
    counter: 'cls', title: 'RJT vs CLS + Threepio', banners: 55, squad: ['rjt', 'hero-poe', 'hero-finn'], defense: ['cls', 'threepio', '3po']
  },
  {
    name: 'Rey', value: 'rey', desc: 'vs SLKR, Daka and Zombie', id: '89MNAu_4rcI',
    counter: 'slkr', title: 'Rey vs SLKR + Zombie Cheese', banners: 56, squad: ['rey', 'hermit', 'jkr'], defense: ['slkr', 'daka', 'zombie']
  },
  {
    name: 'Bastila', value: 'bastila', desc: 'vs Hux, Sith Trooper and Stormtrooper', id: 'VCksXNeOYg0',
    counter: 'hux', title: 'Bossk vs Hux', banners: 57, squad: ['bastila', 'yoda', 'jolee'], defense: ['hux', 'sith-trooper', 'fost']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', desc: 'vs Geo Brood, Spy and Sun Fac', id: '1C6fT0_7KFQ',
    counter: 'geos', title: 'JKL vs Geos', banners: 56, squad: ['jkl', 'ezra', 'shaak-ti'], defense: ['gba', 'geo-spy', 'sun-fac']
  },
  {
    name: 'Bossk', value: 'bossk', desc: 'vs Bastila, Shaak Ti and Old Ben', id: 'nXIsk3Sr1uk',
    counter: 'bastila', title: 'Bossk vs Bastila', banners: 57, squad: ['bossk', 'greef', 'mando'], defense: ['jedi-basti', 'shaak-ti', 'ben']
  },
  {
    name: 'SL Kylo Ren 2v5', value: 'slkr', desc: 'vs Bastila, JML and Wat', id: 'YMj6uRLxOhY',
    counter: 'bastila', title: 'SLKR vs Bastila + JML', banners: 57, squad: ['slkr', 'kru'], defense: ['jedi-basti', 'jml', 'wat']
  },
  {
    name: 'Rey', value: 'rey', desc: 'vs JKL, JML and Old Ben', id: '06dgs1VHmxg',
    counter: 'jml', title: 'Rey vs JML', banners: 53, squad: ['rey', 'holdo', 'jedi-basti'], defense: ['jml', 'ahsoka', 'yoda']
  },
  {
    name: 'Rey', value: 'rey', desc: 'vs JKL, JML and Old Ben', id: 'wm6_UhF0YDY',
    counter: 'jkl', title: 'Rey vs JKL + JML', banners: 53, squad: ['rey', 'holdo', 'hermit'], defense: ['jkl', 'jml', 'ben']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Shaak Ti, Echo and ARC', id: 'Kl7UcKI5wEE',
    counter: 'shaak', title: 'Traya vs Shaak Ti', banners: 54, squad: ['traya', 'nihilus', 'sion'], defense: ['shaak-ti', 'echo', 'arc']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Dooku, Asajj + Zombie', id: 'LRU5YteNRu8',
    counter: 'dooku', title: 'Traya vs Dooku', banners: 56, squad: ['traya', 'nihilus', 'sion'], defense: ['dooku', 'asajj', 'zombie']
  },
  {
    name: 'Rey', value: 'rey', desc: 'vs SEE, Sidious and SE Trooper', id: 'uvkHKkUh0CA',
    counter: 'see', title: 'Rey vs Sith Eternal Emperor', banners: 52, squad: ['rey', 'holdo', 'scavenger'], defense: ['see', 'set', 'sid']
  },
  {
    name: 'Bastila', value: 'bastila', desc: 'vs Mon Mothma, Wedge and Biggs', id: 'YS2R5bWSiN8',
    counter: 'mothma', title: 'Bastila vs Mon Mothma', banners: 57, squad: ['bastila', 'yoda', 'jolee'], defense: ['mothma', 'wedge', 'biggs']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'vs JKL, Hermit and Yoda ', id: 'WHXCj1XigO0',
    counter: 'jkl', title: 'DR vs JKL', banners: 57, squad: ['dr', 'hk', 'fallen-basti'], defense: ['jkl', 'yoda', 'hermit']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs JKL, Old Ben and Ahsoka', id: 'N-nilKCWvCw',
    counter: 'jkl', title: 'GAS vs JKL', banners: 50, squad: ['gas', 'rex', 'fives'], defense: ['jkl', 'ahsoka', 'ben']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', desc: 'vs Shaak Ti, Echo and ARC', id: '8Qyv5D6RhCY',
    counter: 'shaak', title: 'JKL vs Shaak Ti', banners: 56, squad: ['jkl', 'ben', 'shaak'], defense: ['shaak-ti', 'echo', 'arc']
  },
  {
    name: 'KRU + Wat', value: 'kru', desc: 'vs GBA, Spy and Soldier', id: 'oXyIz9ZqybY',
    counter: 'geos', title: 'KRU vs Geos', banners: 56, squad: ['kru', 'kylo', 'wat'], defense: ['gba', 'geo-spy', 'geo-soldier']
  },
  {
    name: 'Nest', value: 'nest', desc: 'vs RJT, BB-8 and Holdo', id: '-5Y07gNioJM',
    counter: 'rjt', title: 'Nest vs RJT', banners: 53, squad: ['nest', 'han', 'chewie'], defense: ['rjt', 'bb', 'holdo']
  },
  {
    name: 'Shaak Ti', value: 'shaak', desc: 'vs Hux, Executioner and Stormtrooper', id: 'Nd9_VzEsy8w',
    counter: 'hux', title: 'Shaak Ti vs Hux', banners: 56, squad: ['shaak', 'rex', 'fives'], defense: ['hux', 'fox', 'fost']
  },
  {
    name: 'Tarkin', value: 'tarkin', desc: 'vs JKL, Hermit and Old Ben', id: 'q5adMWNdiJg',
    counter: 'jkl', title: 'Tarkin vs JKL', banners: 57, squad: ['tarkin', 'thrawn', 'vader'], defense: ['jkl', 'ben', 'hermit']
  },
  {
    name: 'Rey Solo', value: 'rey', desc: 'vs Revan, Malak and Bastila', id: '0JzeT6nc7Zs',
    counter: 'dr', title: 'Rey Solo vs Darth Revan', banners: 59, squad: ['rey'], defense: ['dr', 'malak', 'fallen-basti']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'vs Geos', id: 'qAXSJTwENeU',
    counter: 'geos', title: 'Palpatine vs Geos', banners: 57, squad: ['palp', 'shoretrooper', 'vader'], defense: ['gba', 'geo-spy', 'sun-fac']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs JKL, Hermit and Old Ben', id: 'Smd9YMT4iF8',
    counter: 'jkl', title: 'Jedi Revan vs Jedi Knight Luke', banners: 55, squad: ['jkr', 'jolee', 'gmy'], defense: ['jkl', 'ben', 'hermit']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'vs Shaak Ti, Echo and ARC', id: 'c9OGXeYHgqU',
    counter: 'shaak', title: 'Palpatine vs Shaak Ti', banners: 54, squad: ['palpatine', 'vader', 'thrawn'], defense: ['shaak-ti', 'arc', 'echo']
  },
  {
    name: 'SLKR Solo', value: 'slkr', desc: 'vs SEE, Nihilus and SE Trooper', id: 'cywwy8ntSj0',
    counter: 'see', title: 'SLKR Solo vs Sith Eternal Emperor', banners: 58, squad: ['slkr'], defense: ['see', 'set', 'nihilus']
  },
  {
    name: 'Hux', value: 'hux', desc: 'vs Rey (JT), BB-8 and Vet Han', id: '3SZJlRu5RD8',
    counter: 'rjt', title: 'Hux vs Rey (JT)', banners: 54, squad: ['hux', 'fox', 'foo'], defense: ['rjt', 'bb', 'vet-han']
  },
  {
    name: 'Rey Solo', value: 'rey', desc: 'vs Shaak Ti, Rex and Fives', id: 'cMbnwBqLd0g',
    counter: 'shaak', title: 'Rey vs Shaak Ti', banners: 59, squad: ['rey'], defense: ['shaak-ti', 'rex', 'fives']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', desc: 'vs GG, B1 and B2', id: 'W581PkF2J-A',
    counter: 'gg', title: 'JKL vs GG', banners: 55, squad: ['jkl', 'gmy', 'hermit'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs Revan, Malak and Bastila', id: 'HEo-VtmqdyA',
    counter: 'dr', title: 'Jedi Revan vs Darth Revan', banners: 51, squad: ['jkr', 'jka', 'gmy'], defense: ['dr', 'malak', 'fallen-basti']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs Revan, Malak and Bastila', id: '0PTrzpX51q8',
    counter: 'dr', title: 'GAS vs Darth Revan', banners: 50, squad: ['gas', 'rex', 'fives'], defense: ['dr', 'malak', 'fallen-basti']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs Carth, Mission and Zaalbar', id: 'GdXPeAP-8vU',
    counter: 'carth', title: 'Geos vs Carth', banners: 57, squad: ['gba', 'sun-fac', 'geo-spy'], defense: ['carth', 'zaalbar', 'mission']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs KRU, Fox and Kylo Ren', id: 'nXGQDHOtXeI',
    counter: 'kru', title: 'Padme vs KRU', banners: 54, squad: ['padme', 'jka', 'ahsoka'], defense: ['kru', 'kylo', 'fox']
  },
  {
    name: 'SL Kylo Ren', value: 'slkr', desc: 'vs Rey and Resistance Heroes', id: 'gJTXTuUYDvY',
    counter: 'rey', title: 'SLKR vs Rey', banners: 50, squad: ['slkr', 'wat', 'hermit'], defense: ['rey', 'hero-poe', 'hero-finn']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs General Kenobi, Jolee and Hermit', id: 'xTc0JLC_ZXs',
    counter: 'gk', title: 'Traya vs General Kenobi', banners: 57, squad: ['traya', 'sion', 'nihilus'], defense: ['gk', 'jolee', 'hermit']
  },
  {
    name: 'Talzin', value: 'talzin', desc: 'vs Nute, Dooku and Sun Fac', id: 'ykVIlf1h55U',
    counter: 'nute', title: 'Talzin vs Nute', banners: 55, squad: ['talzin', 'zombie', 'spirit'], defense: ['nute', 'dooku', 'sun-fac']
  },
  {
    name: 'Rex', value: 'rex', desc: 'vs GBA, Poggle and Droideka', id: 'oaubjsKkDGA',
    counter: 'geos', title: 'Rex vs Geos', banners: 54, squad: ['rex', 'fives', 'echo'], defense: ['gba', 'poggle', 'droideka']
  },
  {
    name: 'Malak Solo', value: 'malak', desc: 'vs Rex, Fives and Barriss', id: 'kszdbKdeXNw',
    counter: 'rex', title: 'Malak solo vs Rex', banners: 59, squad: ['malak'], defense: ['rex', 'barriss', 'fives']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'vs GG, B1 and B2', id: 'qjrb9_a8bPs',
    counter: 'gg', title: 'Commander Luke vs GG', banners: 54, squad: ['cls', 'han', 'chewie'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Commander Luke', value: 'cals', desc: 'vs Revan, Yoda and Bastila', id: 'LT68JT7yrPg',
    counter: 'jkr', title: 'Commander Luke vs Jedi Revan', banners: 54, squad: ['cls', 'han', 'chewie'], defense: ['jkr', 'yoda', 'jedi-basti']
  },
  {
    name: 'Kylo Ren (Unmasked)', value: 'kru', desc: 'vs Shaak Ti, Echo and Rex', id: '_HLwANkkziY',
    counter: 'shaak', title: 'KRU vs Shaak Ti Clones', banners: 55, squad: ['kru', 'fox', 'kylo'], defense: ['shaak-ti', 'rex', 'echo']
  },
  {
    name: 'SL Kylo Ren solo', value: 'slkr', desc: 'vs Revan, Malak and Bastila', id: 'sKdbjeC9nT0',
    counter: 'dr', title: 'SLKR solo vs Darth Revan', banners: 58, squad: ['slkr'], defense: ['dr', 'malak', 'fallen-basti']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs RJT, Scavenger and BB-8', id: 'dZmzSsO3h84',
    counter: 'rjt', title: 'Geos vs Rey (JT)', banners: 54, squad: ['gba', 'geo-spy', 'sun-fac'], defense: ['rjt', 'bb', 'scavenger']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs Nute, B1 and B2', id: 'RKJKnZDKsSo',
    counter: 'nute', title: 'Geos vs Nute', banners: 57, squad: ['gba', 'geo-spy', 'sun-fac'], defense: ['nute', 'b1', 'b2']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'vs KRU, Fox and Kylo Ren', id: '-ctmkH-Jfls',
    counter: 'kru', title: 'Palpatine vs KRU', banners: 54, squad: ['palp', 'vader', 'set'], defense: ['kru', 'kylo', 'fox']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs Padme, Anakin and Kenobi', id: 'dGLN2XYn1NA',
    counter: 'padme', title: 'JKR vs Padme', banners: 57, squad: ['jkr', 'gmy', 'hermit'], defense: ['padme', 'jka', 'gk']
  },
  {
    name: 'SL Kylo Ren Solo', value: 'slkr', desc: 'vs Shaak Ti, Rex and Fives', id: 'gGL1WIm2QWU',
    counter: 'shaak', title: 'SLKR solo vs Shaak Ti', banners: 58, squad: ['slkr'], defense: ['shaak-ti', 'rex', 'fives']
  },
  {
    name: 'General Hux', value: 'hux', desc: 'vs Dooku, Sun Fac and Jango', id: 'acaUMNFwrxs',
    counter: 'dooku', title: 'Hux vs Dooke', banners: 54, squad: ['hux', 'fox', 'fost'], defense: ['dooku', 'sun-fac', 'jango']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Nute, Droideka and Magna', id: 'zyw42KU-yfM',
    counter: 'nute', title: 'RJT vs Nute', banners: 55, squad: ['rjt', 'bb', 'scavenger'], defense: ['nute', 'droideka', 'magna']
  },
  {
    name: 'SL Kylo Ren Solo', value: 'slkr', desc: 'vs Palpatine, Malak and Bastila', id: 'en3oBTEKNJY',
    counter: 'palpatine', title: 'SLKR Solo vs Palpatine', banners: 58, squad: ['slkr'], defense: ['palpatine', 'malak', 'fallen-basti']
  },
  {
    name: 'SLKR Solo', value: 'slkr', desc: 'vs Padme, Anakin and Kenobi', id: '5L3uWyL9jE8',
    counter: 'padme', title: 'SLKR Solo vs Padme', banners: 58, squad: ['slkr'], defense: ['padme', 'jka', 'gk']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Padme, Anakin and Kenobi', id: 'S3o4wrGk8-k',
    counter: 'padme', title: 'Traya vs Padme', banners: 56, squad: ['traya', 'nihilus', 'thrawn'], defense: ['padme', 'jka', 'gk']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Qi\'ra, Nest and Hermit Yoda', id: 'wJ9rChCpt4U',
    counter: 'qira', title: 'Traya vs Qi\'ra', banners: 55, squad: ['traya', 'nihilus', 'sion'], defense: ['qira', 'nest', 'hermit']
  },
  {
    name: 'Wampa', value: 'wampa', desc: 'vs Jyn, Chirrut and Baze', id: 'rL--f1X8qLk',
    counter: 'jyn', title: 'Wampa vs Jyn', banners: 57, squad: ['wampa', 'hermit'], defense: ['jyn', 'chirrut', 'baze']
  },
  {
    name: 'Rex', value: 'rex', desc: 'vs GG, B1 and B2', id: '1mfz833xzRg',
    counter: 'gg', title: 'Rex vs GG', banners: 53, squad: ['rex', 'echo', 'fives'], defense: ['gg', 'b1', 'b2']
  },
  {
    name: 'Malak Solo', value: 'malak', desc: 'Malak solos Carth, Mission and Zaalbar', id: 'wz6NWubqkM8',
    counter: 'carth', title: 'Malak Solo vs Carth', banners: 59, squad: ['malak'], defense: ['carth', 'zaalbar', 'mission']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs GBA, Spy and Poggle', id: 'czb-v_pZfgI',
    counter: 'geos', title: 'Padme vs Geos', banners: 55, squad: ['padme', 'ahsoka', 'jka'], defense: ['gba', 'geo-spy', 'poggle']
  },
  {
    name: 'Kylo Ren (Unmasked)', value: 'kru', desc: 'vs Nute, Wat and Magna', id: 'J3jIVKvfMzo',
    counter: 'nute', title: 'KRU vs Nute', banners: 56, squad: ['kru', 'fox', 'kylo'], defense: ['nute', 'wat', 'magna']
  },
  {
    name: 'Rey', value: 'rey', desc: 'Clash of ultimate GLs', id: 'CVRCbDQGEAg', counter: 'slkr',
    title: 'Ulti Rey vs Ulti SLKR', banners: 52, squad: ['rey', 'hermit', 'holdo'], defense: ['slkr', 'hux', 'fost']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs Dooku with Droideka', id: 'sEWcDZrD8vQ',
    counter: 'dooku', title: 'Geos vs Dooku', banners: 57, squad: ['gba', 'geo-spy', 'poggle'], defense: ['dooku', 'sun-fac', 'droideka']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs Geos with Droideka', id: '8CKcahi3YJQ',
    counter: 'geos', title: 'GAS vs Geos', banners: 56, squad: ['gas', 'arc', 'rex'], defense: ['gba', 'droideka', 'sun-fac']
  },
  {
    name: 'Malak Solo', value: 'malak', desc: '1v3 Malak vs Shaak Ti, Rex and ARC', id: 'WawcRFBHzjQ',
    counter: 'shaak', title: 'Malak Solo vs Shaak Ti Clones', banners: 59, squad: ['malak'], defense: ['shaak-ti', 'rex', 'arc']
  },
  {
    name: 'Nihilus Solo', value: 'nihilus', desc: '1v3 Nihilus vs STH, Vandor Chewie and L3', id: 'rajymcEOnsc',
    counter: 'sth', title: 'Nihilus Solo vs STH Scoundrels', banners: 58, squad: ['nihilus'], defense: ['sth', 'vandor-chewie', 'l3']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs General Kenobi, Barriss and Wat', id: 'IUbVYEcOvAM',
    counter: 'gk', title: 'Rey (JT) vs General Kenobi', banners: 57, squad: ['rjt', 'finn', 'bb'], defense: ['gk', 'barriss', 'wat']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Jango, Boba and Bossk', id: 'gt2apUMOrHA',
    counter: 'jango', title: 'Traya vs Jango', banners: 56, squad: ['traya', 'sion', 'nihilus'], defense: ['jango', 'bossk', 'boba']
  },
  {
    name: 'Bastila', value: 'bastila', desc: 'Bastila vs Nute, Droideka and Dooku', id: 'QSXnjbVoDrs',
    counter: 'nute', title: 'Bastila vs Nute, Droideka and Dooku', banners: 57, squad: ['bastila', 'gmy', 'hermit'], defense: ['nute', 'droideka', 'dooku']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'vs Palpatine, Bastila and Malak', id: 'vZq47ZLBAX4',
    counter: 'palpatine', banners: 55, squad: ['dr', 'fallen-basti', 'malak'], defense: ['palpatine', 'malak', 'fallen-basti']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs Padme, Kenobi and JKA', id: 'c3l6MLI0XcE',
    counter: 'padme', title: 'GAS vs Padme', banners: 55, squad: ['gas', 'rex', 'echo'], defense: ['padme', 'jka', 'gk']
  },
  {
    name: 'Kylo Solo', value: 'kylo', desc: 'vs 1v3 Kylo Ren vs Carth, Mission and Zaalbar', id: '-D9bIFHi3fo',
    counter: 'carth', banners: 59, squad: ['kylo'], defense: ['carth', 'zaalbar', 'mission']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs GBA, Spy and Sun Fac', id: '9O3SPDL5Qn0',
    counter: 'geos', banners: 53, squad: ['traya', 'sion', 'l3'], defense: ['gba', 'geo-spy', 'sun-fac']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'vs GBA, Spy and Soldier', id: 'pUmjnbF4XHo',
    counter: 'geos', banners: 56, squad: ['dr', 'fallen-basti', 'malak'], defense: ['gba', 'geo-spy', 'geo-soldier']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs Dooku, Droideka and Sun Fac', id: 'wIctpja0smY',
    counter: 'dooku', banners: 57, squad: ['padme', 'ahsoka', 'gk'], defense: ['dooku', 'sun-fac', 'droideka']
  },
  {
    name: 'Malak Solo', value: 'malak', desc: '1v3 Malak vs Ewoks', id: 'r8738oXU85Q',
    counter: 'chirpa', title: 'Malak Solo vs Shaak Ti Clones', banners: 59, squad: ['malak'], defense: ['chirpa', 'ewok-elder', 'paploo']
  },
  {
    name: 'General Skywalker 2v3', value: 'gas', desc: '2v3 GAS + Rex vs Asajj NS', id: 'qD2Ng77hiMs',
    counter: 'asajj', banners: 57, squad: ['gas', 'rex'], defense: ['asajj', 'talzin', 'daka']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Dooku, Droideka and Sun Fac', id: 'p_ft0_JGSi8',
    counter: 'dooku', banners: 56, squad: ['rjt', 'bb', 'r2'], defense: ['dooku', 'sun-fac', 'droideka']
  },
  {
    name: '6* GAS', value: 'gas', desc: 'vs Nute, Jango and Droideka', id: '7pe-SoBCmVg',
    counter: 'nute', banners: 54, squad: ['gas', 'arc', 'ahsoka'], defense: ['nute', 'jango', 'droideka']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs Shaak Ti, Fives and ARC', id: 'Maff4tk8bjw',
    counter: 'shaak', banners: 56, squad: ['padme', 'ahsoka', 'gk'], defense: ['shaak-ti', 'arc', 'fives']
  },
  {
    name: 'Nihilus', value: 'nihilus', desc: 'vs Kenobi, Mission + Zaalbar', id: 'izHnTblw_GY',
    counter: 'gk', banners: 55, squad: ['nihilus', 'l3', 'nest'], defense: ['gk', 'mission', 'zaalbar']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs GG, Magna and B2', id: 'CFUFO5u6Pjk',
    counter: 'gg', banners: 54, squad: ['jkr', 'hermit', 'gmy'], defense: ['gg', 'magna', 'b2']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'vs GBA, Wat and Spy', id: 'itYCtBhMG0o',
    counter: 'geos', banners: 55, squad: ['cls', 'han', 'chewie'], defense: ['gba', 'geo-spy', 'wat']
  },
  {
    name: 'Jedi Anakin', value: 'jka', desc: 'vs Daka, Zombie and Acolyte', id: 'QRbnpcv5aas',
    counter: 'daka', banners: 56, squad: ['jka', 'hermit', 'gk'], defense: ['daka', 'acolyte', 'zombie']
  },
  {
    name: 'Kylo Ren (Unmasked)', value: 'kru', desc: 'vs Qi\'ra, Nest and L3', id: 'IpBthHJP_Y4',
    counter: 'qira', banners: 55, squad: ['kru', 'fox', 'kylo'], defense: ['qira', 'nest', 'l3']
  },
  {
    name: 'Old Ben', value: 'ben', desc: 'vs Nute, Droideka and Magna', id: '2F53b3M4FKU',
    counter: 'nute', banners: 57, squad: ['ben', 'han', 'chewie'], defense: ['nute', 'droideka', 'magna']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs KRU, OG Kylo and FOX', id: 'eVKRWpvuYiQ',
    counter: 'kru', banners: 55, squad: ['traya', 'sion', 'nihilus'], defense: ['kru', 'kylo', 'fox']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Qi\'ra, Nest and L3', id: 'wDUtSuMAJ5A',
    counter: 'qira', banners: 56, squad: ['rjt', 'finn', 'bb'], defense: ['qira', 'nest', 'l3']
  },
  {
    name: 'Kylo Ren (Unmasked)', value: 'kru', desc: 'vs Carth, Mission and Zaalbar', id: 'Y4315Fet6E0',
    counter: 'carth', banners: 57, squad: ['kru', 'foo', 'kylo'], defense: ['carth', 'zaalbar', 'mission']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs Palpatine, Bastila and Malak', id: 'Ye9mjdDlfDA',
    counter: 'palpatine', banners: 57, squad: ['jkr', 'gmy', 'hermit'], defense: ['palpatine', 'malak', 'fallen-basti']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'vs Nute, GBA and B1', id: 'JL-s559XRj4',
    counter: 'nute', banners: 56, squad: ['cls', 'han', 'chewie'], defense: ['nute', 'b1', 'gba']
  }
];

export const recent = [
  {
    title: '3v3 GAC', desc: 'Some of these Datacrons make me uncomfortable!', id: '8cmm4QKUkv4'
  },
  {
    title: '3v3 GAC', desc: 'Every banner counts!', id: 'Al6_n-Ek2X8'
  },
  {
    title: '3v3 GAC', desc: 'New DC Set - New Problems', id: 'EAEwaUuIxNg'
  },
  {
    title: '3v3 Counter Guide', desc: 'Auto Basic Merrin Omicron vs Jabba', id: 'sAXmThXbrYo'
  },
  {
    title: '3v3 GAC', desc: 'Is Lord Vader a Sith Lord Wannabe?!', id: 'OfCEU3k-gEY'
  },
  {
    title: '3v3 GAC', desc: 'Sidious & Bane datacron testing', id: 'd1p_MFe5X4s'
  },
];