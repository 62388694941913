const sector3 = [
  {
    title: 'Accuracy Down',
    text: 'Attempt to inflict Accuracy Down 50 times',
    keycards: 5,
    qty: 50,
    plan: [
      { day: 3, qty: 2, sector: 3 },
      { day: 4, qty: 2, sector: 3 },
    ],
    sector: 3,
    teams: [
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=907&si=Iq9qSOM4xTm207q7',
        default: true,
        feats: [
          { name: 'Accuracy Down', qty: 10 },
          { name: 'Peridea', qty: 1 },
          { name: 'Stun', qty: 5 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'eighth-brother', name: '8th Bro', main: true },
          { image: 'malak', name: 'Malak' },
          { image: 'scout-trooper', name: 'Scout Trooper', main: true },
          { image: 'peridea', name: 'Peridea' }
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=1359&si=tIW58QS5gTrt7uo0',
        feats: [
          { name: 'Accuracy Down', qty: 10 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'cad', name: 'Cad', main: true },
          { image: 'greef', name: 'Greef' },
          { image: 'mando', name: 'Mando' },
          { image: 'boba', name: 'BOba' }
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=1079&si=QGcc05-7lLrGOlfv',
        feats: [
          { name: 'Accuracy Down', qty: 10 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'reva', name: 'Reva' },
          { image: 'gi', name: 'Grand Inquisitor' },
          { image: 'seventh-sister', name: '7th Sister', main: true },
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'eighth-brother', name: '8th Bro', main: true },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=1160&si=OWIeAfiPIPHhgnRa',
        feats: [
          { name: 'Accuracy Down', qty: 5 },
        ],
        toons: [
          { image: 'jabba', name: 'Jabba' },
          { image: 'cad', name: 'Cad', main: true },
          { image: 'krrsantan', name: 'Krrsantan' },
          { image: 'skiff-lando', name: 'Skiff Lando' },
          { image: 'embo', name: 'Embo' }
        ]
      },
    ]
  },
  {
    title: 'Peridea',
    text: 'Win 10 battles with Death Trooper (Peridea) surviving',
    keycards: 10,
    qty: 10,
    plan: [
      { day: 3, qty: 2, sector: 3 },
      { day: 4, qty: 2, sector: 3 },
      { day: 12, qty: 2, sector: 3 },
    ],
    sector: 3,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=668&si=h7DQQrU4t8Wxa0Md',
        feats: [
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' }
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=1707&si=cotvGX3FU2jFwyWr',
        feats: [
          { name: 'Peridea', qty: 1 },
          { name: 'Accuracy Down', qty: 3 },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gidon' }
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=2060&si=WV4P-_8spmvxzKep',
        default: true,
        feats: [
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' }
        ]
      },
    ]
  },
  {
    title: 'Stun',
    text: 'Attempt to inflict Stun 50 times',
    keycards: 10,
    qty: 50,
    plan: [
      { day: 3, qty: 2, sector: 3 },
      { day: 4, qty: 2, sector: 3 },
    ],
    sector: 3,
    teams: [
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=907&si=Iq9qSOM4xTm207q7',
        feats: [
          { name: 'Accuracy Down', qty: 10 },
          { name: 'Peridea', qty: 1 },
          { name: 'Stun', qty: 5 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine', main: true  },
          { image: 'eighth-brother', name: '8th Bro'},
          { image: 'malak', name: 'Malak' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea' }
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=2260&si=SUYvuJMZ3BWPV0pE',
        default: true,
        feats: [
          { name: 'Accuracy Down', qty: 10 },
          { name: 'No Lead', qty: 1 },
          { name: 'Stun', qty: 5 },
        ],
        toons: [
          { image: 'malak', name: 'Malak' },
          { image: 'jkl', name: 'Jedi Luke', main: true },
          { image: 'jkr', name: 'Jedi Revan', main: true },
          { image: 'jedi-basti', name: 'Jedi Basti', main: true },
          { image: 'ben', name: 'Old Ben' },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=3618&si=sn57q4hzb0K6SJBQ',
        feats: [
          { name: 'Stun', qty: 10 },
        ],
        global: [
          { name: 'Galactic Republic' },
        ],
        toons: [
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'BB Echo' },
          { image: 'tech', name: 'Tech', main: true },
          { image: 'wrecker', name: 'Wrecker', main: true },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
    ]
  },
  {
    title: 'No Lead',
    text: 'Win 10 battles without using a Leader in the leader slot',
    keycards: 10,
    qty: 10,
    plan: [
      { day: 3, qty: 2, sector: 3 },
      { day: 4, qty: 2, sector: 3 },
      { day: 11, qty: 1, sector: 3 },
    ],
    sector: 3,
    teams: [
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=2260&si=SUYvuJMZ3BWPV0pE',
        feats: [
          { name: 'Accuracy Down', qty: 10 },
          { name: 'No Lead', qty: 1 },
          { name: 'Stun', qty: 5 },
        ],
        toons: [
          { image: 'malak', name: 'Malak' },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'jedi-basti', name: 'Jedi Basti' },
          { image: 'ben', name: 'Old Ben' },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=4280&si=6wf-y51xlRTI_MN5',
        feats: [
          { name: 'No Lead', qty: 1 },
        ],
        toons: [
          { image: 'fallen-basti', name: 'Fallen Basti' },
          { image: 'bane', name: 'Bane' },
        ]
      },
      {
        default: true,
        videoThumb: 'rZ5Jp2RbQ0c?t=2573&si=d_m17AFIm8kAZ1dF',
        feats: [
          { name: 'No Lead', qty: 1 },
          { name: 'Stun', qty: 5 },
        ],
        global: [
          { name: 'Dark UFUs' },
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'marrok', name: 'Marrok' },
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=4025&si=AY7Cg6Ptn_vIiUoC',
        feats: [
          { name: 'No Lead', qty: 1 },
        ],
        toons: [
          { image: 'ben-solo', name: 'Ben Solo' },
          { image: 'rey', name: 'Rey' },
          { image: 'rjt', name: 'RJT' },
          { image: 'scavenger', name: 'Scavenger' },
          { image: 'cal', name: 'Cal' },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=5053&si=DAqOuuZWZ2BrFfzN',
        feats: [
          { name: 'No Lead', qty: 1 },
        ],
        toons: [
          { image: 'cat', name: 'Commander Ahsoka' },
          { image: 'jmk', name: 'Master Kenobi' },
          { image: 'padme', name: 'Padme' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'gk', name: 'General Kenobi' },
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Bane with Morgan surviving and defeat an enemy with Mercenary',
    keycards: 7,
    sectorBoss: true,
    plan: 'day3',
    sector: 3,
    teams: [
      {
        name: 'All Feats',
        videoThumb: 'rZ5Jp2RbQ0c?t=3901&si=30tWVArzvBxCiOOW',
        boss: 'bane',
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'jedi-cal', name: 'Jedi Cal', main: true },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        default: true,
        name: 'Morgan',
        videoThumb: 'rZ5Jp2RbQ0c?t=2988&si=P8ix3MXm10qbm5v4',
        boss: 'bane',
        feats: [
          { name: 'Stun', qty: 2 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=3185&si=qf7bhovDYfvnr1c1',
        boss: 'bane',
        feats: [
          { name: 'Stun', qty: 2 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'asajj', name: 'Asajj' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        name: 'Mercenary',
        default: true,
        videoThumb: 'rZ5Jp2RbQ0c?t=3461&si=JjxPB_Zo1vaV6YzM',
        boss: 'bane',
        feats: [
          { name: 'Stun', qty: 2 },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'cad', name: 'Cad' },
          { image: 'han', name: 'Han' },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'jango', name: 'Jango'  }
        ]
      },
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Hunter with Padawan Sabine in your squad and with only dark side units',
    keycards: 11,
    sectorBoss: true,
    plan: 'day4',
    sector: 3,
    teams: [
      {
        name: 'Sabine',
        videoThumb: 'rZ5Jp2RbQ0c?t=5393&si=0oJV96ffLuRRuX9I',
        boss: 'hunter',
        default: true,
        feats: [
          { name: 'Stun', qty: 5 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'padawan-sabine', name: 'Padawan Sabine', main: true },
          { image: 'nihilus', name: 'Nihilus' },
          { image: 'traya', name: 'Traya' },
          { image: 'malak', name: 'Malak' }
        ]
      },
      {
        name: 'Dark Side',
        videoThumb: 'rZ5Jp2RbQ0c?t=5641&si=uxXqxJGtpQEaZzzT',
        boss: 'hunter',
        default: true,
        feats: [
          { name: 'No Lead', qty: 1 },
          { name: 'Stun', qty: 5 },
        ],
        global: [
          { name: 'Dark UFUs' },
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'marrok', name: 'Marrok' },
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        videoThumb: 'rZ5Jp2RbQ0c?t=5508&si=VpOAd4Ll1xdu_M5m',
        boss: 'hunter',
        feats: [
          { name: 'Stun', qty: 5 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'nihilus', name: 'Nihilus' },
          { image: 'traya', name: 'Traya' },
          { image: 'malak', name: 'Malak' }
        ]
      },
    ]
  }
];

export {
  sector3
}