import React, { useState, useEffect } from 'react';

import Page from '../../components/page';
import ScoreBar from '../../components/scorebar';
import Target from '../../components/target';
import Zone from '../../components/zone';
import PlannerIntro from './components/intro';
import PlannerTutorial from './components/tutorial';
import { currentScore, getTargetAvgBanners, getCurrentAvgBanners, getMaxTeams, removeSelectedToons, getMaxBanners, getUnitByBase } from './components/utils';
import { toons, capitals } from './data/planner/characters';

const offenseToons = [...toons, ...capitals];

const GacPlanner = (props) => {

  const [defenseTeams, setDefense] = useState({ north: [], south: [], fleet: [], trap: [] });
  const [offenseTeams, setOffense] = useState([]);
  const [formConfig, setConfig] = useState({ division: '1', format: '3v3', target: '2080' });
  const [wins, updateWins] = useState(0);
  const [defenseList, filterDefense] = useState(toons);
  const [fleetList, filterFleet] = useState(capitals);
  const [offenseList, filterOffense] = useState(offenseToons);
  const { location, history } = props;
  const populateZones = location.pathname === '/gac/populate';

  useEffect(() => {
    document.title = 'Grand Arena Attack Planner - SWGOH-4 Life';
    const defenseTeams = JSON.parse(localStorage.getItem("defenseTeams"));
    defenseTeams && setDefense(defenseTeams);
    const offenseTeams = JSON.parse(localStorage.getItem("offenseTeams"));
    offenseTeams && setOffense(offenseTeams);
    const formConfig = JSON.parse(localStorage.getItem("formConfig"));
    formConfig && setConfig(formConfig);
    const wins = JSON.parse(localStorage.getItem("wins"));
    wins && updateWins(wins);
    const defenseList = JSON.parse(localStorage.getItem("defenseList"));
    defenseList && filterDefense(defenseList);
    const fleetList = JSON.parse(localStorage.getItem("fleetList"));
    fleetList && filterFleet(fleetList);
    const offenseList = JSON.parse(localStorage.getItem("offenseList"));
    offenseList && filterOffense(offenseList);
  }, []);

  const setToonDefense = (selected, zone) => {
    let updateZone = [
      ...defenseTeams[zone],
      selected[0]
    ];
    let updateTeams = {
      ...defenseTeams,
      [zone]: updateZone
    }
    setDefense(updateTeams);
    localStorage.setItem("defenseTeams", JSON.stringify(updateTeams));
    zone === 'fleet' ? filterFleet(removeSelectedToons(capitals, updateTeams, true)) : filterDefense(removeSelectedToons(toons, updateTeams, true));
    zone === 'fleet' ? localStorage.setItem("fleetList", JSON.stringify(removeSelectedToons(capitals, updateTeams, true))) : localStorage.setItem("defenseList", JSON.stringify(removeSelectedToons(toons, updateTeams, true)));
  }

  const setToonOffense = (selected) => {
    let updateToons = [
      ...offenseTeams,
      selected[0]
    ];
    setOffense(updateToons);
    localStorage.setItem("offenseTeams", JSON.stringify(updateToons));
    filterOffense(removeSelectedToons(offenseToons, updateToons));
    localStorage.setItem("offenseList", JSON.stringify(removeSelectedToons(offenseToons, updateToons)));
  }

  const removeToonOffense = (selected) => {
    let updateToons = offenseTeams.filter(r => r.base_id !== selected);
    setOffense(updateToons);
    localStorage.setItem("offenseTeams", JSON.stringify(updateToons));
    filterOffense(removeSelectedToons(offenseToons, updateToons));
    localStorage.setItem("offenseList", JSON.stringify(removeSelectedToons(offenseToons, updateToons)));
  }

  const onBannersAdd = (banners, zone, base) => {
    let addBanners = defenseTeams[zone].map((b) => b.base_id === base ? { ...b, banners: banners } : b);
    let updateZones = {
      ...defenseTeams,
      [zone]: addBanners
    }
    setDefense(updateZones);
    localStorage.setItem("defenseTeams", JSON.stringify(updateZones));
    updateWins(wins + 1);
    localStorage.setItem("wins", JSON.stringify(wins + 1));
  }

  const formChange = (e) => {
    let value = e.target.value ? e.target.value : e.target.dataset.val;
    let id = e.target.dataset.id;
    let updateConfig = {
      ...formConfig,
      [id]: value
    }
    setConfig(updateConfig);
    localStorage.setItem("formConfig", JSON.stringify(updateConfig));
  }

  const setCounter = (drag, drop) => {
    if (drop.zone) {
      let zoneToons = [...defenseTeams[drop.zone]];
      let updateZone = [];

      zoneToons.forEach((t) => {
        if (t.base_id === drop.base_id) {
          updateZone.push({
            name: t.name, base_id: t.base_id, ggImage: t.ggImage,
            off: { name: drag.name, base_id: drag.image, ggImage: drag.ggImage }
          });
        }
        else {
          updateZone.push(t);
        }
      });

      let updateTeams = {
        ...defenseTeams,
        [drop.zone]: updateZone
      }
      setDefense(updateTeams);
      localStorage.setItem("defenseTeams", JSON.stringify(updateTeams));
      let updateOffense = offenseTeams.map((o) => o.base_id === drag.image ? { ...o, assigned: true } : o);
      if (drop.current) {
        updateOffense = updateOffense.map((o) => o.base_id === drop.current.base_id ? { ...o, assigned: false } : o);
      } 
      setOffense(updateOffense);
      localStorage.setItem("offenseTeams", JSON.stringify(updateOffense));
    }
  }

  const onToonRemove = (off, def, zone, reset) => {
    let updateZone = defenseTeams[zone].map(t => t.base_id === def ? { ...t, off: null } : t);
    let resetZone = defenseTeams[zone].filter(r => r.base_id !== def);
    let updateOffense = offenseTeams.map(o => o.base_id === off ? { ...o, assigned: false } : o);

    let updateTeams = {
      ...defenseTeams,
      [zone]: reset ? resetZone : updateZone
    }
    setOffense(updateOffense);
    localStorage.setItem("offenseTeams", JSON.stringify(updateOffense));
    setDefense(updateTeams);
    localStorage.setItem("defenseTeams", JSON.stringify(updateTeams));
    zone === 'fleet' ? filterFleet(removeSelectedToons(capitals, updateTeams, true)) : filterDefense(removeSelectedToons(toons, updateTeams, true));
    zone === 'fleet' ? localStorage.setItem("fleetList", JSON.stringify(removeSelectedToons(capitals, updateTeams, true))) : localStorage.setItem("defenseList", JSON.stringify(removeSelectedToons(toons, updateTeams, true)));
  }

  const resetBoard = (isImport) => {
    localStorage.removeItem("defenseTeams")
    setDefense({ north: [], south: [], fleet: [], trap: [] });
    localStorage.removeItem("wins");
    updateWins(0);
    localStorage.removeItem("defenseList");
    filterDefense(toons);
    localStorage.removeItem("fleetList");
    filterFleet(capitals);
    unassignOffense(offenseTeams, defenseTeams)
  }

  const unassignOffense = (offense) => {
    let updateOffense = offense.map(o => { return { ...o, assigned: false } });
    setOffense(updateOffense);
    localStorage.setItem("offenseTeams", JSON.stringify(updateOffense));
  }

  const clearOffense = () => {
    setOffense([]);
    localStorage.removeItem("offenseTeams");
    filterOffense(offenseToons);
    localStorage.removeItem("offenseList");

    let updateNorth = defenseTeams['north'].map(n => { return { base_id: n.base_id, name: n.name, ggImage: n.ggImage } });
    let updateSouth = defenseTeams['south'].map(s => { return { base_id: s.base_id, name: s.name, ggImage: s.ggImage } });
    let updateFleet = defenseTeams['fleet'].map(f => { return { base_id: f.base_id, name: f.name, ggImage: f.ggImage } });
    let updateTrap = defenseTeams['trap'].map(t => { return { base_id: t.base_id, name: t.name, ggImage: t.ggImage } });
    let updateDefense = { north: updateNorth, south: updateSouth, fleet: updateFleet, trap: updateTrap };
    setDefense(updateDefense);
    localStorage.setItem("defenseTeams", JSON.stringify(updateDefense));
  }

  const importZones = () => {
    resetBoard();
    const buildUrl = new URL(window.location.href);
    let league = buildUrl.searchParams.get('league');
    let division = league === 'kyber' ? 1 : league === 'aurodium' ? 2 : league === 'chromium' ? 3 : league === 'bronzium' ? 4 : league === 'carbonite' && 5;
    let format = buildUrl.searchParams.get('format');
    let north = buildUrl.searchParams.get('north') !== '' && buildUrl.searchParams.get('north').split(',');
    let south = buildUrl.searchParams.get('south') !== '' && buildUrl.searchParams.get('south').split(',');
    let trap = buildUrl.searchParams.get('trap') !== '' && buildUrl.searchParams.get('trap').split(',');
    let fleet = buildUrl.searchParams.get('fleet') !== '' && buildUrl.searchParams.get('fleet').split(',');

    let updateZones = {
      north: north && north.length > 0 ? north.map(n => getUnitByBase(n)) : [],
      south: south.length > 0 ? south.map(n => getUnitByBase(n)) : [],
      fleet: fleet.length > 0 ? fleet.map(n => getUnitByBase(n, 'fleet')) : [],
      trap: trap.length > 0 ? trap.map(n => getUnitByBase(n)) : [],
    };
    let updateConfig = { division: division, format: format, target: '2080' };
    setConfig(updateConfig);
    localStorage.setItem("formConfig", JSON.stringify(updateConfig));
    setDefense(updateZones);
    localStorage.setItem("defenseTeams", JSON.stringify(updateZones));
    history.push('/gac/');
  }

  const GacZone = props =>
    <Zone
      title={props.title} max={props.max} zone={props.zone}
      onUpdate={setToonDefense} toons={defenseTeams[props.zone]}
      onBannersAdd={onBannersAdd} onRemove={onToonRemove}
      options={props.zone === 'fleet' ? fleetList : defenseList}
      format={formConfig.format}
    />;

  const currentBanners = currentScore(formConfig.division, defenseTeams, formConfig.format);

  return (
    <Page modifier="gac-planner" title="Attack Phase Planner" intro={<PlannerIntro onImport={importZones} history={history} populateZones={populateZones} change={formChange} values={formConfig} />} banner={<PlannerTutorial />}>
      {!populateZones &&
        <div className="mb-5 mb-md-6">

          <div className="row align-items-end">
            <div className="col-auto">
              <button type="button" onClick={resetBoard} className="mb-5 btn btn-sm btn-danger">Reset</button>
            </div>
            <div className="col">
              <ScoreBar
                title="Target Score" max={getMaxBanners(formConfig.division, formConfig.format)}
                value={currentBanners} target={formConfig.target}
              />
            </div>
            <div className="col-12 col-sm-auto">
              <Target title="Target Average Banners" value={getTargetAvgBanners(formConfig.division, defenseTeams, formConfig.target, formConfig.format)} current={getCurrentAvgBanners(defenseTeams)} complete="Your Banner Average" />
            </div>
          </div>

          <div className="row">
            <GacZone title="North" zone="north" max={getMaxTeams(formConfig.division, 'north', formConfig.format)} />
            <GacZone title="South" zone="south" max={getMaxTeams(formConfig.division, 'south', formConfig.format)} />
            <GacZone title="Fleet" zone="fleet" max={getMaxTeams(formConfig.division, 'fleet', formConfig.format)} />
            <GacZone title="Trap" zone="trap" max={getMaxTeams(formConfig.division, 'trap', formConfig.format)} />
          </div>

          <Zone
            title="Offensive Teams" max={100} zone="offense" options={offenseList}
            toons={offenseTeams} onUpdate={setToonOffense} onDrop={setCounter} onRemove={removeToonOffense} clearOffense={clearOffense}
          />
        </div>
      }
    </Page>
  );
}

export default GacPlanner;
