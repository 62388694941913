import React from "react";

const disks = [
  {
    title: `Feat Disks`,
    text: <div>
      <p>These disks are required to complete 2 Global Feats:<br /> Remove Turn Meter 60 times with War of Attrition data disk &amp; Gain Offense 100 times from Deadly Catalyst data disk.</p>
      <p>They are highest priority as completing both of these sector feats will reward you with a total of 30 keycards!</p>
    </div>,
    teams: [
      {
        disk: {
          name: 'Caustic Recourance',
          text: `When a debuff on an enemy expires, they gain Damage Over Time for 2 turns which can't be resisted or evaded.`
        },
        /*image: 'disk-premonition'*/
      },
      {
        disk: {
          name: 'Soresu Form',
          text: `Whenever an ally receives damage, they gain 25% Defense, Speed, and Tenacity (stacking, max 100%) until next time they use and ability during their turn. The next time that ally uses an ability, they deal additional true damage equal to 10 times their Defense to target enemy which can't be evaded. Enemies can't be defeated by this damage.`
        },
        /*image: 'disk-dread'*/
      }
    ]
  },
  {
    title: `Perma-BLock Disk Set`,
    name: `Perma-BLock`,
    id: 'perma-block,',
    recommended: true,
    text: <div>
      <p>This set relies on applying debuffs to keep applying ability blocks at the start of enemy turns while also boosting your damage with Thermal Detonators and stacking offense.</p>
    </div>,
    teams: [
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Ability Exhaust',
        desc: 'Whenever an enemy gains 10 debuffs, they gain Ability Block for 1 turn, which can\'t be resisted or evaded.'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Weak Point',
        desc: 'Whenever an ally attacks, they gain +4% Offense (stacking) for every debuff on all characters.'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Critical Debuffs',
        desc: `Enemy units gain Critical Chance Down, Critical Damage Down and Offense Down for 2 turns at the start of their turn, which can't be resisted.`
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Thermal Exhaust',
        desc: 'Whenever an enemy gains 5 debuffs, they gain 3 stacks of Thermal Detonator for 2 turns, which can\'t be resisted. (This effect doesn\'t stack.)'
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Caustic Emissions',
        desc: 'Enemy units gain two Damage over Time effects and Burning for 2 turns at the start of their turn, which can\'t be resisted.'
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Caustic Emissions',
        desc: 'Enemy units gain two Damage over Time effects and Burning for 2 turns at the start of their turn, which can\'t be resisted.'
      },
      {
        icon: 'cross',
        slots: [''],
        rarity: 'blue',
        name: 'Ruthless: Swiftness',
        desc: 'Whenever an enemy falls below 100% Health all allies gain 27% Turn Meter.'
      },
      {
        icon: 'arrow-up',
        slots: [''],
        rarity: 'blue',
        name: 'Entrenched',
        desc: 'At the start of encounter, all allies gain Protection Up (75%) until they are defeated, which can\'t be dispelled or prevented.'
      },
    ]
  },
  {
    title: `Nuke'em Disk Set`,
    name: `Nuke'em`,
    id: 'nuke-em,',
    recommended: true,
    text: <div>
      <p>This set relies on applying debuffs to provide stacking offense for you squad. As it takes a couple of turns to gain enough offense to start nuking emenies, it also provides good survivability at the start of the battle.</p>
    </div>,
    teams: [
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Weak Point',
        desc: 'Whenever an ally attacks, they gain +4% Offense (stacking) for every debuff on all characters.'
      },
      {
        icon: 'arrow-up',
        slots: ['', '', ''],
        rarity: 'blue',
        name: 'Guard and Penetrate',
        desc: `At the start of battle allied units gain 300% Defense. Whenever an ally is damaged by an enemy, they lose 10% of this Defense and gain 10 Defense Penetration. Whenever an enemy is defeated, all allies gain 50% Defense. (This effect doesn't stack)`
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Undercover',
        desc: `The first time each ally drops below 50% Health, if they're not a Tank, they Stealth for 2 turns and recover 40% Health and Protection, if they are a Tank, they recover 80% Health and Protection.`
      },
      {
        icon: 'arrow-up',
        slots: [''],
        rarity: 'blue',
        name: 'Entrenched',
        desc: 'At the start of encounter, all allies gain Protection Up (75%) until they are defeated, which can\'t be dispelled or prevented.'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Critical Debuffs',
        desc: `Enemy units gain Critical Chance Down, Critical Damage Down and Offense Down for 2 turns at the start of their turn, which can't be resisted.`
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Caustic Emissions',
        desc: 'Enemy units gain two Damage over Time effects and Burning for 2 turns at the start of their turn, which can\'t be resisted.'
      },
      {
        icon: 'cross',
        slots: [''],
        rarity: 'blue',
        name: 'Ruthless: Swiftness',
        desc: 'Whenever an enemy falls below 100% Health all allies gain 27% Turn Meter.'
      },
      {
        icon: 'cross',
        slots: [''],
        rarity: 'blue',
        name: 'Ruthless: Debilitation',
        desc: `When an enemy falls below 100% Health they are inflicted with Healing Immunity and 2 stacks of Armor Shred, which can't be dispelled or resisted.`
      }
    ]
  },
  {
    title: `Overzealous Disk Set`,
    name: 'Overzealous',
    id: 'overzealous',
    recommended: false,
    text: <div>
      <p>Supercharge your Support and Healers with this powerful data disk set.<br /> For best results equip 3x stacks of Zealous Ambition data disk.</p>
    </div>,
    teams: [
      {
        icon: 'arrow-up',
        slots: ['', '', ''],
        rarity: 'blue',
        name: 'Fortified',
        desc: 'At the start of battle allied units gain 300% Defense. Whenever an ally is damaged by an enemy, they lose 5% of this Defense and gain 3% Turn Meter. Whenever an enemy is defeated, all allies regain 50% Defense.'
      },
      {
        icon: 'circle',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Zealous Ambition',
        desc: 'Allied Healers and Supports gain Offense equal to 54.4% of their Max Health.'
      },
      {
        icon: 'circle',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Zealous Ambition',
        desc: 'Allied Healers and Supports gain Offense equal to 54.4% of their Max Health.'
      },
      {
        icon: 'circle',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Zealous Ambition',
        desc: 'Allied Healers and Supports gain Offense equal to 54.4% of their Max Health.'
      },
      {
        icon: 'arrow-up',
        slots: [''],
        rarity: 'blue',
        name: 'Entrenched',
        desc: 'At the start of encounter, all allies gain Protection Up (75%) until they are defeated, which can\'t be dispelled or prevented.'
      },
      {
        icon: 'circle',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Vitality',
        desc: 'Allied units gain 35% Max Health and 28% Max Protection.'
      }
    ]
  },
  {
    title: `Stoneskin Disk Set`,
    name: 'Stoneskin',
    id: 'stoneskin',
    recommended: false,
    text: <div>
      <p>Make your tanks invinicible with this data disk set, while you ramp up your offense.</p>
    </div>,
    teams: [
      {
        icon: 'arrow-up',
        slots: ['', '', ''],
        rarity: 'blue',
        name: 'Fortified',
        desc: 'At the start of battle allied units gain 300% Defense. Whenever an ally is damaged by an enemy, they lose 5% of this Defense and gain 3% Turn Meter. Whenever an enemy is defeated, all allies regain 50% Defense.'
      },
      {
        icon: 'circle',
        slots: ['', ''],
        rarity: 'green',
        name: 'Voluntary Vanguard',
        desc: 'At the start of battle, the strongest ally gains Marked. Allies take 30% reduced damage as long as the Marked ally is alive (This effect doesn\'t stack).'
      },
      {
        icon: 'arrow-up',
        slots: [''],
        rarity: 'blue',
        name: 'Heal Over Time',
        desc: 'Allied units gain 4 Heal Over Time effects (stacking) for 2 turns at the start of their turn.'
      },
      {
        icon: 'arrow-up',
        slots: [''],
        rarity: 'blue',
        name: 'Entrenched',
        desc: 'At the start of encounter, all allies gain Protection Up (75%) until they are defeated, which can\'t be dispelled or prevented.'
      },
      {
        icon: 'arrow-up',
        slots: [''],
        rarity: 'blue',
        name: 'Entrenched',
        desc: 'At the start of encounter, all allies gain Protection Up (75%) until they are defeated, which can\'t be dispelled or prevented.'
      },
      {
        icon: 'arrow-up',
        slots: ['', ''],
        rarity: 'green',
        name: 'Stacking Offense',
        desc: 'Whenever an ally gains a buff, they also gain 5% Offense (stacking).'
      },
      {
        icon: 'arrow-up',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Protection Shield',
        desc: 'Whenever an ally is critically hit, all allies recover 15% Protection'
      },
    ]
  },
  {
    title: `'Gone in 30s' Disk Set`,
    name: 'Gone in 30s',
    id: 'gone-30',
    recommended: false,
    text: <div>
      <p>This set will allow you destroy most teams in 30 seconds or less. Kills from this set will count towards 'defeat enemies' feats.</p>
    </div>,
    teams: [
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Amplify Agony',
        desc: 'Whenever an ally inflicts a debuff on an enemy, they also deal 8.16% Max Health damage to that enemy. This damage can\t defeat enemies.'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Amplify Agony',
        desc: 'Whenever an ally inflicts a debuff on an enemy, they also deal 8.16% Max Health damage to that enemy. This damage can\t defeat enemies.'
      },
      {
        icon: 'star',
        slots: [''],
        rarity: 'blue',
        name: 'Decay: Turn Meter',
        desc: 'Whenever an ally deals damage to an enemy, that enemy loses 8% Turn Meter.'
      },
      {
        icon: 'star',
        slots: ['', '', ''],
        rarity: 'blue',
        name: 'Volatile Accelerator',
        desc: 'When an ally deals damage to an enemy, that enemy gains 8% Turn Meter and 3 Damage Over Time for 2 turns, which can\'t be resisted or evaded. (This disk doesn\'t stack)'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Weak Point',
        desc: 'Whenever an ally attacks, they gain +4% Offense (stacking) for every debuff on all characters.'
      },
      {
        icon: 'arrow-up',
        slots: ['', ''],
        rarity: 'green',
        name: 'Protection Shield',
        desc: 'Whenever an ally is critically hit, all allies recover 15% Protection.'
      }
    ]
  },
  {
    title: `'Self-destruct' Disk Set`,
    name: 'Self-destruct',
    id: 'self-destruct',
    recommended: false,
    text: <div>
      <p>This set will allow you to easily handle all those Steadfast Retribution enemies, as well as make Bossk-led Bounty Hunters almost unkillable. Great vs enemies with Steadfast Retribution. This set is not recommended for 'defeat enemies' feats as most enemies will die from thermal detonators.</p>
    </div>,
    teams: [
      {
        icon: 'star',
        slots: ['', '', ''],
        rarity: 'blue',
        name: 'Volatile Accelerator',
        desc: 'When an ally deals damage to an enemy, that enemy gains 8% Turn Meter and 3 Damage Over Time for 2 turns, which can\'t be resisted or evaded. (This disk doesn\'t stack)'
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Caustic Emissions',
        desc: 'Enemy units gain two Damage over Time effects and Burning for 2 turns at the start of their turn, which can\'t be resisted.'
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Caustic Emissions',
        desc: 'Enemy units gain two Damage over Time effects and Burning for 2 turns at the start of their turn, which can\'t be resisted.'
      },
      {
        icon: 'arrow-down',
        slots: [''],
        rarity: 'blue',
        name: 'Caustic Emissions',
        desc: 'Enemy units gain two Damage over Time effects and Burning for 2 turns at the start of their turn, which can\'t be resisted.'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'blue',
        name: 'Thermal Exhaust',
        desc: 'Whenever an enemy gains 5 debuffs, they gain 3 stacks of Thermal Detonator for 2 turns, which can\'t be resisted. (This effect doesn\'t stack.)'
      },
      {
        icon: 'arrow-down',
        slots: ['', ''],
        rarity: 'green',
        name: 'Ability Exhaust',
        desc: 'Whenever an enemy gains 10 debuffs, they gain Ability Block for 1 turn, which can\'t be resisted or evaded.'
      }
    ]
  }
];

export {
  disks
}