export const videos = [
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/h-Zv4joebiA?t=2882&si=tr0MAROoxYzSvOme', creator: 'tas',
    counter: 'saw', banners: 62, squad: ['omi-traya', 'omi-savage', 'sion', 'omi-talon', 'nihilus'], defense: ['omi-saw', 'omi-luthen', 'baze', 'kyle', 'cara']
  },
  {
    name: 'Finn', value: 'finn', url: 'https://youtu.be/nY-S8knfiNQ?t=8609&si=YYmI4i7E53ljWS29', creator: 'tas',
    counter: 'savage', banners: 65, squad: ['finn', 'omi-zorii', 'omi-rose', 'hero-finn', 'hero-poe'], defense: ['omi-savage', 'omi-traya', 'omi-talon', 'nihilus', 'vader']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/nY-S8knfiNQ?t=7039&si=lUyr60b9pU1cZHyE', creator: 'tas',
    counter: 'baylen', banners: 57, squad: ['slkr', 'hux', 'fost', 'sith-trooper', 'foo'], defense: ['omi-bane', 'shin', 'marrok', 'omi-hondo', 'qira']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/dCcTB_n9dqc?t=1190&si=VInNx0QpelVVv3gM', creator: 'songeta',
    counter: 'amidala', banners: 65, squad: ['omi-reva', 'gi', 'seventh-sister', 'fifth-brother', 'eighth-brother'], defense: ['omi-amidala', 'padawan-obi', 'master-qgj', 'yoda', 'shaak-ti']
  },
  {
    name: 'Savage', value: 'savage', url: 'https://youtu.be/qWV-o3wRyh8?t=4729&si=x6OHnq6kXz5HVc39', creator: 'tas',
    counter: 'boss-nass', banners: 62, squad: ['omi-savage', 'omi-traya', 'talon', 'sion', 'nihilus'], defense: ['boss-nass', 'omi-hondo', 'tarpals', 'phalanx', 'boomadier']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/qWV-o3wRyh8?t=3614&si=POqMyO0JjNu-Jqik', creator: 'tas',
    counter: 'leia-organa', banners: 62, squad: ['jml', 'jkr', 'jedi-cal', 'hermit', 'jkl'], defense: ['leia-organa', 'r2', 'omi-drogan', 'ben', 'omi-captain-rex']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/r_eHvCNs9q0?t=4715&si=7tIDCj2cE6YId5gM', creator: 'tas',
    counter: 'trench', banners: 65, squad: ['omi-traya', 'omi-savage', 'nihilus', 'sion', 'talon'], defense: ['trench', 'nute', 'wat', 'jango', 'dooku']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/r_eHvCNs9q0?t=3857&si=gaQ8RqpP6AMQmigi', creator: 'tas',
    counter: 'saw', banners: 62, squad: ['omi-cere', 'omi-malicos', 'cal', 'fulcrum', 'kru'], defense: ['omi-saw', 'omi-luthen', 'baze', 'kyle', 'chirrut']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/r_eHvCNs9q0?t=2746&si=2IdDdOjqYFy1BSPJ', creator: 'tas',
    counter: 'gas', banners: 65, squad: ['aurra', 'mando', 'greef', 'omi-zam', 'bossk'], defense: ['gas', 'rex', 'arc', 'echo', 'fives']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/r_eHvCNs9q0?t=1617&si=7nWp1A5j4beqvsdV', creator: 'tas',
    counter: 'baylen', banners: 59, squad: ['omi-reva', 'gi', 'seventh-sister', 'fifth-brother', 'eighth-brother'], defense: ['omi-bane', 'shin', 'marrok', 'omi-hondo', 'dengar']
  },
  {
    name: 'Bane', value: 'Bane', url: 'https://youtu.be/ZrHze9eQXgY?t=1100&si=2lnrNm1lhtAgVlkh', creator: 'songeta',
    counter: 'amidala', banners: 65, squad: ['omi-bane', 'dooku'], defense: ['omi-amidala', 'padawan-obi', 'master-qgj', 'barriss', 'shaak-ti']
  },
  {
    name: 'JMK', value: 'jmk', url: 'https://youtu.be/ZrHze9eQXgY?t=980&si=CUi8RC3aaQoEa6lF', creator: 'songeta',
    counter: 'gm', banners: 65, squad: ['jmk', 'ahsoka', 'padme', 'plo', 'cat'], defense: ['gm', 'spirit', 'omi-morgan', 'night-trooper', 'peridea']
  },
  {
    name: 'Boss Nass', value: 'boss-nass', url: 'https://youtu.be/ZrHze9eQXgY?t=770&si=ATUKWo8HtNtr5TKS', creator: 'songeta',
    counter: 'kelleran', banners: 65, squad: ['boss-nass', 'omi-jar-jar', 'tarpals', 'phalanx', 'boomadier'], defense: ['kelleran', 'kam', 'jka', 'mace', 'eeth']
  },
  {
    name: 'DR', value: 'dr', url: 'https://youtu.be/geOh-lhzTgM?t=2620&si=JiJcpO4EFG2ZINVQ', creator: 'songeta',
    counter: 'traya', banners: 64, squad: ['dr', 'malgus', 'malak', 'fallen-basti', 'marauder'], defense: ['traya', 'sion', 'nihilus', 'talon', 'marauder']
  },
  {
    name: 'Bo', value: 'bo-mandalor', url: 'https://youtu.be/BQuk0DKf8gk?t=1598&si=J5MhDaNawJOIqly_', creator: 'vex',
    counter: 'kelleran', banners: 65, squad: ['omi-bo-mandalor', 'ig-12', 'bokatan', 'bam', 'paz'], defense: ['kelleran', 'kam', 'jka', 'mace', 'eeth']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/BQuk0DKf8gk?t=1598&si=Y-OCBwevMROH4zfa', creator: 'vex',
    counter: 'gas', banners: 63, squad: ['palpatine', 'mara', 'rjt', 'omi-starkiller', 'gk'], defense: ['gas', 'rex', 'arc', 'echo', 'fives']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/BQuk0DKf8gk?t=1193&si=9hvERWVUuVOa50SZ', creator: 'vex',
    counter: 'gm', banners: 65, squad: ['see', 'bane'], defense: ['gm', 'omi-morgan', 'zombie', 'night-trooper', 'peridea']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/BQuk0DKf8gk?t=1078&si=sTMdFRLIIrJwcFzO', creator: 'vex',
    counter: 'aphra', banners: 65, squad: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister'], defense: ['aphra', 'omi-zam', 'dengar', 'trip', 'bt-1']
  },
  {
    name: 'Aphra', value: 'aphra', url: 'https://youtu.be/BQuk0DKf8gk?t=988&si=_2gxSdGGcIgsiIML', creator: 'vex',
    counter: 'finn', banners: 65, squad: ['omi-aphra', 'ig88', 'b2', 'trip', 'bt-1'], defense: ['finn', 'zorii', 'hero-finn', 'poe', 'hero-poe']
  },
  {
    name: 'DT Gideon', value: 'dt-gideon', url: 'https://youtu.be/BQuk0DKf8gk?t=602&si=EorRHUrbDpIH2kmf', creator: 'vex',
    counter: 'saw', banners: 65, squad: ['dt-gideon', 'night-trooper', 'gideon', 'enoch', 'scout-trooper'], defense: ['omi-saw', 'omi-luthen', 'baze', 'kyle', 'omi-captain-rex']
  },
  {
    name: 'Cere', value: 'cere', url: 'https://youtu.be/BQuk0DKf8gk?t=183&si=VOfFJGn0yEG7f9zq', creator: 'vex',
    counter: 'rey', banners: 65, squad: ['omi-cere', 'omi-malicos', 'kru', 'fulcrum', 'cal'], defense: ['rey', 'omi-ben-solo', 'omi-ezra-exile', 'barriss', 'sortie']
  },
  {
    name: 'DT Gideon', value: 'dt-gideon', url: 'https://www.youtube.com/live/k3ya9AeQpiM?t=4653&si=h7j1-UbWXFvWMqQY',
    counter: 'kelleran', banners: 65, squad: ['dt-gideon', 'stormtrooper', 'gideon', 'death-trooper', 'scout-trooper'], defense: ['kelleran', 'kam', 'jka', 'mace', 'eeth']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://www.youtube.com/live/k3ya9AeQpiM?t=4522&si=mH_WpbbUP3LCpjTp',
    counter: 'saw', banners: 65, squad: ['omi-bane', 'sith-trooper'], defense: ['omi-saw', 'chirrut', 'baze', 'drogan', 'omi-captain-rex']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://www.youtube.com/live/k3ya9AeQpiM?t=4088&si=kDp7AHljcQd4A-P2',
    counter: 'mothma', banners: 62, squad: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister'], defense: ['mothma', 'pao', 'omi-luthen', 'hoth-scout', 'kyle']
  },
  {
    name: 'JMK', value: 'jmk', url: 'https://www.youtube.com/live/k3ya9AeQpiM?t=3176&si=sM6HYBnXTfmIMFpT',
    counter: 'jml', banners: 64, squad: ['jmk', 'ahsoka', 'padme', 'plo', 'cat'], defense: ['jml', 'jedi-cal', 'jkl', 'jkr', 'plo']
  },
  {
    name: 'JML', value: 'jml', url: 'https://www.youtube.com/live/0Ju5Ii75L3Y?t=1716&si=FiSOMwgJggn9jUaQ',
    counter: 'boss-nass', banners: 64, squad: ['jml', 'barriss', 'jkr', 'jkl', 'jedi-cal'], defense: ['boss-nass', 'omi-jar-jar', 'tarpals', 'phalanx', 'boomadier']
  },
  {
    name: 'Hera', value: 'hera', url: 'https://www.youtube.com/live/OVzwY0NUeU8?t=5282&si=nJl5X0C7tRfCyCL-',
    counter: 'trench', banners: 65, squad: ['hera', 'omi-captain-rex', 'chopper', 'sabine', 'kanan'], defense: ['trench', 'nute', 'wat', 'jango', 'dooku']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://www.youtube.com/live/OVzwY0NUeU8?t=4410&si=SEGG2P62OR9LJKeL',
    counter: 'hera', banners: 64, squad: ['omi-traya', 'sion', 'omi-savage', 'talon', 'nihilus'], defense: ['hera', 'omi-captain-rex', 'chopper', 'sabine', 'kanan']
  },
  {
    name: 'Hera', value: 'hera', url: 'https://youtu.be/JGbUxFgVBQg?t=1695&si=pbQr38PQMkpUNfr-',
    counter: 'cls', banners: 65, squad: ['hera', 'omi-captain-rex', 'chopper', 'sabine', 'kanan'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Admiral Raddus', value: 'raddus', url: 'https://youtu.be/JGbUxFgVBQg?t=1490&si=fcdCR1_bu4E3btBB',
    counter: 'dt-gideon', banners: 63, squad: ['omi-raddus', 'jyn', 'baze', 'k2', 'cassian'], defense: ['dt-gideon', 'stormtrooper', 'gideon', 'death-trooper', 'scout-trooper']
  },
  {
    name: 'Bo', value: 'bo-mandalor', url: 'https://youtu.be/ZrHze9eQXgY?t=1755&si=I-6C391t-rf86hhH', creator: 'songeta',
    counter: 'boss-nass', banners: 62, squad: ['omi-bo-mandalor', 'ig-12', 'bokatan', 'bam', 'paz'], defense: ['boss-nass', 'omi-jar-jar', 'tarpals', 'phalanx', 'boomadier']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/JGbUxFgVBQg?t=1026&si=ezFcrJnDwGGtswO1',
    counter: 'amidala', banners: 64, squad: ['gas', 'echo', 'fives', 'rex', 'omi-amidala'], defense: ['omi-amidala', 'padawan-obi', 'master-qgj', 'barriss', 'shaak-ti']
  },
  {
    name: 'Kelleran', value: 'kelleran', url: 'https://youtu.be/JGbUxFgVBQg?t=449&si=NF47aco06spvioCi',
    counter: 'trench', banners: 60, squad: ['kelleran', 'jka', 'mace', 'gmy', 'kam'], defense: ['trench', 'nute', 'wat', 'jango', 'dooku']
  },
  {
    name: 'Aphra', value: 'aphra', url: 'https://youtu.be/JGbUxFgVBQg?t=131&si=XmmAC-hr6mCjtBYF',
    counter: 'jabba', banners: 61, squad: ['omi-aphra', 'ipd', 'vader', 'trip', 'bt-1'], defense: ['jabba', 'krrsantan', 'skiff-lando', 'boussh-leia', 'boba']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://www.youtube.com/live/rUz4tgZjSGs?t=6123&si=gV-XJp1r_IogNBrj',
    counter: 'finn', banners: 62, squad: ['palpatine', 'mara', 'visas', 'omi-starkiller', 'ben'], defense: ['finn', 'omi-zorii', 'hero-finn', 'poe', 'hero-poe']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://www.youtube.com/live/rUz4tgZjSGs?t=2802&si=ZM4P4P0mIZ6L7_GI',
    counter: 'leia-organa', banners: 65, squad: ['omi-bane', 'dooku'], defense: ['leia-organa', 'r2', 'omi-drogan', 'ben', 'sth']
  },
  {
    name: 'SEE', value: 'see', url: 'https://www.youtube.com/live/rUz4tgZjSGs?t=1090&si=PscCU0u9DUrGgGHR',
    counter: 'bo-mandalor', banners: 52, squad: ['see', 'gba', 'set', 'armorer', 'maul'], defense: ['omi-bo-mandalor', 'bam', 'bokatan', 'ig-12', 'paz']
  },
  {
    name: 'JKR', value: 'jkr', url: 'https://www.youtube.com/live/bBq3LGv7_Lw?t=4528&si=VERnmdXCGzY74hnP',
    counter: 'qgj', banners: 65, squad: ['jkr', 'jedi-cal', 'jkl', 'jolee', 'hermit'], defense: ['omi-qgj', 'kam', 'jka', 'gk', 'kelleran']
  },
  {
    name: 'Talzin', value: 'talzin', url: 'https://www.youtube.com/live/bBq3LGv7_Lw?t=2435&si=xiBm1adygj_IkQZP',
    counter: 'dash', banners: 64, squad: ['talzin', 'daka', 'asajj', 'zombie', 'omi-merrin'], defense: ['omi-dash', 'vandor-chewie', 'hondo', 'ig-11', 'nest']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://www.youtube.com/live/bBq3LGv7_Lw?t=2217&si=dchLFXdMQhj-U2l-',
    counter: 'dt-gideon', banners: 55, squad: ['slkr', 'hux', 'foo', 'fost', 'sith-trooper'], defense: ['dt-gideon', 'stormtrooper', 'gideon', 'death-trooper', 'scout-trooper']
  },
  {
    name: 'Tusken Chief', value: 'tusken-chief', url: 'https://youtu.be/XM0W2FBR48s?t=731&si=LxkMDQ-tFLYhSF01',
    counter: 'mothma', banners: 65, squad: ['tusken-chief', 'tusken-warrior', 'tusken-raider', 'uror', 'tusken-shaman'], defense: ['mothma', 'pao', 'biggs', 'cara', 'kyle']
  },
  {
    name: 'Tusken Chief', value: 'tusken-chief', url: 'https://youtu.be/XM0W2FBR48s?t=639&si=-mqc4R7JgjYIbA-I',
    counter: 'cls', banners: 60, squad: ['tusken-chief', 'tusken-warrior', 'tusken-raider', 'uror', 'tusken-shaman'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/XM0W2FBR48s?t=522&si=3QaQCbxuJBkAsLmS',
    counter: 'dt-gideon', banners: 64, squad: ['omi-malgus', 'dr', 'fallen-basti', 'sith-assassin', 'malak'], defense: ['dt-gideon', 'stormtrooper', 'gideon', 'death-trooper', 'scout-trooper']
  },
  {
    name: 'Leia', value: 'leia-organa', url: 'https://youtu.be/XM0W2FBR48s?t=421&si=9eNlkBce1m_SCiJV',
    counter: 'lv', banners: 63, squad: ['leia-organa', 'r2', 'drogan', 'admiralraddus', 'jynn'], defense: ['lv', 'rg', 'thrawn', 'mando-maul', 'shoretrooper']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/XM0W2FBR48s?t=327&si=ihlcHPCNcAOxZhVT',
    counter: 'reva', banners: 65, squad: ['omi-bane', 'maul'], defense: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister']
  },
  {
    name: 'Bane', value: 'bane', url: 'https://youtu.be/XM0W2FBR48s?t=216&si=N9c75b3ZPAU8vg4q',
    counter: 'lv', banners: 65, squad: ['omi-bane', 'dooku'], defense: ['lv', 'rg', 'thrawn', 'mando-maul', 'shoretrooper']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/XM0W2FBR48s?t=31&si=WTR-vvPmbSSd8BlA',
    counter: 'rey', banners: 64, squad: ['see', 'omi-bane'], defense: ['rey', 'barriss', 'l3', 'sortie', 'omi-ben-solo']
  },
  {
    name: 'Tusken Chief', value: 'tusken-chief', url: 'https://youtu.be/Hs06y0IEpek?t=728&si=7SNLoGmtsoErMgH6',
    counter: 'talzin', banners: 65, squad: ['tusken-chief', 'tusken-warrior', 'tusken-raider', 'uror', 'tusken-shaman'], defense: ['talzin', 'daka', 'asajj', 'zombie', 'omi-merrin']
  },
  {
    name: 'Raddus', value: 'admiralraddus', url: 'https://youtu.be/Hs06y0IEpek?t=638&si=2Zpg41f-kTCoTj0-',
    counter: 'tusken-chief', banners: 65, squad: ['omi-raddus', 'jyn', 'cassian', 'k2', 'pathfinder'], defense: ['tusken-chief', 'tusken-warrior', 'tusken-raider', 'uror', 'tusken-shaman']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/Hs06y0IEpek?t=554&si=b0TlDJJ5zN_pt4lJ',
    counter: 'vader', banners: 65, squad: ['aurra', 'omi-zam', 'mando', 'bossk', 'greef'], defense: ['vader', 'omi-starkiller', 'barriss', 'kylo', 'visas']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/Hs06y0IEpek?t=419&si=3wABwhkKBlnrpMlW',
    counter: 'reva', banners: 62, squad: ['omi-malgus', 'dr', 'fallen-basti', 'sith-assassin', 'malak'], defense: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister']
  },
  {
    name: 'Jabba', value: 'jabba', url: 'https://youtu.be/Hs06y0IEpek?t=305&si=YiwtEXRIMYNhBq1C',
    counter: 'jmk', banners: 60, squad: ['jabba', 'krrsantan', 'skiff-lando', 'boussh-leia', 'embo'], defense: ['jmk', 'cat', 'ahsoka', 'padme', 'mace']
  },
  {
    name: 'Leia', value: 'leia-organa', url: 'https://youtu.be/Hs06y0IEpek?t=189&si=Wre4DdGjVRemx2v1',
    counter: 'rey', banners: 60, squad: ['leia-organa', 'r2', 'drogan', 'ben', 'mothma'], defense: ['rey', 'gk', 'l3', 'rjt', 'omi-ben-solo']
  },
  {
    name: 'Dark Gideon', value: 'dt-gideon', url: 'https://youtu.be/Hs06y0IEpek?t=85&si=uPmM4ZmeIt6BWA7X',
    counter: 'cls', banners: 65, squad: ['dt-gideon', 'stormtrooper', 'gideon', 'death-trooper', 'scout-trooper'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Dark Gideon', value: 'dt-gideon', url: 'https://youtu.be/Hs06y0IEpek?t=4&si=RFkFCcPTL3wvLzUh',
    counter: 'dash', banners: 65, squad: ['dt-gideon', 'stormtrooper', 'gideon', 'death-trooper', 'scout-trooper'], defense: ['omi-dash', 'vandor-chewie', 'omi-hondo', 'l3', 'nest']
  },
  {
    name: 'Raddus', value: 'admiralraddus', url: 'https://youtu.be/YwTEr4zjJPM?t=1266&si=iA5s-qRkSXmWfuZW',
    counter: 'iden', banners: 65, squad: ['omi-raddus', 'jyn', 'cassian', 'bistan', 'pathfinder'], defense: ['omi-iden', 'scout-trooper', 'death-trooper', 'starck', 'stormtrooper']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/YwTEr4zjJPM?t=1229&si=yC86QPRrgxO1wFgy',
    counter: 'tarfful', banners: 65, squad: ['hunter', 'tech', 'bb-echo', 'wrecker', 'omega'], defense: ['tarfful', 'vet-chewie', 'zaalbar', 'vandor-chewie', 'threepio']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/YwTEr4zjJPM?t=1150&si=xL63NQq3S6CboBWm',
    counter: 'dt-gideon', banners: 65, squad: ['hunter', 'tech', 'bb-echo', 'wrecker', 'omega'], defense: ['dt-gideon', 'stormtrooper', 'dark-trooper', 'death-trooper', 'scout-trooper']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/YwTEr4zjJPM?t=1059&si=KsKubYYjoo6OsCIN',
    counter: 'cere', banners: 65, squad: ['see', 'set', 'wat'], defense: ['cere', 'omi-malicos', 'fulcrum', 'kru', 'cal']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/YwTEr4zjJPM?t=970&si=05y9Z7tfOIXwCRj_',
    counter: 'cere', banners: 65, squad: ['omi-malgus', 'dr', 'fallen-basti', 'marauder', 'malak'], defense: ['omi-cere', 'omi-malicos', 'fulcrum', 'visas', 'cal']
  },
  {
    name: 'Malgus', value: 'malgus', url: 'https://youtu.be/YwTEr4zjJPM?t=946&si=yNwBU30zzH6hPl5X',
    counter: 'finn', banners: 65, squad: ['omi-malgus', 'dr', 'fallen-basti', 'marauder', 'malak'], defense: ['finn', 'zorii', 'hero-finn', 'poe', 'hero-poe']
  },
  {
    name: 'Jedi Bastila', value: 'jedi-basti', url: 'https://youtu.be/YwTEr4zjJPM?t=799&si=Wd5EOpbvk6lt-l9p',
    counter: 'malgus', banners: 62, squad: ['jedi-basti', 'mace', 'jkl', 'hermit', 'jedi-cal'], defense: ['omi-malgus', 'dr', 'fallen-basti', 'set', 'malak']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/YwTEr4zjJPM?t=683&si=9bntOS4AQO69_EQF',
    counter: 'jmk', banners: 65, squad: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister'], defense: ['jmk', 'cat', 'ahsoka', 'padme', 'gk']
  },
  {
    name: 'Hera', value: 'hera', url: 'https://youtu.be/YwTEr4zjJPM?t=285&si=up9TSxyB-79HjLKZ',
    counter: 'reva', banners: 65, squad: ['hera', 'omi-captain-rex', 'sabine', 'chopper', 'l3'], defense: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister']
  },
  {
    name: 'Jabba', value: 'jabba', url: 'https://youtu.be/YwTEr4zjJPM?t=133&si=rEeCwTZhcuDjt-sM',
    counter: 'leia-organa', banners: 62, squad: ['jabba', 'krrsantan', 'skiff-lando', 'boussh-leia', 'embo'], defense: ['leia-organa', 'r2', 'omi-drogan', 'ben', 'rolo']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/Yw4Suc5iyq0?t=2042',
    counter: 'saw', banners: 63, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['omi-saw', 'chirrut', 'baze', 'biggs', 'wedge']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/Yw4Suc5iyq0?t=1777',
    counter: 'finn', banners: 58, squad: ['omi-traya', 'nihilus', 'sion', 'talon', 'set'], defense: ['finn', 'zorii', 'hero-finn', 'poe', 'hero-poe']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/Yw4Suc5iyq0?t=1639',
    counter: '50rt', banners: 65, squad: ['hunter', 'omega', 'bb-echo', 'tech', 'wrecker'], defense: ['50rt', 'bb', 'hk', 't3', 'chopper']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/Yw4Suc5iyq0?t=1023',
    counter: 'cere', banners: 61, squad: ['gas', 'rex', 'echo', 'fives', 'arc'], defense: ['cere', 'asajj', 'fulcrum', 'kru', 'cal']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/Yw4Suc5iyq0?t=897',
    counter: 'tusken-chief', banners: 65, squad: ['omi-qgj', 'gk', 'jka', 'kam', 'ahsoka'], defense: ['tusken-chief', 'tusken-warrior', 'tusken-raider', 'uror', 'tusken-shaman']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/Yw4Suc5iyq0?t=364',
    counter: 'jabba', banners: 61, squad: ['reva', 'gi', 'seventh-sister', 'eighth-brother', 'fifth-brother'], defense: ['jabba', 'omi-krrsantan', 'skiff-lando', 'boussh-leia', 'boba']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/X1KqkOwW4es?t=2505',
    counter: 'admiralraddus', banners: 65, squad: ['hunter', 'omega', 'bb-echo', 'tech', 'wrecker'], defense: ['omi-raddus', 'jyn', 'cassian', 'bistan', 'pathfinder']
  },
  {
    name: 'Reva', value: 'reva', url: 'https://youtu.be/X1KqkOwW4es?t=1584',
    counter: 'lv', banners: 60, squad: ['reva', 'gi', 'seventh-sister', 'eighth-brother', 'fifth-brother'], defense: ['lv', 'rg', 'canderous', 'mando-maul', 'shoretrooper']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/X1KqkOwW4es?t=177',
    counter: 'rey', banners: 65, squad: ['palpatine', 'omi-starkiller', 'mara', 'rjt', 'ben'], defense: ['rey', 'sortie', 'l3', 'barriss', 'omi-ben-solo']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/-TuwrdkDjHg?t=1920',
    counter: 'dash', banners: 65, squad: ['aurra', 'bossk', 'omi-zam', 'mando', 'greef'], defense: ['omi-dash', 'vandor-chewie', 'young-han', 'l3', 'nest']
  },
  {
    name: 'Finn', value: 'finn', url: 'https://youtu.be/-TuwrdkDjHg?t=1641',
    counter: 'finn', banners: 64, squad: ['finn', 'omi-zorii', 'hero-finn', 'poe', 'resistance-trooper'], defense: ['finn', 'omi-zorii', 'hero-finn', 'poe', 'hero-poe']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/-TuwrdkDjHg?t=1537',
    counter: 'sana', banners: 65, squad: ['hunter', 'omega', 'bb-echo', 'tech', 'wrecker'], defense: ['sana', 'omi-hondo', 'rolo', 'cara', 'sth']
  },
  {
    name: 'JML', value: 'jml', url: 'https://youtu.be/-TuwrdkDjHg?t=1356',
    counter: 'trench', banners: 62, squad: ['jml', 'jkl', 'shaak-ti', 'barriss', 'kit'], defense: ['trench', 'nute', 'wat', 'jango', 'dooku']
  },
  {
    name: 'Rey', value: 'rey', url: 'https://youtu.be/-TuwrdkDjHg?t=890',
    counter: 'malgus', banners: 55, squad: ['rey', 'omi-ben-solo', 'r2', 'holdo', 'scavenger'], defense: ['malgus', 'dr', 'fallen-basti', 'malak', 'talon']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/-TuwrdkDjHg?t=127',
    counter: 'reva', banners: 63, squad: ['omi-traya', 'sion', 'nihilus', 'talon', 'omi-savage'], defense: ['omi-reva', 'gi', 'fifth-brother', 'eighth-brother', 'seventh-sister']
  },
  {
    name: 'Finn', value: 'hunter', url: 'https://youtu.be/sbNcTnPBzXQ?t=830',
    counter: 'dash', banners: 65, squad: ['finn', 'omi-zorii', 'hero-finn', 'poe', 'resistance-trooper'], defense: ['omi-dash', 'ig-11', 'vandor-chewie', 'kuiil', 'omi-hondo']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/sbNcTnPBzXQ?t=568',
    counter: 'traya', banners: 65, squad: ['see', 'wat'], defense: ['omi-traya', 'sion', 'nihilus', 'talon', 'omi-savage']
  },
  {
    name: 'Raddus', value: 'admiralraddus', url: 'https://youtu.be/sbNcTnPBzXQ?t=299',
    counter: 'gi', banners: 62, squad: ['omi-raddus', 'jyn', 'cassian', 'bistan', 'pathfinder'], defense: ['gi', 'fifth-brother', 'eighth-brother', 'ninth-sister', 'seventh-sister']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/sbNcTnPBzXQ?t=32',
    counter: 'malgus', banners: 56, squad: ['gas', 'rex', 'echo', 'fives', 'arc'], defense: ['malgus', 'malak', 'fallen-basti', 'dr', 'marauder']
  },
  {
    name: 'Finn', value: 'finn', url: 'https://youtu.be/vXSTlZLPQF0?t=832',
    counter: 'admiralraddus', banners: 65, squad: ['finn', 'poe', 'resistance-trooper', 'hero-finn', 'omi-zorii'], defense: ['omi-raddus', 'jyn', 'cassian', 'pathfinder', 'bistan']
  },
  {
    name: 'Talzin', value: 'talzin', url: 'https://youtu.be/vXSTlZLPQF0?t=731',
    counter: 'gg', banners: 60, squad: ['talzin', 'spirit', 'zombie', 'daka', 'asajj'], defense: ['gg', 'b1', 'b2', 'magna', 'bb']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/vXSTlZLPQF0?t=536',
    counter: 'savage', banners: 65, squad: ['aurra', 'omi-zam', 'mando', 'bossk', 'greef'], defense: ['omi-savage', 'malak', 'maul', 'dooku', 'sith-trooper']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/vXSTlZLPQF0?t=439',
    counter: 'aphra', banners: 65, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['aphra', 'vader', 'ig88', 'trip', 'bt-1']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/vXSTlZLPQF0?t=44',
    counter: 'jabba', banners: 54, squad: ['slkr', 'hux', 'foo', 'fost', 'sith-trooper'], defense: ['jabba', 'krrsantan', 'skiff-lando', 'boussh-leia', 'embo']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/eR9-jqyFiAI?t=933',
    counter: 'mothma', banners: 68, squad: ['omi-wampa'], defense: ['mothma', 'pao', 'hoth-scout', 'cara', 'bistan']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/eR9-jqyFiAI?t=848',
    counter: 'malgus', banners: 63, squad: ['slkr', 'zombie', 'daka'], defense: ['omi-malgus', 'dr', 'fallen-basti', 'marauder', 'malak']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/eR9-jqyFiAI?t=787',
    counter: 'palpatine', banners: 65, squad: ['see', 'wat'], defense: ['palpatine', 'mara', 'omi-starkiller', 'visas', 'juhani']
  },
  {
    name: 'Jedi Master Luke', value: 'jmk', url: 'https://youtu.be/eR9-jqyFiAI?t=637',
    counter: 'lv', banners: 64, squad: ['jml', 'jkr', 'jkl', 'hermit', 'shaak-ti'], defense: ['lv', 'rg', 'vader', 'mando-maul', 'stormtrooper']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/eR9-jqyFiAI?t=553',
    counter: 'mando-maul', banners: 61, squad: ['omi-qgj', 'gk', 'jka', 'kam', 'barriss'], defense: ['mando-maul', 'bokatan', 'armorer', 'canderous', 'bam']
  },
  {
    name: 'QGJ', value: 'qgj', url: 'https://youtu.be/eR9-jqyFiAI?t=478',
    counter: 'cls', banners: 62, squad: ['omi-qgj', 'gk', 'jka', 'kam', 'barriss'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'LV', value: 'lv', url: 'https://youtu.be/eR9-jqyFiAI?t=173',
    counter: 'jabba', banners: 62, squad: ['lv', 'mando-maul', 'rg', 'thrawn', 'vader'], defense: ['jabba', 'krrsantan', 'skiff-lando', 'boussh-leia', 'boba']
  },
  {
    name: 'JMK', value: 'jmk', url: 'https://youtu.be/eR9-jqyFiAI?t=48',
    counter: 'jabba', banners: 56, squad: ['jmk', 'padme', 'jka', 'gk', 'cat'], defense: ['jabba', 'krrsantan', 'skiff-lando', 'boussh-leia', 'embo']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/CEexN2wKG1k?t=3306',
    counter: 'malgus', banners: 63, squad: ['see', 'set', 'nihilus', 'sion', 'sith-trooper'], defense: ['malgus', 'set', 'fallen-basti', 'marauder', 'talon']
  },
  {
    name: 'Grievous', value: 'gg', url: 'https://youtu.be/CEexN2wKG1k?t=2682',
    counter: 'dr', banners: 61, squad: ['gg', 'b2', 'b1', 'magna', 'nute'], defense: ['dr', 'omi-starkiller', 'scavenger', 'juhani', 'kylo']
  },
  {
    name: 'Bossk', value: 'bossk', url: 'https://youtu.be/h0Wk0IkZ5LA?t=5829',
    counter: 'admiralraddus', banners: 63, squad: ['bossk', 'zam', 'greef', 'mando', 'boba'], defense: ['omi-raddus', 'jyn', 'cassian', 'k2', 'baze']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/h0Wk0IkZ5LA?t=5370',
    counter: 'chirpa', banners: 65, squad: ['omi-iden', 'starck', 'shoretrooper', 'range', 'death-trooper'], defense: ['chirpa', 'ewok-elder', 'wicket', 'paploo', 'logray']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/1ow7yc4NeJw',
    counter: 'boba', banners: 68, squad: ['omi-wampa'], defense: ['boba', 'greedo', 'embo', 'cad', 'krrsantan']
  },
  {
    name: 'Grievous', value: 'gg', url: 'https://youtu.be/1ow7yc4NeJw?t=3567',
    counter: 'jml', banners: 58, squad: ['gg', 'b2', 'b1', 'magna', 'nute'], defense: ['jml', 'kam', 'jkl', 'jkr', 'hermit']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/1ow7yc4NeJw?t=2047',
    counter: 'admiralraddus', banners: 62, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['omi-raddus', 'jyn', 'cassian', 'bistan', 'pathfinder']
  },
  {
    name: 'Hunter', value: 'hunter', url: 'https://youtu.be/1ow7yc4NeJw?t=1798',
    counter: 'phasma', banners: 65, squad: ['hunter', 'omega', 'bb-echo', 'tech', 'wrecker'], defense: ['phasma', 'kylo', 'omi-fotp', 'fox', 'fosftp']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/1ow7yc4NeJw?t=1513',
    counter: 'gi', banners: 56, squad: ['traya', 'sion', 'nihilus', 'sith-assassin', 'shoretrooper'], defense: ['gi', 'fifth-brother', 'eighth-brother', 'second-sister', 'seventh-sister']
  },
  {
    name: 'Grievous', value: 'gg', url: 'https://youtu.be/L9H-VZkymvE?t=4235',
    counter: 'palpatine', banners: 56, squad: ['gg', 'b1', 'b2', 'nute', 'magna'], defense: ['palpatine', 'mara', 'visas', 'omi-starkiller', 'juhani']
  },
  {
    name: 'Geos', value: 'gba', url: 'https://youtu.be/ZpbHtE-UeEg?t=1219',
    counter: 'hunter', banners: 65, squad: ['gba', 'geo-spy', 'geo-soldier', 'sun-fac', 'poggle'], defense: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega']
  },
  {
    name: 'Sith Eternal Emperor', value: 'hunter', url: 'https://youtu.be/ZpbHtE-UeEg?t=1099',
    counter: 'qgj', banners: 67, squad: ['see', 'wat'], defense: ['omi-qgj', 'gk', 'jka', 'kam', 'barriss']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/ZpbHtE-UeEg?t=910',
    counter: 'hux', banners: 56, squad: ['traya', 'sion', 'nihilus', 'talon', 'set'], defense: ['hux', 'kylo', 'kru', 'sith-trooper', 'omi-fotp']
  },
  {
    name: 'General Skywalker', value: 'gas', url: 'https://youtu.be/ZpbHtE-UeEg?t=772',
    counter: 'hux', banners: 61, squad: ['gas', 'rex', 'arc', 'echo', 'fives'], defense: ['hux', 'kylo', 'fost', 'foo', 'omi-fotp']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/ZpbHtE-UeEg?t=593',
    counter: 'palpatine', banners: 64, squad: ['veers', 'gideon', 'dark-trooper', 'piett', 'wat'], defense: ['palpatine', 'mara', 'visas', 'omi-starkiller', 'gk']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/ZpbHtE-UeEg?t=433',
    counter: 'palpatine', banners: 65, squad: ['veers', 'gideon', 'dark-trooper', 'piett', 'range'], defense: ['palpatine', 'mara', 'visas', 'omi-starkiller', 'juhani']
  },
  {
    name: 'Jedi Bastila', value: 'jedi-basti', url: 'https://youtu.be/JvQTX4sNvqg?t=1164',
    counter: 'jango', banners: 65, squad: ['jedi-basti', 'jkl', 'ezra', 'ben', 'jolee'], defense: ['jango', 'cad', 'boba', 'embo', 'dengar']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', url: 'https://youtu.be/JvQTX4sNvqg?t=871',
    counter: 'jmk', banners: 53, squad: ['see', 'wat', 'armorer', 'gba', 'thrawn'], defense: ['jmk', 'cat', 'ahsoka', 'padme', 'mace']
  },
  {
    name: 'General Grievous', value: 'gg', url: 'https://youtu.be/JvQTX4sNvqg?t=344',
    counter: 'mando-maul', banners: 61, squad: ['gg', 'b1', 'b2', 'magma', 'nute'], defense: ['mando-maul', 'bokatan', 'nest', 'canderous', 'bam']
  },
  {
    name: 'Mon Mothma', value: 'mothma', url: 'https://youtu.be/BkmFTUAGKVQ?t=290',
    counter: 'geos', banners: 64, squad: ['mothma', 'cara', 'kyle', 'pao', 'hoth-scout'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/BkmFTUAGKVQ?t=159',
    counter: 'dash', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'gideon'], defense: ['dash', 'l3', 'vandor-chewie', 'vet-han', 'vet-chewie']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/BkmFTUAGKVQ?t=52',
    counter: 'dash', banners: 65, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['omi-dash', 'l3', 'vandor-chewie', 'young-han', 'qira']
  },
  {
    name: 'Jango', value: 'jango', url: 'https://youtu.be/MnUFPb9v8dE?t=1343',
    counter: 'aurra', banners: 59, squad: ['jango', 'boba', 'old-boba', 'dengar', 'bokatan'], defense: ['aurra', 'embo', 'ig88', 'boba', 'nest']
  },
  {
    name: 'Finn', value: 'finn', url: 'https://youtu.be/MnUFPb9v8dE?t=1200',
    counter: 'phasma', banners: 63, squad: ['finn', 'poe', 'resistance-trooper', 'holdo', 'scavenger'], defense: ['phasma', 'kylo', 'fost', 'fox', 'fosftp']
  },
  {
    name: 'Mon Mothma', value: 'mothma', url: 'https://youtu.be/MnUFPb9v8dE?t=1070',
    counter: 'phasma', banners: 65, squad: ['mothma', 'pao', 'cassian', 'cara', 'hoth-scout'], defense: ['phasma', 'kylo', 'fost', 'fox', 'fosftp']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/MnUFPb9v8dE?t=910',
    counter: 'dooku', banners: 64, squad: ['bastila', 'gmy', 'hermit', 'ben', 'jolee'], defense: ['dooku', 'geo-spy', 'gba', 'droideka', 'jango']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/MnUFPb9v8dE?t=768',
    counter: 'darth-maul', banners: 63, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['maul', 'dr', 'marauder', 'fallen-basti', 'talon']
  },
  {
    name: 'Mirror', value: 'jkr', url: 'https://youtu.be/MnUFPb9v8dE?t=269',
    counter: 'jml', banners: 65, squad: ['jkr', 'jml', 'jkl', 'shaak-ti', 'wat'], defense: ['jml', 'jolee', 'jkl', 'jkr', 'hermit']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/dTYoWa2fxUg?t=605',
    counter: 'mothma', banners: 65, squad: ['omi-iden', 'starck', 'shoretrooper', 'snowtrooper', 'death-trooper'], defense: ['mothma', 'pao', 'hoth-scout', 'cara', 'kyle']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/Ey72N97cn4M?t=922',
    counter: 'bossk', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'gideon'], defense: ['bossk', 'omi-zam', 'boba', 'dengar', 'mando']
  },
  {
    name: 'General Skywalker', value: 'gas', url: 'https://youtu.be/Ey72N97cn4M?t=794',
    counter: 'jango', banners: 60, squad: ['gas', 'rex', 'arc', 'echo', 'fives'], defense: ['jango', 'bam', 'ig-11', 'kuiil', 'vet-han']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/Ey72N97cn4M?t=490',
    counter: 'gas', banners: 59, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['gas', 'rex', 'arc', 'echo', 'fives']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/Ey72N97cn4M?t=304',
    counter: 'bam', banners: 65, squad: ['jkr', 'jedi-basti', 'gmy', 'ben', 'ezra'], defense: ['bam', 'ig-11', 'kuiil', 'fennec', 'old-boba']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/Ey72N97cn4M?t=37',
    counter: 'slkr', banners: 62, squad: ['jkr', 'jkl', 'ben', 'bastila', 'hermit'], defense: ['slkr', 'fost', 'hux', 'kru', 'sith-trooper']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/ymyTeBR9aCQ?t=964',
    counter: 'hunter', banners: 65, squad: ['veers', 'piett', 'dark-trooper', 'range', 'gideon'], defense: ['hunter', 'bb-echo', 'tech', 'wrecker', 'barriss']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/ymyTeBR9aCQ?t=841',
    counter: 'rjt', banners: 64, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['rjt', 'hero-finn', 'hero-poe', 'bb', 'r2']
  },
  {
    name: 'Qui-Gon', value: 'iden', url: 'https://youtu.be/ymyTeBR9aCQ?t=730',
    counter: 'hux', banners: 63, squad: ['omi-qgj', 'kam', 'jka'], defense: ['hux', 'stormtrooper', 'kylo', 'fox', 'sith-trooper']
  },
  {
    name: 'Iden', value: 'iden', url: 'https://youtu.be/ymyTeBR9aCQ?t=500',
    counter: 'hux', banners: 56, squad: ['omi-iden', 'shoretrooper', 'snowtrooper', 'death-trooper', 'magma'], defense: ['hux', 'kru', 'kylo', 'fox', 'sith-trooper']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/ymyTeBR9aCQ?t=174',
    counter: 'iden', banners: 68, squad: ['omi-wampa'], defense: ['omi-iden', 'shoretrooper', 'snowtrooper', 'death-trooper', 'stormtrooper']
  },
  {
    name: 'Wampa', value: 'wampa', url: 'https://youtu.be/ymyTeBR9aCQ?t=22',
    counter: 'gg', banners: 67, squad: ['omi-wampa'], defense: ['gg', 'nute', 'b1', 'b2', 'magna']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', url: 'https://youtu.be/B_iT8zSjWWA?t=915',
    counter: 'rjt', banners: 62, squad: ['kru', 'kylo', 'hux', 'sith-trooper', 'foo'], defense: ['rjt', 'vet-han', 'vet-chewie', 'finn', 'nest']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/B_iT8zSjWWA?t=739',
    counter: 'mando-maul', banners: 63, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['mando-maul', 'bokatan', 'armorer', 'canderous', 'jango']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/B_iT8zSjWWA?t=609',
    counter: 'hunter', banners: 65, squad: ['palpatine', 'mara', 'vader', 'thrawn', 'gideon'], defense: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/B_iT8zSjWWA?t=322',
    counter: 'rey', banners: 64, squad: ['palpatine', 'mara', 'vader', 'thrawn', 'wat'], defense: ['rey', 'hero-finn', 'hero-poe', 'finn', 'holdo']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/B_iT8zSjWWA?t=170',
    counter: 'iden', banners: 60, squad: ['traya', 'sion', 'nihilus', 'barriss'], defense: ['omi-iden', 'magma', 'snowtrooper', 'death-trooper', 'stormtrooper']
  },
  {
    name: 'Qui-Gon', value: 'qgj', url: 'https://youtu.be/B_iT8zSjWWA?t=31',
    counter: 'mothma', banners: 62, squad: ['omi-qgj', 'kam', 'jka', 'mace', 'aayla'], defense: ['mothma', 'pao', 'hoth-scout', 'cara', 'kyle']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/r3DplfBscwc?t=740',
    counter: 'nute', banners: 65, squad: ['veers', 'piett', 'dark-trooper', 'range', 'starck'], defense: ['nute', 'droideka', 'b1', 'b2', 'magna']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/r3DplfBscwc?t=450',
    counter: 'hux', banners: 65, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['hux', 'kylo', 'fost', 'sith-trooper', 'fox']
  },
  {
    name: 'General Grievous', value: 'gg', url: 'https://youtu.be/r3DplfBscwc?t=269',
    counter: 'geos', banners: 63, squad: ['gg', 'nute', 'b1', 'b2', 'magna'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/r3DplfBscwc?t=28',
    counter: 'traya', banners: 62, squad: ['palpatine', 'vader', 'mara', 'death-trooper', 'set'], defense: ['traya', 'sion', 'nihilus', 'set', 'marauder']
  },
  {
    name: 'GAS', value: 'gas', url: 'https://youtu.be/fQL-4W1eMKk?t=5028',
    counter: 'iden', banners: 60, squad: ['gas', 'echo', 'rex', 'fives', 'arc'], defense: ['omi-iden', 'magma', 'snowtrooper', 'death-trooper', 'stormtrooper']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/fQL-4W1eMKk?t=4374',
    counter: 'palpatine', banners: 65, squad: ['veers', 'piett', 'dark-trooper', 'range', 'starck'], defense: ['palpatine', 'mara', 'vader', 'shoretrooper', 'gideon']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/fQL-4W1eMKk?t=3406',
    counter: 'qgj', banners: 65, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['omi-qgj', 'gk', 'jka', 'aayla', 'plo']
  },
  {
    name: 'General Grievous', value: 'gg', url: 'https://youtu.be/fQL-4W1eMKk?t=3004',
    counter: 'mothma', banners: 63, squad: ['gg', 'b1', 'b2', 'magna', 'nute'], defense: ['mothma', 'pao', 'hoth-scout', 'cara', 'kyle']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/fQL-4W1eMKk?t=2692',
    counter: 'gg', banners: 62, squad: ['bastila', 'kam', 'gmy', 'shaak-ti', 'ben'], defense: ['gg', 'b1', 'b2', 'magna', 'bb']
  },
  {
    name: 'Jedi Master Luke', value: 'jml', url: 'https://youtu.be/fQL-4W1eMKk?t=1426',
    counter: 'jmk', banners: 63, squad: ['jml', 'jkl', 'yoda', 'jolee', 'jkr'], defense: ['jmk', 'cat', 'ahsoka', 'padme', 'mace']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/CySofLTJJJg?t=4233', image: 'bastila-nute',
    counter: 'nute', banners: 65, squad: ['jedi-basti', 'jolee', 'ezra', 'yoda', 'ben'], defense: ['nute', 'wampa', 'dooku', 'droideka', 'jango']
  },
  {
    name: 'RJT', value: 'rjt', url: 'https://youtu.be/CySofLTJJJg?t=1543',
    counter: 'aurra', banners: 65, squad: ['rjt', 'hero-finn', 'hero-poe', 'finn', 'vet-han'], defense: ['aurra', 'embo', 'cad', 'dengar', 'old-boba']
  },
  {
    name: 'Jedi Bastila', value: 'bastila', url: 'https://youtu.be/qqCR022xcME?t=3325',
    counter: 'dash', banners: 65, squad: ['jedi-basti', 'kam', 'gmy', 'jkr', 'jolee'], defense: ['omi-dash', 'l3', 'vandor-chewie', 'young-han', 'qira']
  },
  {
    name: 'Mirror', value: 'jmk', url: 'https://youtu.be/qqCR022xcME?t=2023',
    counter: 'jmk', banners: 60, squad: ['jmk', 'padme', 'cat', 'ahsoka', 'mace'], defense: ['jmk', 'padme', 'cat', 'ahsoka', 'gk']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', url: 'https://youtu.be/qqCR022xcME?t=1768',
    counter: 'jml', banners: 51, squad: ['see', 'set', 'traya', 'nihilus', 'sion'], defense: ['jml', 'hermit', 'gmy', 'barriss', 'shaak-ti']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/qqCR022xcME?t=1300',
    counter: 'old-boba', banners: 62, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['old-boba', 'fennec', 'ig-11', 'kuiil', 'bam']
  },
  {
    name: 'Beskar Mando', value: 'bam', url: 'https://youtu.be/qqCR022xcME?t=1067',
    counter: 'kru', banners: 61, squad: ['bam', 'old-boba', 'dengar', 'boba', 'gideon'], defense: ['kru', 'kylo', 'fosftp', 'foo', 'fox']
  },
  {
    name: 'Padme', value: 'padme', url: 'https://youtu.be/xtpmMLmbYJA?t=669',
    counter: 'darth-maul', banners: 60, squad: ['padme', 'barriss', 'clone-sergeant', 'ahsoka', 'cat'], defense: ['maul', 'marauder', 'dooku', 'sid', 'sith-assassin']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/xtpmMLmbYJA?t=195',
    counter: 'kru', banners: 65, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['kru', 'kylo', 'hux', 'fost', 'sith-trooper']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/xtpmMLmbYJA?t=334',
    counter: 'rjt', banners: 64, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'omega'], defense: ['rjt', 'hero-finn', 'hero-poe', 'r2', 'scavenger']
  },
  {
    name: 'Imperial Troopers', value: 'veers', url: 'https://youtu.be/xtpmMLmbYJA?t=27',
    counter: 'dr', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'dark-trooper'], defense: ['dr', 'talon', 'malak', 'fallen-basti', 'set']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', url: 'https://youtu.be/6WUTD-6zUCg?t=526',
    counter: 'iden', banners: 63, squad: ['kru', 'kylo', 'hux', 'foo', 'fox'], defense: ['iden', 'shoretrooper', 'snowtrooper', 'death-trooper', 'stormtrooper']
  },
  {
    name: 'Maul', value: 'mando-maul', url: 'https://youtu.be/6WUTD-6zUCg?t=345',
    counter: 'old-boba', banners: 63, squad: ['mando-maul', 'wat', 'canderous', 'armorer', 'bokatan'], defense: ['old-boba', 'omi-zam', 'jango', 'fennec', 'boba']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/6WUTD-6zUCg?t=230',
    counter: 'palpatine', banners: 65, squad: ['aurra', 'omi-zam', 'bossk', 'greef', 'mando'], defense: ['palpatine', 'mara', 'visas', 'omi-starkiller', 'kanan']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/6WUTD-6zUCg?t=19',
    counter: 'dr', banners: 65, squad: ['aurra', 'omi-zam', 'bossk', 'greef', 'mando'], defense: ['dr', 'talon', 'malak', 'fallen-basti', 'set']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/2LtVAyRC7I8?t=1052',
    counter: 'traya', banners: 62, squad: ['veers', 'dark-trooper', 'piett', 'starck', 'range'], defense: ['traya', 'sion', 'nihilus', 'talon', 'sid']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/2LtVAyRC7I8?t=858',
    counter: 'dash', banners: 60, squad: ['traya', 'sion', 'nihilus', 'barriss', 'set'], defense: ['omi-dash', 'vandor-chewie', 'nest', 'l3', 'qira']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/2LtVAyRC7I8?t=688',
    counter: 'jango', banners: 64, squad: ['jkr', 'gmy', 'ezra', 'jolee', 'jedi-basti'], defense: ['jango', 'omi-zam', 'cad', 'ig88', 'dengar']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/2LtVAyRC7I8?t=486',
    counter: 'nute', banners: 64, squad: ['palpatine', 'thrawn', 'rg', 'gideon', 'vader'], defense: ['nute', 'dooku', 'nest', 'droideka', 'jango']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/2LtVAyRC7I8?t=359',
    counter: 'nute', banners: 65, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'cody'], defense: ['nute', 'dooku', 'b1', 'b2', 'magna']
  },
  {
    name: 'Bad Batch', value: 'hunter', url: 'https://youtu.be/2LtVAyRC7I8?t=149',
    counter: 'gg', banners: 63, squad: ['hunter', 'bb-echo', 'tech', 'wrecker', 'cody'], defense: ['gg', 'ig88', 'b1', 'b2', 'magna']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', url: 'https://youtu.be/-TuwrdkDjHg?t=1106',
    counter: 'gas', banners: 64, squad: ['see', 'set'], defense: ['gas', 'rex', 'echo', 'fives', 'arc']
  },
  {
    name: 'Cheese Mirror', value: 'slkr', url: 'https://youtu.be/8nEtmtcd4cw?t=1135',
    counter: 'slkr', banners: 57, squad: ['slkr', 'fost', 'wat', 'zombie', 'daka'], defense: ['slkr', 'fost', 'hux', 'foo', 'sith-trooper']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/8nEtmtcd4cw?t=711',
    counter: 'lv', banners: 56, squad: ['slkr', 'fost', 'wat', 'zombie', 'daka'], defense: ['lv', 'rg', 'mando-maul', 'thrawn', 'vader']
  },
  {
    name: 'Maul', value: 'mando-maul', url: 'https://youtu.be/8nEtmtcd4cw?t=510',
    counter: 'mothma', banners: 65, squad: ['mando-maul', 'wat', 'canderous', 'armorer', 'bokatan'], defense: ['mothma', 'kyle', 'cara', 'pao', 'hoth-scout']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/8nEtmtcd4cw?t=251',
    counter: 'bam', banners: 65, squad: ['veers', 'dark-trooper', 'piett', 'death-trooper', 'range'], defense: ['bam', 'ig-11', 'kuiil', 'vet-han', 'dengar']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/8nEtmtcd4cw?t=24',
    counter: 'jango', banners: 65, squad: ['aurra', 'bossk', 'greef', 'mando', 'omi-zam'], defense: ['jango', 'bam', 'kuiil', 'ig-11', 'nest']
  },
  {
    name: 'Qui-Gon', value: 'qgj', url: 'https://youtu.be/1wNvZJG7FKc?t=436',
    counter: 'darth-maul', banners: 60, squad: ['omi-qgj', 'kam', 'jka', 'gk', 'aayla'], defense: ['maul', 'dr', 'malak', 'fallen-basti', 'set']
  },
  {
    name: 'Qui-Gon', value: 'qgj', url: 'https://youtu.be/1wNvZJG7FKc?t=163',
    counter: 'dr', banners: 52, squad: ['omi-qgj', 'kam', 'jka', 'aayla', 'gk'], defense: ['dr', 'talon', 'malak', 'fallen-basti', 'marauder']
  },
  {
    name: 'Jedi Master Kenobi', value: 'jmk', url: 'https://youtu.be/P4sEcCKd3As?t=794',
    counter: 'lv', banners: 61, squad: ['jmk', 'cat', 'padme', 'ahsoka', 'gk'], defense: ['lv', 'rg', 'mando-maul', 'shoretrooper', 'vader']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/P4sEcCKd3As?t=638',
    counter: 'kru', banners: 68, squad: ['see'], defense: ['kru', 'kylo', 'hux', 'fost', 'sith-trooper']
  },
  {
    name: 'Rey (Jedi Training)', value: 'rjt', url: 'https://youtu.be/P4sEcCKd3As?t=522',
    counter: 'darth-maul', banners: 63, squad: ['rjt', 'r2', 'bb', 'hero-finn', 'hero-poe'], defense: ['maul', 'savage', 'dooku', 'sid', 'sith-assassin']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/P4sEcCKd3As?t=378',
    counter: 'mando-maul', banners: 65, squad: ['veers', 'gideon', 'piett', 'range', 'dark-trooper'], defense: ['mando-maul', 'armorer', 'canderous', 'bokatan', 'jango']
  },
  {
    name: 'Palpatine + Mara', value: 'palpatine', url: 'https://youtu.be/P4sEcCKd3As?t=30',
    counter: 'mothma', banners: 65, squad: ['palpatine', 'mara', 'vader', 'thrawn', 'gideon'], defense: ['mothma', 'kyle', 'cara', 'pao', 'hoth-scout']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/rAHZxDyu74U?t=261',
    counter: 'dash', banners: 62, squad: ['aurra', 'bossk', 'omi-zam', 'mando', 'greef'], defense: ['omi-dash', 'vandor-chewie', 'young-han', 'l3', 'qira']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/rAHZxDyu74U?t=62',
    counter: 'qgj', banners: 65, squad: ['aurra', 'bossk', 'omi-zam', 'mando', 'greef'], defense: ['omi-qgj', 'gk', 'jka', 'aayla', 'barriss']
  },
  {
    name: 'Jedi Master Kenobi', value: 'jmk', url: 'https://youtu.be/0Nn-PO_fcDw?t=638',
    counter: 'slkr', banners: 64, squad: ['jmk', 'cat', 'padme', 'mace', 'ahsoka'], defense: ['slkr', 'fost', 'hux', 'phasma', 'sith-trooper']
  },
  {
    name: 'Jedi Knight Revan', value: 'jkr', url: 'https://youtu.be/0Nn-PO_fcDw?t=375',
    counter: 'mando-maul', banners: 65, squad: ['jkr', 'yoda', 'hermit', 'jolee', 'jedi-basti'], defense: ['mando-maul', 'bam', 'canderous', 'bokatan', 'sabine']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/0Nn-PO_fcDw?t=234',
    counter: 'nute', banners: 65, squad: ['mothma', 'cara', 'pao', 'hoth-scout', 'bistan'], defense: ['nute', 'droideka', 'b1', 'b2', 'magna']
  },
  {
    name: 'Mothma', value: 'mothma', url: 'https://youtu.be/0Nn-PO_fcDw?t=30',
    counter: 'gg', banners: 63, squad: ['mothma', 'cara', 'pao', 'hoth-scout', 'bistan'], defense: ['gg', 'nute', 'b1', 'b2', 'magna']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/ucJcNCo-2dY?t=489',
    counter: 'qgj', banners: 60, squad: ['palpatine', 'rg', 'vader', 'thrawn', 'gideon'], defense: ['omi-qgj', 'mace', 'jka', 'aayla', 'barriss']
  },
  {
    name: 'Aurra', value: 'aurra', url: 'https://youtu.be/ucJcNCo-2dY?t=298',
    counter: 'mando-maul', banners: 65, squad: ['aurra', 'omi-zam', 'bossk', 'greef', 'mando'], defense: ['mando-maul', 'armorer', 'canderous', 'bokatan', 'gar']
  },
  {
    name: 'Qui-Gon', value: 'qgj', url: 'https://youtu.be/ucJcNCo-2dY?t=166',
    counter: 'jango', banners: 58, squad: ['omi-qgj', 'kam', 'jka', 'gk', 'aayla'], defense: ['jango', 'omi-zam', 'cad', 'fennec', 'dengar']
  },
  {
    name: 'Imperial Troopers', value: 'veers', url: 'https://youtu.be/ZAzR6BDAbgM?t=437',
    counter: 'lv', banners: 64, squad: ['veers', 'dark-trooper', 'piett', 'range', 'shaak-ti'], defense: ['lv', 'rg', 'kru', 'shoretrooper', 'stormtrooper']
  },
  {
    name: 'Rey Undersize', value: 'rey', url: 'https://youtu.be/CWmyLyRBOjg?t=857',
    counter: 'mando-maul', banners: 62, squad: ['rey', 'holdo', 'finn'], defense: ['mando-maul', 'b2', 'canderous', 'bokatan', 'jango']
  },
  {
    name: 'Palpatine', value: 'traya', url: 'https://youtu.be/C9LVjhAsFgo?t=148',
    counter: 'bam', banners: 63, squad: ['palpatine', 'mara', 'vader', 'thrawn', 'gideon'], defense: ['bam', 'ig-11', 'kuiil', 'l3', 'dengar']
  },
  {
    name: 'Maul', value: 'mando-maul', url: 'https://youtu.be/Kf6886W7Nho?t=466',
    counter: 'nute', banners: 65, squad: ['mando-maul', 'bam', 'canderous', 'armorer', 'bokatan'], defense: ['nute', 'magna', 'droideka', 'b1', 'dooku']
  },
  {
    name: 'Maul', value: 'mando-maul', url: 'https://youtu.be/Kf6886W7Nho?t=152',
    counter: 'shaak', banners: 63, squad: ['mando-maul', 'bam', 'wat', 'armorer', 'bokatan'], defense: ['shaak-ti', 'rex', 'echo', 'fives', 'arc']
  },
  {
    name: 'CLS', value: 'cls', url: 'https://youtu.be/zqkmN4utEtI?t=2029',
    counter: 'hux', banners: 65, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['hux', 'sith-trooper', 'sftp', 'foo', 'fost']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/xrqpxvKdh74?t=330',
    counter: 'bam', banners: 64, squad: ['jedi-basti', 'kam', 'ben', 'shaak-ti', 'hermit'], defense: ['bam', 'ig-11', 'kuiil', 'nest', 'dengar']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/Nrtn8k2vzx8?t=1960',
    counter: 'jango', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'death-trooper'], defense: ['jango', 'boba', 'dengar', 'bossk', 'cad']
  },
  {
    name: 'SLKR', value: 'slkr', url: 'https://youtu.be/Nrtn8k2vzx8?t=853',
    counter: 'see', banners: 68, squad: ['slkr'], defense: ['see', 'dooku', 'sid', 'maul', 'wat']
  },
  {
    name: 'SEE + Wat', value: 'see', url: 'https://youtu.be/Nrtn8k2vzx8?t=664',
    counter: 'jkl', banners: 65, squad: ['see', 'wat'], defense: ['jkl', 'jml', 'hermit', 'ben', 'qgj']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/tqdSpj44hF8?t=1814',
    counter: 'talzin', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'death-trooper'], defense: ['talzin', 'daka', 'asajj', 'zombie', 'spirit']
  },
  {
    name: 'Jedi Luke', value: 'jkl', url: 'https://youtu.be/tqdSpj44hF8?t=818',
    counter: 'dr', banners: 65, squad: ['jkl', 'jml', 'shaak-ti', 'ben', 'hermit'], defense: ['dr', 'fallen-basti', 'marauder', 'set', 'malak']
  },
  {
    name: 'Bastila', value: 'bastila', url: 'https://youtu.be/gsHyD7jzG8g?t=1183',
    counter: 'hux', banners: 65, squad: ['jedi-basti', 'kam', 'shaak-ti', 'ben', 'hermit'], defense: ['hux', 'sith-trooper', 'fotp', 'foo', 'fost']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/gsHyD7jzG8g?t=474',
    counter: 'kru', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'death-trooper'], defense: ['kru', 'kylo', 'fotp', 'fox', 'phasma']
  },
  {
    name: 'Traya', value: 'traya', url: 'https://youtu.be/4SnD9whmTcA?t=1118',
    counter: 'mothma', banners: 63, squad: ['traya', 'sion', 'nihilus', 'set'], defense: ['mothma', 'pao', 'wedge', 'biggs', 'hoth-scout']
  },
  {
    name: 'Padme', value: 'padme', url: 'https://youtu.be/4SnD9whmTcA?t=1292',
    counter: 'nute', banners: 60, squad: ['padme', 'jka', 'ahsoka', 'gk', 'shaak-ti'], defense: ['nute', 'b1', 'b2', 'magna', 'jango']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/4SnD9whmTcA?t=645',
    counter: 'geos', banners: 65, squad: ['palp', 'vader', 'royal-guard', 'thrawn', 'gideon'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Palpatine', value: 'palpatine', url: 'https://youtu.be/EQEK7tnoXYk?t=1725',
    counter: 'kru', banners: 65, squad: ['palpatine', 'vader', 'thrawn', 'gideon', 'royal-guard'], defense: ['kru', 'kylo', 'fosftp', 'foo', 'fox']
  },
  {
    name: 'Veers', value: 'veers', url: 'https://youtu.be/m7Lmr2DSeRg?t=1026',
    counter: 'bossk', banners: 65, squad: ['veers', 'starck', 'piett', 'range', 'death-trooper'], defense: ['bossk', 'boba', 'dengar', 'mando', 'cad']
  },
  {
    name: 'SEE', value: 'see', url: 'https://youtu.be/m7Lmr2DSeRg?t=901',
    counter: 'mothma', banners: 68, squad: ['see'], defense: ['mothma', 'pao', 'hoth-scout', 'cara', 'bistan']
  },
  {
    name: 'Jedi Revan', value: 'jkr', url: 'https://youtu.be/m7Lmr2DSeRg?t=706',
    counter: 'hux', banners: 63, squad: ['jkr', 'gmy', 'gas', 'jolee', 'gk'], defense: ['hux', 'fosftp', 'sith-trooper', 'fost', 'kylo']
  },
  {
    name: 'Traya', value: 'traya', desc: 'Traya handles BAM with ease with help of Thrawn and Gideon', url: 'https://youtu.be/m7Lmr2DSeRg?t=244',
    counter: 'bam', title: 'Traya vs BAM', banners: 65, squad: ['traya', 'sion', 'nihilus', 'thrawn', 'gideon'], defense: ['bam', 'ig-11', 'kuiil', 'nest', 'ig88']
  },
  {
    name: 'Traya', value: 'traya', desc: 'Sith take on First Order', id: 'm9Vmgqd8xLw',
    counter: 'kru', title: 'Traya vs KRU', banners: 60, squad: ['traya', 'nihilus', 'sion', 'palpatine', 'set'], defense: ['kru', 'sith-trooper', 'hux', 'kylo', 'fost']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs GG, Magna, B1, B2 and Nute', id: 'ocvqZIwrzIk',
    counter: 'gg', title: 'Padme vs GG', banners: 58, squad: ['padme', 'jka', 'ahsoka', 'shaak', 'gk'], defense: ['gg', 'b1', 'b2', 'magna', 'nute']
  },
  {
    name: 'Jedi Master Luke', value: 'jml', desc: 'Jedi Lukes prove too much for the First Order', id: 'oDulnQcJ14M',
    counter: 'slkr', title: 'JML vs SLKR', banners: 62, squad: ['jml', 'jkl', 'hermit', 'shaak', 'ben'], defense: ['slkr', 'fost', 'hux', 'kru', 'sith-trooper']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'Rebels take out Clones', id: 'uI0N1j8U_1c',
    counter: 'shaak', title: 'CLS vs Shaak Clones', banners: 61, squad: ['cls', 'han', 'chewie', 'threepio', '3po'], defense: ['shaak', 'rex', 'fives', 'arc', 'echo']
  },
  {
    name: 'Jango', value: 'jango', desc: 'Max banners vs fast KRU', id: '8sllcLeMLgo',
    counter: 'kru', title: 'Jango vs KRU', banners: 65, squad: ['jango', 'greef', 'mando', 'boba', 'bossk'], defense: ['kru', 'fox', 'foo', 'kylo', 'sftp']
  },
  {
    name: 'Padme', value: 'padme', desc: 'Padme defeats RJT and Heroes', id: 'O38XRZwMjhQ',
    counter: 'rjt', title: 'GG vs RJT + Heroes', banners: 59, squad: ['padme', 'jka', 'ahsoka', 'echo', 'gk'], defense: ['rjt', 'bb', 'r2', 'hero-finn', 'hero-poe']
  },
  {
    name: 'General Grievous', value: 'gg', desc: 'GG taking out SEE and Nest', id: 'jM2m-EzGvk0',
    counter: 'see', title: 'GG vs SEE + Heroes', banners: 53, squad: ['gg', 'b1', 'b2', 'nute', 'magna'], defense: ['see', 'nest', 'maul', 'dooku', 'sion']
  },
  {
    name: 'Padme', value: 'padme', desc: 'Shaak-Ti as 5th', id: '6rzZRtIkwoc',
    counter: 'shaak', title: 'Padme vs Shaak Clones', banners: 61, squad: ['padme', 'jka', 'ahsoka', 'shaak-ti', 'gk'], defense: ['shaak', 'rex', 'fives', 'arc', 'echo']
  },
  {
    name: 'Veers', value: 'veers', desc: 'Max banners', id: 's00ussTFKtU',
    counter: 'rjt', title: 'Veers vs Rey (JT)', banners: 65, squad: ['veers', 'starck', 'piett', 'gideon', 'range'], defense: ['rjt', 'r2', 'bb', 'hero-poe', 'hero-finn']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', id: 'nhNb5TMZfj8',
    counter: 'cls', title: 'SEE vs CLS', banners: 65, squad: ['see', 'wat'], defense: ['cls', 'han', 'chewie', 'threepio', '3po']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', desc: 'Solo Jedi Revan with SEE', id: 'sWfMhVK7RDo',
    counter: 'jkr', title: 'SEE Solo vs JKR', banners: 68, squad: ['see'], defense: ['jkr', 'yoda', 'hermit', 'jedi-basti', 'jolee']
  },
  {
    name: 'Veers', value: 'veers', desc: 'Max banners', id: 'rZXA-218CHo',
    counter: 'dooku', title: 'Veers vs Dooku', banners: 65, squad: ['veers', 'starck', 'piett', 'gideon', 'range'], defense: ['dooku', 'geo-spy', 'gba', 'droideka', 'sun-fac']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'Defeat Padme with Palpatine without Sith Trio', id: 'qWARxq1bHYE',
    counter: 'padme', title: 'Palpatine vs Padme', banners: 64, squad: ['palpatine', 'vader', 'thrawn', 'wat', 'royal-guard'], defense: ['padme', 'jka', 'ahsoka', 'r2', 'gk']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', desc: 'Solo Padme with SEE', id: 'OqL4iEndBw0',
    counter: 'padme', title: 'SEE Solo vs Padme', banners: 68, squad: ['see'], defense: ['padme', 'ahsoka', 'jka', 'gk', 'barriss']
  },
  {
    name: 'Sith Eternal Emperor', value: 'see', id: '5TKCC_r9SS8',
    counter: 'rey', title: 'SEE vs Rey', banners: 64, squad: ['see', 'wat'], defense: ['rey', 'rjt', 'holdo', 'hero-finn', 'hero-poe']
  },
  {
    name: 'Veers', value: 'veers', desc: 'Max banners', id: '__MOkKIQSJA',
    counter: 'geos', title: 'Veers vs Geos', banners: 65, squad: ['veers', 'starck', 'piett', 'death-trooper', 'range'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Rex', value: 'rex', desc: 'Fives sacrifices for the team', id: 'kNhC2ejmrr0',
    counter: 'padme', title: 'Rex vs Padme', banners: 54, squad: ['rex', 'fives', 'echo', 'arc', 'barriss'], defense: ['padme', 'jka', 'ahsoka', 'r2', 'gk']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'Palpatine rolls over Padme without C-3PO', id: 'MQZW1ivanRY',
    counter: 'padme', title: 'Palpatine vs Padme', banners: 64, squad: ['palpatine', 'vader', 'thrawn', 'traya', 'sion'], defense: ['padme', 'jka', 'ahsoka', 'r2', 'gk']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'Max banners vs Padme', id: 'ijNcXqHEC5U',
    counter: 'padme', title: 'Jedi Revan vs Padme', banners: 65, squad: ['jkr', 'jedi-basti', 'yoda', 'jolee', 'hermit'], defense: ['padme', 'jka', 'ahsoka', 'yoda', 'gk']
  },
  {
    name: 'General Grievous', value: 'gg', desc: 'GG pulls out a close win vs RJT and Heroes', id: 'V5I9R6i5a98',
    counter: 'rjt', title: 'GG vs RJT + Heroes', banners: 53, squad: ['gg', 'b1', 'b2', 'nute', 'magna'], defense: ['rjt', 'bb', 'l3', 'hero-finn', 'hero-poe']
  },
  {
    name: 'Bossk', value: 'bossk', desc: 'Max banners vs KRU with Sith Trooper', id: '-4u_1Yrllu0',
    counter: 'kru', title: 'Bossk vs KRU', banners: 65, squad: ['bossk', 'greef', 'mando', 'boba', 'dengar'], defense: ['kru', 'fox', 'sith-trooper', 'kylo', 'sftp']
  },
  {
    name: 'Rey', value: 'rey', desc: 'Rey undersize for a good banner score', id: 'G2w31AQJcOY',
    counter: 'cls', title: 'Rey undersize vs CLS + Threepio & Chewie', banners: 62, squad: ['rey', 'barriss', 'holdo', 'l3'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'Max banners with JKR vs RJT and Heroes', id: 'aZxHaUlkfEc',
    counter: 'rjt', title: 'JKR vs RJT + Heroes', banners: 65, squad: ['jkr', 'jedi-basti', 'yoda', 'jolee', 'hermit'], defense: ['rjt', 'bb', 'r2', 'hero-finn', 'hero-poe']
  },
  {
    name: 'General Grievous', value: 'gg', desc: 'GG shatters the First Order', id: 'YmQPOAowvj0',
    counter: 'kru', title: 'GG vs KRU + Hux', banners: 61, squad: ['gg', 'b1', 'b2', 'magna', 'nute'], defense: ['kru', 'sith-trooper', 'hux', 'kylo', 'fost']
  },
  {
    name: 'General Grievous', value: 'gg', desc: 'GG barely making it vs Padme', id: 'qN-KgXXCUyQ',
    counter: 'padme', title: 'GG vs Padme', banners: 56, squad: ['gg', 'b1', 'b2', 'magna', 'nute'], defense: ['padme', 'jka', 'ahsoka', '3po', 'gk']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'Rebels take on Bastila, JML and Wat', id: 'fmc4Bu8_OD8',
    counter: 'bastila', title: 'CLS vs Bastila + JML', banners: 60, squad: ['cls', 'han', '3po', 'chewie', 'threepio'], defense: ['bastila', 'jml', 'jkl', 'gas', 'wat']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'DR undersizes RJT and Heroes', id: 'JbdWSY-HoTU',
    counter: 'rjt', title: 'DR vs RJT + Heroes', banners: 65, squad: ['dr', 'set', 'fallen-basti', 'malak'], defense: ['rjt', 'bb', 'r2', 'hero-finn', 'hero-poe']
  },
  {
    name: 'General Grievous', value: 'gg', desc: 'GG enlists Nute to extort the rebels', id: 'oGQHKaAXoFE',
    counter: 'cls', title: 'GG vs CLS', banners: 60, squad: ['gg', 'b1', 'b2', 'magna', 'nute'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'Sith Empire destroys the rebels', id: 'xqIZpgWt-Pc',
    counter: 'cls', title: 'DR vs CLS', banners: 60, squad: ['dr', 'hk', 'set', 'fallen-basti', 'malak'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'SL Kylo Ren', value: 'slkr', desc: 'Kylo takes down his uncle', id: 'jnjPq-_lThI',
    counter: 'jml', title: 'SLKR + Wat vs Jedi Master Luke', banners: 52, squad: ['slkr', 'dr', 'traya', 'wat', 'fallen-basti'], defense: ['jml', 'wat', 'jkl', 'jkr', 'gas']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'Sith Empire make quick work of the Galactic Republic', id: 'RoOFqdiT1pc',
    counter: 'padme', title: 'Darth Revan vs Padme', banners: 64, squad: ['dr', 'fallen-basti', 'malak', 'hk', 'sith-marauder'], defense: ['padme', 'jka', 'ahsoka', '3po', 'gk']
  },
  {
    name: 'Jango', value: 'jango', desc: 'The Mandalorian trio squashes bugs', id: 'Gj3TNRRCcWM',
    counter: 'geos', title: 'Jango vs Geos', banners: 63, squad: ['jango', 'bossk', 'boba', 'mando', 'greef'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Jedi Knight Luke', value: 'jkl', desc: 'Jedi Knight Luke takes out his younger self', id: 'ogbO9-Vz2TU',
    counter: 'cls', title: 'JKL vs CLS', banners: 62, squad: ['jkl', 'ben', 'jolee', 'yoda', 'barriss'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Jedi Knight Revan', value: 'jkr', desc: 'Old Republic dominates Galactic Republic', id: 'gGSyItWXBPU',
    counter: 'shaak', title: 'JKR vs Shaak Ti', banners: 63, squad: ['jkr', 'jedi-basti', 'jolee', 'yoda', 'hermit'], defense: ['shaak-ti', 'rex', 'fives', 'arc', 'echo']
  },
  {
    name: 'Palpatine', value: 'palpatine', desc: 'Palpatine, Vader and JKL showdown', id: 'zqWvWO2KIIU',
    counter: 'jkl', title: 'Palp vs JKL', banners: 61, squad: ['palpatine', 'vader', 'tarkin', 'thrawn', 'shoretrooper'], defense: ['jkl', 'jedi-basti', 'yoda', 'barriss', 'ben']
  },
  {
    name: 'Rey', value: 'rey', desc: 'Rey can also solo GG', id: '38FfHHf6mHw',
    counter: 'gg', title: 'Rey Solo vs Grievous', banners: 68, squad: ['rey'], defense: ['gg', 'b1', 'b2', 'magna', 'droideka']
  },
  {
    name: 'Talzin', value: 'talzin', desc: 'Nightsisters stun lock rebels', id: 'JFDA3JMIP_8',
    counter: 'cls', title: 'Talzin vs CLS + Threepio & Chewie', banners: 60, squad: ['talzin', 'spirit', 'zombie', 'daka', 'asajj'], defense: ['cls', 'han', '3po', 'chewie', 'threepio']
  },
  {
    name: 'Talzin', value: 'talzin', desc: 'Nightsisters take on Geos', id: 'Lv7HPfaCCDo',
    counter: 'geos', title: 'Talzin vs Geos', banners: 60, squad: ['talzin', 'spirit', 'zombie', 'daka', 'asajj'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Asajj', value: 'asajj', desc: 'vs Kylo Ren (Unmasked)', id: 't4TDr8rqr5M',
    counter: 'kru', title: 'Asajj vs Kru', banners: 59, squad: ['asajj', 'spirit', 'zombie', 'daka', 'talzin'], defense: ['kru', 'fox', 'phasma', 'kylo', 'foo']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs GG, BB-8, B1, B2 and Magna', id: 'jVXmnGxdK8c',
    counter: 'gg', title: 'Padme vs GG', banners: 60, squad: ['padme', 'ahsoka', 'gk', '3po', 'jka'], defense: ['gg', 'b1', 'b2', 'magna', 'bb']
  },
  {
    name: 'Finn', value: 'finn', desc: 'Finn zeta making a comeback', id: 'hK-ErGsdl1o',
    counter: 'geos', title: 'Finn vs Geos', banners: 65, squad: ['finn', 'poe', 'vet-han', 'vet-chewie', 'resistance-trooper'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Palpatine + Thrawn', value: 'palp-thrawn', desc: 'vs GG, Wat, B1, B2 and Magna', id: 'ugBqYKmHTcg',
    counter: 'gg', title: 'Palpatine vs GG', banners: 63, squad: ['palp', 'thrawn', 'traya', 'sion', 'nihilus'], defense: ['gg', 'b1', 'b2', 'magna', 'wat']
  },
  {
    name: 'Palpatine + Bastila', value: 'palp-basti', desc: 'vs GG, Wat, B1, B2 and Magna', id: '0fkp6tyS7co',
    counter: 'gg', title: 'Palpatine vs GG', banners: 63, squad: ['palp', 'fallen-basti', 'traya', 'sion', 'nihilus'], defense: ['gg', 'b1', 'b2', 'magna', 'wat']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs GG, BB-8, B1, B2 and Magna', id: 'Gfd7DHreItM',
    counter: 'gg', title: 'GAS vs GG', banners: 57, squad: ['gas', 'rex', 'fives', 'echo', 'arc'], defense: ['gg', 'b1', 'b2', 'magna', 'bb']
  },
  {
    name: 'Shaak Ti', value: 'palp-basti', desc: 'vs GG, Wat, B1, B2 and Magna', id: 'ANiUlvGYZeM',
    counter: 'gg', title: 'Shaak Ti vs GG', banners: 57, squad: ['shaak-ti', 'rex', 'fives', 'echo', 'arc'], defense: ['gg', 'b1', 'b2', 'magna', 'wat']
  },
  {
    name: 'Bastila', value: 'bastila', desc: 'vs Hux lead and Ultimate SLKR', id: 'InPOTNxWWxs',
    counter: 'hux', title: 'Bastila vs Hux', banners: 60, squad: ['bastila', 'gas', 'gk', 'hermit', 'jkr'], defense: ['hux', 'fost', 'sith-trooper', 'foo', 'slkr']
  },
  {
    name: 'Kylo Ren (Unmasked)', value: 'kru', desc: 'vs Relic Bounty Hunters', id: 'EtUGInuCwVs',
    counter: 'bossk', title: 'KRU vs Bossk', banners: 64, squad: ['kru', 'kylo', 'foo', 'fox', 'fotp'], defense: ['bossk', 'cad', 'embo', 'dengar', 'zam']
  },
  {
    name: 'Traya', value: 'traya', desc: 'Just try not to time out :) ', id: 'cYA_z0S_ND0',
    counter: 'asajj', title: 'Traya vs Asajj', banners: 60, squad: ['traya', 'set', 'sion', 'nihilus', 'sith-marauder'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Carth, relic Zaalbar, Mission, Wat and Wampa', id: 'Ln6srg0VsLQ',
    counter: 'carth', title: 'Traya vs Carth', banners: 62, squad: ['traya', 'set', 'sion', 'nihilus', 'sith-trooper'], defense: ['carth', 'mission', 'zaalbar', 'wat', 'wampa']
  },
  {
    name: 'SL Kylo Ren', value: 'slkr', desc: 'vs GG, Droideka, Magna, B1 and B2', id: 'qk5vRHsG-Xo',
    counter: 'gg', title: 'SLKR solo vs GG', banners: 68, squad: ['slkr'], defense: ['gg', 'b1', 'b2', 'magna', 'droideka']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'RJT and droids squash bugs', id: '75obDjTY9UI',
    counter: 'geos', title: 'RJT vs Geos', banners: 65, squad: ['rjt', 'r2', 'bb', '3po', 'resistance-trooper'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Qi\'ra and Nest', id: '9CqQjIHBicI',
    counter: 'qira', title: 'RJT vs Qi\'ra', banners: 65, squad: ['rjt', 'r2', 'bb', 'scavenger', 'finn'], defense: ['qira', 'l3', 'vandor-chewie', 'nest', 'sth']
  },
  {
    name: 'Shaak Ti', value: 'shaak', desc: 'vs Jango, Boba, Bossk, Dengar and Zam', id: 'vOdB7AJxIlc',
    counter: 'jango', title: 'Shaak Ti vs Jango', banners: 62, squad: ['shaak-ti', 'echo', 'fives', 'rex', 'clone-sergeant'], defense: ['jango', 'boba', 'bossk', 'dengar', 'zam']
  },
  {
    name: 'Rex', value: 'rex', desc: 'vs GG, BB-8, B1, B2 and Droideka', id: '1pBy3Bhram0',
    counter: 'gg', title: 'Rex vs GG', banners: 54, squad: ['rex', 'ahsoka', 'arc', 'rex', 'fives'], defense: ['gg', 'b1', 'b2', 'magna', 'bb']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs Bossk, Greedo, Cad, Boba and Dengar', id: '6Vir7xy-sXs',
    counter: 'bossk', title: 'GAS solo vs Bossk', banners: 68, squad: ['gas'], defense: ['bossk', 'cad', 'boba', 'dengar', 'greedo']
  },
  {
    name: 'Malak', value: 'malak', desc: 'vs Carth, Mission, Zaalbar, Canderous and T3', id: 'bUX-PSPFN78',
    counter: 'carth', title: 'Malak solo vs Carth', banners: 69, squad: ['malak'], defense: ['carth', 'mission', 'zaalbar', 't3', 'canderous']
  },
  {
    name: 'Palpatine', value: 'palp', desc: 'vs relic and zeta Rogue One squad', id: 'ShFztLP7vP0',
    counter: 'jyn', title: 'Palpatine vs Jyn', banners: 62, squad: ['palp', 'vader', 'thrawn', 'death-trooper', 'tarkin'], defense: ['jyn', 'baze', 'chirrut', 'k2', 'cassian']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'vs Darth Revan, Basti, Marauder, Malak and HK', id: 'wL7KPeVI1_4',
    counter: 'dr', title: 'GAS vs Darth Revan', banners: 58, squad: ['gas', 'arc', 'rex', 'fives', 'echo'], defense: ['dr', 'fallen-basti', 'malak', 'hk', 'marauder']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs Qi\'ra, Mission, Zaalbar, Chewie and L3', id: '4gWGGLN7bgo',
    counter: 'qira', title: 'Geos vs Qi\'ra', banners: 65, squad: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac'], defense: ['qira', 'l3', 'vandor-chewie', 'zaalbar', 'mission']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'vs Nute, Nest, Dooku, Droideka and Jango', id: '1K9caS5qnRA',
    counter: 'nute', title: 'CLS vs Nute', banners: 59, squad: ['cls', 'han', 'chewie', 'chirrut', 'baze'], defense: ['nute', 'nest', 'dooku', 'droideka', 'jango']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'vs Asajj, Spirit, Daka, Zombie and Talzin', id: 'pcR7_94FVOo',
    counter: 'asajj', banners: 66, squad: ['dr', 'fallen-basti'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Bossk', value: 'bossk', desc: 'vs Talzin, Spirit, Daka, Zombie and Asajj', id: 'fDD68BDBfJU',
    counter: 'talzin', title: 'Bossk vs Talzin', banners: 65, squad: ['bossk', 'jango', 'zam', 'dengar', 'boba'], defense: ['talzin', 'zombie', 'asajj', 'daka', 'spirit']
  },
  {
    name: 'Jango', value: 'jango', desc: 'vs Talzin, Spirit, Daka, Zombie and Asajj', id: 'UWZ6y6cHQEI',
    counter: 'talzin', title: 'Jango vs Talzin', banners: 59, squad: ['jango', 'bossk', 'zam', 'dengar', 'boba'], defense: ['talzin', 'zombie', 'asajj', 'daka', 'spirit']
  },
  {
    name: 'Shaak Ti', value: 'shaak', desc: 'vs Qi\'ra, Lando, L3, Han and Chewie', id: '_RnxYggjfTI',
    counter: 'qira', title: 'Shaak Ti vs Qi\ra', banners: 62, squad: ['shaak-ti', 'cody', 'arc', 'clone-sergeant', 'fives'], defense: ['qira', 'l3', 'vandor-chewie', 'young-han', 'young-lando']
  },
  {
    name: 'Palpatine', value: 'palp', desc: 'vs Zeta Relic Phoenix squad', id: 'VwwND_I6uF8',
    counter: 'hera', title: 'Palpatine vs Hera', banners: 64, squad: ['palp', 'vader', 'shore', 'tfp', 'tarkin'], defense: ['hera', 'ezra', 'chopper', 'zeb', 'kanan']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'vs Hux, Sith Trooper, Pilots and FOST', id: 's1AvZVICxmg',
    counter: 'hux', title: 'Rey (JT) vs Hux', banners: 63, squad: ['rjt', 'scavenger', '3po', 'bb', 'finn'], defense: ['hux', 'fost', 'fotp', 'sftp', 'sith-trooper']
  },
  {
    name: 'Daka', value: 'daka', desc: 'vs GG, B1, B2, Magna and Droideka', id: 'DPDg-ryeNc0',
    counter: 'gg', title: 'Daka vs GG', banners: 60, squad: ['daka', 'spirit', 'zombie', 'talzin', 'asajj'], defense: ['gg', 'b1', 'b2', 'magna', 'droideka']
  },
  {
    name: 'SL Kylo Ren', value: 'slkr', desc: 'vs DR, Basti, Malak, HK and Trooper', id: '15P5gxjrQWw',
    counter: 'dr', title: 'SLKR solo vs Darth Revan', banners: 67, squad: ['slkr'], defense: ['dr', 'fallen-basti', 'malak', 'hk', 'set']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'vs Darth Revan, Basti, Trooper, Malak and HK', id: 'kUGW_Ibv3Pc',
    counter: 'dr', title: 'Commander Luke vs Darth Revan', banners: 60, squad: ['cls', 'han', 'chewie', '3po', 'thrawn'], defense: ['dr', 'fallen-basti', 'malak', 'hk', 'set']
  },
  {
    name: 'Mirror', value: 'slkr-mirror', desc: 'Mirror matches are not fun', id: 'I0GSxT9vWQ4',
    counter: 'slkr', title: 'SLKR Mirror', banners: 52, squad: ['slkr', 'hux', 'sith-trooper', 'fost', 'kru'], defense: ['slkr', 'fost', 'hux', 'kru', 'sith-trooper']
  },
  {
    name: 'Traya', value: 'traya', desc: 'Against Qi\'ra with Nest', id: 'C5FlzwZ7v98',
    counter: 'qira', title: 'Traya vs Qi\'ra + Nest', banners: 61, squad: ['traya', 'nihilus', 'sion', 'set', 'sith-marauder'], defense: ['qira', 'l3', 'nest', 'mission', 'zaalbar']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'RJT vs KRU without Hux', id: 'jEmIcR1xNBU',
    counter: 'kru', title: 'Rey (JT) vs KRU without Hux', banners: 65, squad: ['rjt', 'scavenger', 'resistance-trooper', 'bb', 'finn'], defense: ['kru', 'fox', 'fost', 'kylo', 'sftp']
  },
  {
    name: 'Padme', value: 'padme', id: 'azcChdJS8Lo',
    counter: 'kru', title: 'Padme vs KRU', banners: 64, squad: ['padme', 'jka', 'gk', 'ahsoka'], defense: ['kru', 'fox', 'fost', 'kylo', 'sith-trooper']
  },
  {
    name: 'Commander Luke Skywalker', desc: 'Rebel trio with droids', value: 'cls', id: 'v5EbyD2F4AI',
    counter: 'bossk', banners: 62, squad: ['cls', 'han', 'chewie', '3po', 'r2'], defense: ['bossk', 'jango', 'boba', 'dengar', 'cad']
  },
  {
    name: 'Rey (JT)', desc: 'Without BB-8', value: 'rjt-bb', id: 'Fxwonlaj6Ig',
    counter: 'bossk', banners: 65, squad: ['rjt', 'r2', '3po', 'holdo', 'hfinn'], defense: ['bossk', 'ig88', 'boba', 'dengar', 'cad']
  },
  {
    name: 'Rey (JT)', desc: 'Without BB-8', value: 'rjt-thrawn', id: 'OCsQAlD7VNg',
    counter: 'bossk', banners: 64, squad: ['rjt', 'thrawn', '3po', 'resistance-trooper', 'finn'], defense: ['bossk', 'greedo', 'boba', 'dengar', 'embo']
  },
  {
    name: 'Padme', value: 'padme', desc: 'With Shaak-Ti', id: 'Ygzf4m7ZLnI',
    counter: 'bossk', banners: 65, squad: ['padme', 'jka', 'ahsoka', 'shaak-ti', 'gk'], defense: ['bossk', 'greedo', 'boba', 'dengar', 'zam']
  },
  {
    name: 'Darth Revan', value: 'mirror', desc: 'Mirror Match', id: 'ZMGD5sm9-Vs',
    counter: 'dr', banners: 59, squad: ['dr', 'fallen-basti', 'hk', 'malak', 'set'], defense: ['dr', 'fallen-basti', 'malak', 'hk', 'set']
  },
  {
    name: 'Jedi Revan', desc: 'The one with Sith Trooper', value: 'jkr-trooper', id: 'clpJPxtyJaE',
    counter: 'dr', banners: 61, squad: ['jkr', 'jedi-basti', 'yoda', 'thrawn', 'hermit'], defense: ['dr', 'fallen-basti', 'malak', 'hk', 'set']
  },
  {
    name: 'Mirror', value: 'mirror', desc: 'G12 GAS vs R7 GAS', id: 'p99OYBwBf1M',
    counter: 'gas', banners: 57, squad: ['gas', 'arc', 'fives', 'rex', 'echo'], defense: ['gas', 'echo', 'arc', 'rex', 'fives']
  },
  {
    name: 'Wampa', value: 'wampa', desc: 'Wampa, Wat, Hermit kitchen sink', id: 'medCCa6ERp0',
    counter: 'jyn', banners: 64, squad: ['ig88', 'wat', 'wampa', 'rg', 'hermit'], defense: ['jyn', 'baze', 'chirrut', 'k2', 'cassian']
  },
  {
    name: 'Padme', value: 'padme7', desc: 'vs Relic 7 GAS', id: 'uQeowVLk3Q8',
    counter: 'gas', banners: 56, squad: ['padme', 'gk', 'ahsoka', 'yoda', 'jka'], defense: ['gas', 'echo', 'arc', 'rex', 'fives']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'With Sith Trooper', id: 'UOlHQoUcGfI',
    counter: 'gg', banners: 60, squad: ['dr', 'set', 'hk', 'malak', 'fallen-basti'], defense: ['gg', 'b1', 'b2', 'magna', 'droideka']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'vs GG + Wat', id: '16edpfjMzaA',
    counter: 'gg', banners: 64, squad: ['jkr', 'jedi-basti', 'yoda', 'hermit', 'jolee'], defense: ['gg', 'b1', 'b2', 'magna', 'wat']
  },
  {
    name: 'SL Kylo Ren', value: 'slkr', desc: 'Kylo solo 63 banners', id: 'f3MYOn8Z6FU',
    counter: 'geos', banners: 68, squad: ['slkr'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Traya', value: 'traya', id: '8W4c-8s18Hk',
    counter: 'geos', banners: 65, squad: ['traya', 'nihilus', 'sion'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'Full Jedi Team', id: 'R7vKfC9P2Yw',
    counter: 'geos', banners: 64, squad: ['jkr', 'jolee', 'jedi-basti', 'yoda', 'hermit'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Darth Revan', value: 'dr', desc: 'Full 60 banners', id: 'bqkSQHJVpO8',
    counter: 'geos', banners: 65, squad: ['dr', 'set', 'hk', 'malak', 'fallen-basti'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'With R2-D2', id: 'GtD6MYcPlVw',
    counter: 'geos', banners: 62, squad: ['cls', 'han', 'chewie', 'r2', 'ben'], defense: ['gba', 'geo-spy', 'geo-soldier', 'poggle', 'sun-fac']
  },
  {
    name: 'Rey (JT)', value: 'dr', desc: 'vs Jango + Nest', id: 'h5dSSmkhSk8',
    counter: 'jango', banners: 64, squad: ['rjt', 'r2', '3po', 'bb', 'finn'], defense: ['jango', 'boba', 'bossk', 'dengar', 'nest']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'With Thrawn', id: 'cHTpW5tZ2lk',
    counter: 'jango', banners: 63, squad: ['cls', 'han', 'chewie', 'thrawn', 'ben'], defense: ['jango', 'boba', 'bossk', 'dengar', 'zam']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'With 6* GAS', id: 'UV0E5Xr5T3E',
    counter: 'jkr', banners: 62, squad: ['gas', 'arc', 'fives', 'rex', 'echo'], defense: ['jkr', 'yoda', 'jedi-basti', 'jolee', 'ben']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'With Kylo and Thrawn', id: '0WLiO1i56nY',
    counter: 'jkr', banners: 64, squad: ['rjt', 'thrawn', '3po', 'bb', 'kylo'], defense: ['jkr', 'yoda', 'jedi-basti', 'jolee', 'gk']
  },
  {
    name: 'SL Kylo Ren', value: 'jkr', desc: 'Solo Nightsisters with SLKR', id: 'okifwu7CCwk',
    counter: 'asajj', banners: 68, squad: ['slkr'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', desc: 'With Executioner and Wat', id: 'oYQcHqTwNpA',
    counter: 'asajj', banners: 65, squad: ['kru', 'foo', 'wat', 'kylo', 'fox'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Jedi Revan', value: 'jkr', desc: 'With Ezra instead of Hermit', id: 'mtI7gPt6zZw',
    counter: 'asajj', banners: 65, squad: ['jkr', 'jedi-basti', 'ezra', 'yoda', 'jolee'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Padme', value: 'padme', desc: 'With Barriss', id: 'NPBMReMlEgI',
    counter: 'asajj', banners: 63, squad: ['padme', 'jka', 'ahsoka', 'gk', 'barriss'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Commander Luke', value: 'cls', desc: 'With Wat', id: 'NGdOuLMdfnE',
    counter: 'asajj', banners: 65, squad: ['cls', 'han', 'chewie', 'ben', 'wat'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Palpatine', value: 'palp', desc: 'With Wat', id: 'zHkWmgus_Ug',
    counter: 'asajj', banners: 64, squad: ['palp', 'vader', 'tarkin', 'shore', 'wat'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Bossk', value: 'bossk', desc: 'With Fetts, Zam and Dengar', id: '5mDdp6ONCpU',
    counter: 'asajj', banners: 65, squad: ['bossk', 'boba', 'jango', 'dengar', 'zam'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Jango', value: 'jango', desc: 'With Bossk, Boba, Zam and Dengar', id: 'lU7l50L8T30',
    counter: 'asajj', banners: 62, squad: ['jango', 'bossk', 'jango', 'dengar', 'zam'], defense: ['asajj', 'zombie', 'talzin', 'daka', 'spirit']
  },
  {
    name: 'Jedi Knight Revan', value: 'jkr', desc: 'vs Nute + Nest', id: 'd6hfYfclY3Y',
    counter: 'nute', banners: 65, squad: ['jkr', 'jolee', 'hermit', 'yoda', 'jedi-basti'], defense: ['nute', 'nest', 'gba', 'vandor-chewie', 'jango']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: '6* GAS vs Nute', id: '0HXjIWqkgiw',
    counter: 'nute', banners: 63, squad: ['gas', 'echo', 'rex', 'arc'], defense: ['nute', 'wat', 'dooku', 'droideka', 'jango']
  },
  {
    name: 'Traya', value: 'traya', desc: 'vs Nute + Wat', id: '-R9hyh_R5UE',
    counter: 'nute', banners: 52, squad: ['traya', 'sid', 'palp', 'sion', 'nihilus'], defense: ['nute', 'nest', 'dooku', 'wat', 'jango']
  },
  {
    name: 'Geos', value: 'geos', desc: 'vs Nute + Nest', url: 'https://youtu.be/5SfCXxgNJrs?t=1922', image: 'geos-nute',
    counter: 'nute', banners: 63, squad: ['gba', 'geo-spy', 'sun-fac', 'poggle', 'geo-soldier'], defense: ['nute', 'nest', 'wat', 'droideka', 'jango']
  },
  {
    name: 'SL Kylo Ren', value: 'slkr', desc: 'Kylo solo 63 banners', id: 'G-UoWG_Goow',
    counter: 'padme', banners: 68, squad: ['slkr'], defense: ['padme', 'jka', 'ahsoka', '3po', 'gk']
  },
  {
    name: 'General Skywalker', value: 'gas', desc: 'With 6* GAS', id: '37-Af24t2pI',
    counter: 'padme', banners: 58, squad: ['gas', 'rex', 'fives', 'echo', 'arc'], defense: ['padme', 'jka', 'ahsoka', 'barriss', 'gk']
  },
  {
    name: 'Mirror', value: 'mirror', desc: 'vs faster Padme', id: 'Dak98k51jqE',
    counter: 'padme', banners: 59, squad: ['padme', 'ahsoka', 'jka', 'gk', '3po'], defense: ['padme', 'jka', 'ahsoka', '3po', 'gk']
  },
  {
    name: 'Traya', value: 'traya', desc: 'With Thrawn', id: '6QLNluBVQpg',
    counter: 'padme', banners: 62, squad: ['traya', 'thrawn', 'sion', 'nihilus', 'set'], defense: ['padme', 'jka', 'ahsoka', 'shaak-ti', 'gk']
  },
  {
    name: 'Padme', value: 'padme', desc: 'vs 501st Clones + Barriss', url: 'https://youtu.be/kIG-g2_4lIs?t=415', image: 'padme-rex',
    counter: 'rex', banners: 59, squad: ['padme', '3po', 'ahsoka', 'gk', 'jka'], defense: ['rex', 'fives', 'echo', 'arc', 'barriss']
  },
  {
    name: 'Malak', value: 'malak', desc: 'Malak solo 64 banners', id: 'XuQQQsDe6ZI',
    counter: 'shaak', banners: 69, squad: ['malak'], defense: ['shaak-ti', 'rex', 'fives', 'arc', 'echo']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', desc: 'With Executioner and Wat', id: 'iFZZVhG7md0',
    counter: 'talzin', banners: 65, squad: ['kru', 'wat', 'fox', 'foo', 'kylo'], defense: ['talzin', 'zombie', 'asajj', 'daka', 'spirit']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', desc: 'FO vs Old Republic', id: '1Zq5bWv3WNE',
    counter: 'carth', banners: 64, squad: ['kru', 'foo', 'fox', 'phasma', 'kylo'], defense: ['carth', 'mission', 'zaalbar', 'juhani', 'canderous']
  },
  {
    name: 'Rey (JT)', value: 'rjt', desc: 'RJT vs Old Republic', id: 'D5mYo_nLpEU',
    counter: 'carth', banners: 65, squad: ['rjt', 'finn', 'r2', 'resistance-trooper', '3po'], defense: ['carth', 'mission', 'zaalbar', 'juhani', 'canderous']
  },
  {
    name: 'Kylo Ren Unmasked', value: 'kru', desc: 'Against zeta Phoenix squad', id: 'sHCQY9x6Gqo',
    counter: 'hera', banners: 64, squad: ['kru', 'kylo', 'phasma', 'fox', 'foo'], defense: ['hera', 'ezra', 'sabine', 'zeb', 'kanan']
  }
];

export const recent = [
  {
    title: '5v5 GAC', desc: 'Saw Haunts Me + Story Time', id: '0Ju5Ii75L3Y'
  },
  {
    title: '5v5 GAC', desc: 'One final push of Set 16', id: 'k3ya9AeQpiM'
  },
  {
    title: '5v5 GAC', desc: 'Do these counters still work?', id: 'OVzwY0NUeU8'
  },
  {
    title: '5v5 GAC', desc: 'Is the defense without Jar Jar really dead?', id: 'JGbUxFgVBQg'
  },
  {
    title: 'Livestrean', desc: '5v5 GAC Returns with a Brand New Datacron Set', id: 'rUz4tgZjSGs'
  },
  {
    title: '5v5 GAC', desc: 'Darth Bane wrecks everything + More!', id: 'XM0W2FBR48s'
  },
];