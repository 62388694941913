import { sector0 } from '../data/sector0'
import { sector1 } from '../data/sector1'
import { sector2 } from '../data/sector2'
import { sector3 } from '../data/sector3'
import { sector4 } from '../data/sector4'
import { sector5 } from '../data/sector5'
import { buildPlanBattles } from './planBattles'

const buildDayProgress = {
  day1: [],
  day2: [],
  day3: [],
  day4: [],
  day5: [],
  day6: [],
  day7: [],
  day8: [],
  day9: [],
  day10: [],
  day11: [],
  day12: [],
  day13: [],
  day14: []
}

export const buildPlanProgress = () => {
  const buildDayBattles = buildPlanBattles()
  let buildProgress = []
  let initialProgress = {
    sector0: [],
    sector1: [],
    sector2: [],
    sector3: [],
    sector4: [],
    sector5: [],
  }

  const buildInitialProgress = (data, name) => {
    data.forEach(feat => {
      const { sectorBoss, title, qty, sector, ignore } = feat
      !(sectorBoss || ignore) && initialProgress[name].push({
        name: title,
        target: qty,
        current: 0,
        sector: sector
      })
    })
  }

  buildInitialProgress(sector0, 'sector0')
  buildInitialProgress(sector1, 'sector1')
  buildInitialProgress(sector2, 'sector2')
  buildInitialProgress(sector3, 'sector3')
  buildInitialProgress(sector4, 'sector4')
  buildInitialProgress(sector5, 'sector5')

  let globalProgress = { ...buildDayProgress }

  let sectorProgres = {
    sector1: { ...buildDayProgress },
    sector2: { ...buildDayProgress },
    sector3: { ...buildDayProgress },
    sector4: { ...buildDayProgress },
    sector5: { ...buildDayProgress },
  }

  let latestProgres = {
    sector1: [...initialProgress.sector1],
    sector2: [...initialProgress.sector2],
    sector3: [...initialProgress.sector3],
    sector4: [...initialProgress.sector4],
    sector5: [...initialProgress.sector5],
  }

  Object.keys(sectorProgres).forEach(key => {
    const getSector = sectorProgres[key]

    Object.keys(getSector).forEach(dayKey => {
      getSector[dayKey] = [...initialProgress[key]]
    })
  })

  Object.keys(globalProgress).forEach(globalKey => {
    globalProgress[globalKey] = [ ...initialProgress.sector0 ]
  })

  const calculateFeats = (feats, numOfBattles, progress) => {
    let updateFeatProgress = [...progress]
    progress.length > 0 && feats.forEach(feat => {
      const calculateQty = (feat.qty ? feat.qty : 1) * numOfBattles
      const findItem = updateFeatProgress.find(item => item.name === feat.name)
      if (findItem) {
        const updateItem = {
          ...findItem,
          current: calculateQty + findItem.current
        }
        const removeItem = updateFeatProgress.filter(item => item.name !== feat.name)
        updateFeatProgress = [
          ...removeItem,
          updateItem
        ]
      }
    })
    return updateFeatProgress.sort((a, b) => a.name.localeCompare(b.name))
  }

  Object.keys(buildDayBattles).forEach(key => {
    const getDay = buildDayBattles[key]
    getDay.forEach(day => {
      const { squads } = day

      squads.forEach(squad => {
        const { sector, numOfBattles, feats, global } = squad
        const prevDay = Number(key.replace('day', '') - 1)
        const targetSector = `sector${sector}`
        const getDaySector = latestProgres[targetSector]

        if (feats && getDaySector?.length > 0) {
          const updateFeats = feats && calculateFeats(feats, numOfBattles, getDaySector)
          if (updateFeats) {
            sectorProgres = {
              ...sectorProgres,
              [targetSector]: {
                ...sectorProgres[targetSector],
                [key]: updateFeats,
                [`day${prevDay + 1}`]: updateFeats
              }
            }
            latestProgres = {
              ...latestProgres,
              [targetSector]: updateFeats
            }
          }
        }

        if (global) {
          const updateGlobalFeats = global && calculateFeats(global, numOfBattles, globalProgress[key])
          if (updateGlobalFeats) {
            globalProgress = {
              ...globalProgress,
              [key]: updateGlobalFeats,
              [`day${prevDay + 2}`]: updateGlobalFeats
            }
          }
        }
      })

    })
  })


  buildProgress = { sectorProgres: sectorProgres, globalProgress: globalProgress }

  return buildProgress
}