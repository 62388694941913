const day13 = [
  /* {
    title: `Sector 5 - Datacron Farming`,
    teams: [
      {
        id: 1301,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=378&si=Nrcy93gMlcTW1Ueh',
        feats: [
          { name: 'Geo Spy', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 1302,
        name: '2x',
        videoThumb: 'm8GLdlP5SMA?t=333&si=qha9YFIPipIyUiYe',
        feats: [
          { name: 'Plague', qty: 10 },
          { name: 'Peridea', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Accuracy Up', complete: true },
      { name: 'Plague', complete: true },
      { name: 'Geo Spy', current: 15, target: 15 },
      { name: 'Peridea', current: 14, target: 25 }
    ]
  },


  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true },
      { name: 'Imperial Remnants', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Master QGJ', current: 15, target: 15 },
    ]
  } */
];

export {
  day13
}