const day7 = [
  /* {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 701,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
      {
        id: 702,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=366&si=eJO1o08slFIwcxTl',
        feats: [
          { name: 'Decay', qty: 10 },
        ],
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'tfp', name: 'Tie Pilot' },
          { image: 'stormtrooper', name: 'Stormtrooper' }
        ]
      },
      {
        id: 703,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=141&si=p5cZSWDcFysehgUA',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Tenacity Down', complete: true },
      { name: 'Morgan', current: 6, target: 10 },
      { name: 'Decay', current: 40, target: 50 },
      { name: 'Gungans', current: 30, target: 35 }
    ]
  },


  {
    title: `Sector 3 - Datacron Farming`,
    teams: [
      {
        id: 704,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Retaliate', qty: 2 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 705,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        id: 706,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Geonosians', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Evasion Up', current: 50, target: 50 },
      { name: 'Acolyte', current: 11, target: 15 },
      { name: 'Retaliate', current: 12, target: 15 },
      { name: 'Geonosians', current: 6, target: 15 }
    ]
  },




  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Stagger', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Nightsisters', complete: true  },
      { name: 'Imperial Remnants', complete: true  },
      { name: 'Gungans', current: 15, target: 15 },
      { name: 'Master QGJ', current: 9, target: 15 },
    ]
  } */
];

export {
  day7
}