import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Page from '../../components/page';
import ConquestTeams from '../../components/conquest';
import ConquestMenu from './menu';
import ConquestPlanNav from './components/planNav';
import { daily, planProgress } from './data/daily';
import { gold, goldProgress } from './gold/daily';
import { buildCustomPlan } from './utils/planBuild';

const FeatBadge = (props) => <span className='badge rounded-pill badge-info mx-1'>{props.text}</span>

const Intro = (props) => <div>A guide with suggested teams to help you achive max rewards with minimum refreshes. Key units for feats are marked in <span className='badge rounded-pill badge-primary mx-1'>Yellow</span>.
  {/* <div className="d-flex align-items-center mt-3">
    <div className="form-group-label mb-0 ml-0 mr-3">Crate Selection</div>
    <div className='btn-group btn-group-sm'>
      <button className={classNames('btn btn-outline-danger px-3', props.value === 'all' && 'active')} onClick={() => props.change('all')}>
        Red Crate
      </button>
      <button className={classNames('btn btn-outline-warning px-3', props.value === 'gold' && 'active')} onClick={() => props.change('gold')}>
        Gold Crate
      </button>
    </div>
  </div> */}
  <hr className='bg-light' />
  <div className="mt-3">
   {/*  {props.value === 'all' && <>
      <div className='h3'>Red Crate</div>
      The daily plan for Red Crate is skipping the below feats (30 keycards)<br /> <FeatBadge text='Morgan' /> <FeatBadge text='Alert' /> <FeatBadge text='Jar Jar' /> <FeatBadge text='Amidala' /> <FeatBadge text='Gungans' /> <FeatBadge text='Luthen' /><br /><br />
      <strong>Total of up to 34 keycards can be skipped to unlock Red Crate.</strong>
    </>}
    {props.value === 'gold' && <>
      <div className='h3'>Gold Crate</div>
      The daily plan for Gold Crate is skipping the below feats (128 keycards)<br /> <FeatBadge text='Imperial Remnants' /> <FeatBadge text='Gungans' /> <FeatBadge text='Peridea' /> <FeatBadge text='Padawan Obi-Wan' /> <FeatBadge text='DT Gideon' /> <FeatBadge text='Blight' /> <FeatBadge text='Retaliate' /> <FeatBadge text='Geos' /> <FeatBadge text='Tarpals' /> <FeatBadge text='Breach' /> <FeatBadge text='Decay' /> <br /><br />
      <strong>Total of up to 134 keycards can be skipped to unlock Gold Crate.</strong>
    </>} */}
    <strong>Total of up to 34 keycards can be skipped to unlock Red Crate.</strong>
    <div className='alert alert-warning mt-3'><strong>Announcement</strong><br/>The Conquest Plan is currently missing some features as I am in the process of upgrading the framework. Once the upgrade is complete I will able to add more features and enhancements and I can't wait to share those with you as soon as they are ready!</div>
  </div>
</div>;

const ConquestPlan = () => {
  const [selectedDay, setDay] = useState(0);
  const [trackProgress, setProgress] = useState([]);
  const [planData, setPlanData] = useState(daily);
  const [customPlanData, setCustomPlanData] = useState(buildCustomPlan());
  const [crate, setCrate] = useState('red');
  const [planValue, setPlanValue] = useState('all');
  const [conquestLive] = useState(true);

  useEffect(() => {
    document.title = 'Conquest Daily Plan - SWGOH-4 Life';
    const dayPlan = JSON.parse(localStorage.getItem("dayPlan"));
    dayPlan === null ? setDay(0) : setDay(dayPlan);
    const getTrack = JSON.parse(localStorage.getItem("trackConquest45"));
    const getUnit = localStorage.getItem("unitConquest45")
    getTrack === null ? setProgress(getUnit === 'gold' ? goldProgress : planProgress) : setProgress(getTrack);
    setCrate(getUnit === 'gold' ? 'gold' : 'red')
    getUnit && loadPlan(getUnit, true);
  }, []);

  const onChangeDay = (d, top) => {
    setDay(d);
    localStorage.setItem("dayPlan", JSON.stringify(d));
    top && window.scroll({
      top: 100,
      behavior: "smooth"
    });
  }

  const onCompleteBattle = (id) => {
    let getBattle = trackProgress.find(b => b.id === id);
    let updateBattle = {
      ...getBattle, completed: getBattle.completed + 1
    };
    let updateTracker = trackProgress.map(b => b.id === id ? updateBattle : b);
    setProgress(updateTracker);
    localStorage.setItem("trackConquest45", JSON.stringify(updateTracker));
  }

  const onResetBattle = (id) => {
    let getBattle = trackProgress.find(b => b.id === id);
    let updateBattle = {
      ...getBattle, completed: 0
    };
    let updateTracker = trackProgress.map(b => b.id === id ? updateBattle : b);
    setProgress(updateTracker);
    localStorage.setItem("trackConquest45", JSON.stringify(updateTracker));
  }

  const loadPlan = (v, onLoad) => {
    if (v === 'all') {
      setPlanData(daily);
      !onLoad && setProgress(planProgress);
    } else if (v === 'gold') {
      setPlanData(gold);
      !onLoad && setProgress(goldProgress);
    }
    setCrate(v)
    setPlanValue(v);
    localStorage.setItem("unitConquest45", v);
  }

  const onPlanChange = (s) => {
    let v = s;
    loadPlan(v);
    onChangeDay(0);
    localStorage.removeItem("trackConquest45");
  }

  return (
    <Page title="Conquest Daily Plan" intro={<Intro change={onPlanChange} value={planValue} />} menu={<ConquestMenu />}>
      {conquestLive ?
        <>
          <ConquestPlanNav selectedDay={selectedDay} onChangeDay={onChangeDay} />
          {customPlanData && customPlanData[`day${selectedDay + 1}`].length > 0 ? customPlanData[`day${selectedDay + 1}`].map((day, i) =>
            <ConquestTeams
              squads={day.squads} key={i} title={day.title} boss={day.boss} progress={day.progress}
              /*  planProgress={trackProgress.length > 0 && trackProgress} */
              onComplete={onCompleteBattle} onReset={onResetBattle} dataDisk={day.dataDisk}
            />
          ) :
            <div className='text-center mb-5'>
              <div className="alert alert-dark mt-3 d-inline-flex">Daily plan for Day {selectedDay + 1} coming soon!</div>
            </div>
          }

          {selectedDay === 13 && crate !== 'gold' &&
            <div className="mb-5 mb-md-6 row justify-content-center align-items-center">
              <div className="col-12 col-md-10 col-lg-8">
                <img alt="" className="img-fluid" src={`/images/conquest/congrats.png`} />
              </div>
            </div>
          }

          <div className='mt-5'>
            <ConquestPlanNav selectedDay={selectedDay} onChangeDay={onChangeDay} />
          </div>
        </>
        :
        <div>
          <ConquestTeams
            title="Daily Plan Under Construction" boss="construction"
          />
        </div>
      }
    </Page>
  );
}

export default ConquestPlan;
