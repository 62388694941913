const day2 = [
  /* {
    title: `Sector 1 - Battles`,
    teams: [
      {
        id: 201,
        name: '2x',
        videoThumb: 'VSOm6nRcj4g?t=299&si=bIifZLNi3oyK6WG1',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'starck', name: 'Starck' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'mara', name: 'Mara' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
    ]
  },
  {
    title: `Sector 1 - Final Boss`,
    boss: 'malgus',
    teams: [
      {
        id: 202,
        name: 'Boomadier',
        videoThumb: '5AeDDhXuz5A?t=384&si=qp1-oFQFgsR4Km4m',
        feats: [
          { name: 'Tenacity Down', qty: 5 },
          { name: 'Gungans', qty: 4 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier', main: true  },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Tenacity Down', complete: true },
      { name: 'Morgan', current: 4, target: 10 },
      { name: 'Decay', current: 20, target: 50 },
      { name: 'Gungans', current: 20, target: 35 }
    ]
  },


  {
    title: `Sector 2 - Battles`,
    teams: [
      {
        id: 204,
        name: '2x',
        videoThumb: 'YWLK52JUuxc?t=192&si=Q92PaR11ooJYa4Uf',
        feats: [
          { name: 'Bonus Turn', qty: 10 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'vader', name: 'Darth Vader', main: true },
          { image: 'han', name: 'Han', main: true },
          { image: 'mara', name: 'Mara' },
          { image: 'fallen-basti', name: 'Fallen Basti' },
          { image: 'malak', name: 'Malak'},
        ]
      },
      {
        id: 205,
        name: '2x',
        videoThumb: 'YWLK52JUuxc?t=84&si=NGiQ_N50nuAWEP1W',
        feats: [
          { name: 'Bonus Turn', qty: 10 },
          { name: 'Breach', qty: 5 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul', main: true },
          { image: 'wat', name: 'Wat', main: true },
          { image: 'paz', name: 'Paz' },
          { image: 'sabine', name: 'Sabine'},
          { image: 'canderous', name: 'Candy' },
        ]
      },
      {
        id: 206,
        name: '2x',
        videoThumb: 'YWLK52JUuxc?t=262&si=I8sBkH7NRzRqh-9U',
        feats: [
          { name: 'Breach', qty: 10 },
          { name: 'Bonus Turn', qty: 2 },
        ],
        global: [
          { name: 'Master QGJ' }
        ],
        toons: [          
          { image: 'jml', name: 'Master Luke', main: true },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'han', name: 'Han' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        id: 207,
        name: '4x',
        videoThumb: 'YWLK52JUuxc?t=348&si=HK1RG73MJFI8zvIg',
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'dark-trooper', name: 'Dark Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        id: 208,
        name: '4x',
        videoThumb: '',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      }
    ]
  },
  {
    title: `Sector 2 - Mini Boss`,
    boss: 'hux',
    teams: [
      {
        id: 209,
        name: 'Peridea',
        videoThumb: 'HbVl6AnlkwY?t=203&si=YNLrOWT9MbzNtKNG',
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'peridea', name: 'Peridea', main: true },
        ]
      },
      {
        id: 210,
        name: 'Qui-Gon',
        videoThumb: 'HbVl6AnlkwY?t=543&si=oNslF13teTJikqOY',
        feats: [
          { name: 'Breach', qty: 10 },
          { name: 'Bonus Turn', qty: 2 },
        ],
        global: [
          { name: 'Master QGJ' }
        ],
        toons: [          
          { image: 'jml', name: 'Master Luke'},
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'qgj', name: 'QGJ', main: true },
          { image: 'han', name: 'Han' },
          { image: 'wat', name: 'Wat' },
        ]
      }
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Morgan', skip: true },
      { name: 'Alert', skip: true },
      { name: 'Bonus Turn', current: 40, target: 50 },
      { name: 'Breach', current: 40, target: 50 }
    ]
  },



 
  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Challenge Path', current: 100, target: 250 },      
      { name: 'Nightsisters', current: 5, target: 20 },
      { name: 'Stagger', current: 70, target: 100 },
      { name: 'Imperial Remnants', current: 6, target: 20 },
      { name: 'Gungans', current: 9, target: 15 },
      { name: 'Master QGJ', current: 4, target: 15 },
    ]
  } */
];

export {
  day2
}