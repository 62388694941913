const day3 = [
  /* {
    title: `Sector 2 - Battles`,
    teams: [
      {
        id: 302,
        name: '2x',
        videoThumb: 'YWLK52JUuxc?t=192&si=Q92PaR11ooJYa4Uf',
        feats: [
          { name: 'Bonus Turn', qty: 10 },
        ],
        global: [
          { name: 'Stagger' },
        ],
        toons: [
          { image: 'vader', name: 'Darth Vader', main: true },
          { image: 'han', name: 'Han', main: true },
          { image: 'mara', name: 'Mara' },
          { image: 'fallen-basti', name: 'Fallen Basti' },
          { image: 'malak', name: 'Malak'},
        ]
      },
      {
        id: 303,
        name: '2x',
        videoThumb: 'YWLK52JUuxc?t=262&si=I8sBkH7NRzRqh-9U',
        feats: [
          { name: 'Breach', qty: 10 },
          { name: 'Bonus Turn', qty: 2 },
        ],
        global: [
          { name: 'Master QGJ' }
        ],
        toons: [          
          { image: 'jml', name: 'Master Luke', main: true },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'han', name: 'Han' },
          { image: 'wat', name: 'Wat' },
        ]
      },
      {
        id: 304,
        name: '2x',
        videoThumb: 'YWLK52JUuxc?t=348&si=HK1RG73MJFI8zvIg',
        global: [
          { name: 'Stagger' },
          { name: 'Imperial Remnants' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'dark-trooper', name: 'Dark Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
    ]
  },
  {
    title: `Sector 2 - Final Boss`,
    boss: 'iden',
    teams: [
      {
        id: 305,
        name: 'Tarpals',
        videoThumb: 'HbVl6AnlkwY?t=710&si=jhJvmHXvhiCM3pt_',
        global: [
          { name: 'Gungans' },
        ],
        toons: [          
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'jar-jar', name: 'Jar Jar' },
          { image: 'tarpals', name: 'Tarpals', main: true },
        ]
      },
      {
        id: 306,
        name: 'Dark Side',
        videoThumb: 'HbVl6AnlkwY?t=768&si=FsdlRDvTutzLsRQE',
        feats: [
          { name: 'Bonus Turn', qty: 5 },
        ],
        toons: [
          { image: 'gg', name: 'GG' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'nute', name: 'Nute' },
          { image: 'wat', name: 'Wat' },
        ]
      }
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Morgan', skip: true },
      { name: 'Alert', skip: true },
      { name: 'Bonus Turn', current: 50, target: 50 },
      { name: 'Breach', current: 50, target: 50 }
    ]
  },



  {
    title: `Sector 3 - Battles`,
    teams: [
      {
        id: 307,
        name: '2x',
        videoThumb: 'ncdA62rgwS0?t=117&si=e6nsp8kHlg7SMzBR',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Master QGJ' },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 308,
        name: '4x',
        videoThumb: 'ncdA62rgwS0?t=165&si=IO0rQrit30QUP9wV',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte', main: true },
          { image: 'daka', name: 'Daka' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        id: 309,
        name: '2x',
        videoThumb: 'ncdA62rgwS0?t=277&si=Y9XtAjtmLpp9DLiA',
        feats: [
          { name: 'Geonosians', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
      {
        id: 310,
        name: '2x',
        videoThumb: 'ncdA62rgwS0?t=332&si=bqLLmedqBTkdRA47',
        feats: [
          { name: 'Retaliate', qty: 2 },
        ],
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
    ]
  },
  {
    title: `Sector 3 - Mini Boss`,
    boss: 'raddus',
    teams: [
      {
        id: 312,
        name: 'Morgan',
        videoThumb: 'His-MU1Fpmc?t=88&si=DYOV_pgVirFDOPg1',
        feats: [
          { name: 'Acolyte', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'acolyte', name: 'Acolyte' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        id: 313,
        name: 'Enoch',
        videoThumb: 'His-MU1Fpmc?t=186&si=XerhC1kVzYQg6OOF',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'Echo' },
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'wrecker', name: 'Wrecker' },
          { image: 'tech', name: 'Tech' }
        ]
      }
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Evasion Up', current: 30, target: 50 },
      { name: 'Acolyte', current: 5, target: 15 },
      { name: 'Retaliate', current: 4, target: 15 },
      { name: 'Geonosians', current: 2, target: 15 }
    ]
  },




  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Challenge Path', current: 150, target: 250 },
      { name: 'Nightsisters', current: 12, target: 20 },
      { name: 'Stagger', current: 100, target: 100 },
      { name: 'Imperial Remnants', current: 8, target: 20 },
      { name: 'Gungans', current: 12, target: 15 },
      { name: 'Master QGJ', current: 6, target: 15 },
    ]
  } */
];

export {
  day3
}