import React from 'react';
import classNames from 'classnames';

import Countdown from 'react-countdown';

const dayRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  } else {
    return <div>{days}d {hours}h {minutes}m {seconds}s</div>;
  }
}

const ConquestPlanNav = (props) => {
  const { selectedDay, onChangeDay } = props

  return (
    <div className="mb-5 mb-md-6 row justify-content-center align-items-center">
      <div className='col-12 col-sm pb-3 pb-sm-0 order-sm-1'>
        <div className='row'>
          <div className='col-6 col-sm-12 col-md text-right'>
            <strong>Event ends in</strong>
          </div>
          <div className='col-6 col-sm-12 col-md-auto pl-0 text-sm-right text-monospace'>
            <Countdown date={new Date('2024-12-09T18:00:00Z')} renderer={dayRenderer} />
          </div>
        </div>
      </div>
      <div className="col-auto">
        <button
          disabled={selectedDay === 0}
          className={classNames('d-print-none btn py-2 px-4', selectedDay === 0 ? 'btn-outline-info' : 'btn-outline-light')}
          onClick={() => onChangeDay(selectedDay - 1, true)}>Prev</button>
      </div>
      <div className="col-auto">
        <h2 className="h2 mb-0">Day {selectedDay + 1}</h2>
      </div>
      <div className="col-auto">
        <button
          disabled={selectedDay === 13}
          className={classNames('d-print-none btn py-2 px-4', selectedDay === 13 ? 'btn-outline-info' : 'btn-outline-light')}
          onClick={() => onChangeDay(selectedDay + 1, true)}>Next</button>
      </div>
    </div>
  );
}

export default ConquestPlanNav;
