const sector2 = [

  {
    title: 'Potency Up',
    text: 'Gain Potency Up 50 times',
    keycards: 5,
    qty: 50,
    plan: [
      { day: 2, qty: 2, sector: 2 },
    ],
    sector: 2,
    teams: [
      {
        default: true,
        videoThumb: 'uHKDi1QLOig?t=865&si=9DIcyhivVBFu0mb1',
        feats: [
          { name: 'Potency Up', qty: 10 },
          { name: 'Armor Shred', qty: 1 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'mara', name: 'Mara' },
          { image: 'fifth-brother', name: '5th Bro', main: true },
          { image: 'marauder', name: 'Marauder', main: true },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=990&si=cdNwGeZk8MBZhspj',
        feats: [
          { name: 'Potency Up', qty: 10 },
          { name: 'Armor Shred', qty: 2 },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kam', name: 'KAM' },
          { image: 'kyle', name: 'Kyle', main: true  },
          { image: 'kit', name: 'Kit', main: true  },
          { image: 'gas', name: 'GAS'},
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=1240&si=6o68Gh77LHWi7Ic_',
        feats: [
          { name: 'Potency Up', qty: 5 },
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'gba', name: 'GBA' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'geo-soldier', name: 'Solider' },
          { image: 'geo-spy', name: 'Geo Spy', main: true },
        ]
      },
    ]
  },
  {
    title: 'Great Mothers',
    text: 'Win 10 battles with Great Mothers surviving',
    keycards: 5,
    qty: 10,
    plan: [
      { day: 2, qty: 2, sector: 2 },
      { day: 3, qty: 2, sector: 2 },
      { day: 10, qty: 2, sector: 2 },
      { day: 11, qty: 3, sector: 2 },
    ],
    sector: 2,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=728&si=jCdDi65SJ6fqHk0t',
        feats: [
          { name: 'Great Mothers', qty: 1 },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'wat', name: 'Wat' },
          { image: 'jango', name: 'Jango' },
          { image: 'canderous', name: 'Candy' },
          { image: 'gm', name: 'Great Mothers', main: true },
        ]
      },
      {
        default: true,
        videoThumb: 'uHKDi1QLOig?t=1707&si=rZVM2ibRPsH8W8nR',
        feats: [
          { name: 'Great Mothers', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers', main: true },
          { image: 'asajj', name: 'Asajj' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: `UFUs`,
    text: `Win 10 battles with a full squad of Unaligned Force Users`,
    keycards: 5,
    qty: 10,
    plan: [
      { day: 2, qty: 2, sector: 2 },
      { day: 3, qty: 2, sector: 2 },
      { day: 10, qty: 2, sector: 2 },
      { day: 11, qty: 2, sector: 2 },
      { day: 12, qty: 2, sector: 2 },
    ],
    sector: 2,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=887&si=sW6fVh3it28iDjBN',
        feats: [
          { name: 'UFUs', qty: 1 },
        ],
        toons: [
          { image: 'cere', name: 'Cere' },
          { image: 'rjt', name: 'RJT' },
          { image: 'scavenger', name: 'Rey Scavenger' },
          { image: 'cat', name: 'Commander Ahsoke' },
          { image: 'cal', name: 'Cal' },
        ]
      },
      {
        videoThumb: '0NpXGIB23Jw?t=794&si=Gq3M9v_z2DBVSwGq',
        feats: [
          { name: 'UFUs', qty: 1 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'mara', name: 'Mara' },
          { image: 'sk', name: 'Starkiller' },
          { image: 'malicos', name: 'Malicos' },
        ]
      },
      {
        default: true,
        videoThumb: 'uHKDi1QLOig?t=1853&si=ndrSAXYflKYddir-',
        feats: [
          { name: 'UFUs', qty: 1 },
        ],
        global: [
          { name: 'Dark UFUs' },
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=1950&si=3fdjMru-VLM--Ee5',
        feats: [
          { name: 'UFUs', qty: 1 },
          { name: 'Armor Shred', qty: 1 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'reva', name: 'Reva' },
          { image: 'gi', name: 'Grand Inquisitor' },
          { image: 'seventh-sister', name: '7th Sister' },
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'eighth-brother', name: '8th Bro' },
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'UFUs', qty: 1 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'mando-maul', name: 'Maul' },
          { image: 'starkiller', name: 'Starkiller' },
          { image: 'mara', name: 'Mara' },
          { image: 'malicos', name: 'Malicos' },
          { image: 'asajj', name: 'Asajj' }
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=3303&si=KB3Oq8cJRq27Ndsg',
        feats: [
          { name: 'UFUs', qty: 1 },
        ],
        toons: [
          { image: 'cere', name: 'Cere' },
          { image: 'cal', name: 'Cal' },
          { image: 'cls', name: 'CLS' },
          { image: 'cat', name: 'CAT' },
          { image: 'fulcrum', name: 'Fulcrum' }
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=2149&si=NBzrcX5mPvaF2dS3',
        feats: [
          { name: 'UFUs', qty: 1 },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'rjt', name: 'RJT' },
          { image: 'scavenger', name: 'Scavenger' },
          { image: 'visas', name: 'Visas' },
          { image: 'ben-solo', name: 'Ben Solo' }
        ]
      }
    ]
  },
  {
    title: 'Armor Shred',
    text: 'Attempt to inflict Armor Shred 30 times',
    keycards: 5,
    qty: 30,
    plan: [
      { day: 2, qty: 2, sector: 2 },
      { day: 3, qty: 2, sector: 2 },
      { day: 7, qty: 2, sector: 2 },
      { day: 8, qty: 2, sector: 2 },
      { day: 9, qty: 3, sector: 2 },
      { day: 10, qty: 2, sector: 2 },
    ],
    sector: 2,
    teams: [  
      {
        videoThumb: 'uHKDi1QLOig?t=865&si=9DIcyhivVBFu0mb1',
        feats: [
          { name: 'Potency Up', qty: 10 },
          { name: 'Armor Shred', qty: 1 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'gideon', name: 'Gideon', main: true },
          { image: 'mara', name: 'Mara' },
          { image: 'fifth-brother', name: '5th Bro' },
          { image: 'marauder', name: 'Marauder' },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=1096&si=vXKM9S24c5kMv2va',
        feats: [
          { name: 'Potency Up', qty: 2 },
          { name: 'Armor Shred', qty: 1 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'sabine', name: 'Sabine', main: true },
          { image: 'ben', name: 'Old Ben' },
        ]
      },
      {
        default: true,
        videoThumb: 'uHKDi1QLOig?t=990&si=cdNwGeZk8MBZhspj',
        feats: [
          { name: 'Potency Up', qty: 10 },
          { name: 'Armor Shred', qty: 2 },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'kam', name: 'KAM', main: true },
          { image: 'kyle', name: 'Kyle' },
          { image: 'kit', name: 'Kit' },
          { image: 'gas', name: 'GAS', main: true },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=3932&si=l_lUQNtmRnKzf5cI',
        feats: [
          { name: 'Armor Shred', qty: 4 },
        ],
        toons: [
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'kam', name: 'KAM', main: true },
          { image: 'ezra-exile', name: 'Ezra Exile' },
          { image: 'jedi-cal', name: 'Jedi Cal', main: true },
          { image: 'gas', name: 'GAS', main: true },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=5160&si=DYeEL-19T9Pbqjut',
        feats: [
          { name: 'Armor Shred', qty: 2 },
        ],
        toons: [
          { image: 'bo-mandalor', name: 'Bo Mandalore', main: true },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'paz', name: 'Paz' },
          { image: 'sabine', name: 'Sabine', main: true },
          { image: 'ig-12', name: 'IG-12' },
        ]
      },
      {
        videoThumb: 'uHKDi1QLOig?t=3684&si=U6ksmo3V0YNW5geL',
        feats: [
          { name: 'UFUs', qty: 1 },
          { name: 'Armor Shred', qty: 2 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'reva', name: 'Reva', main: true },
          { image: 'gi', name: 'Grand Inquisitor' },
          { image: 'seventh-sister', name: '7th Sister' },
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'eighth-brother', name: '8th Bro', main: true },
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: `Battle vs General Grievous with Shin defeating an enemy and with a full squad of Nightsisters`,
    keycards: 7,
    sectorBoss: true,
    plan: 'day2',
    sector: 2,
    teams: [
      {
        name: 'Shin',
        boss: 'gg',
        videoThumb: 'uHKDi1QLOig?t=3078&si=TkRynhaHuYhsUhml',
        default: true,
        toons: [
          { image: 'lv', name: 'Lord Vader' },
          { image: 'rg', name: 'Royal Guard' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'vader', name: 'Darth Vader' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        name: 'Nightsisters',
        boss: 'gg',
        default: true,
        videoThumb: 'uHKDi1QLOig?t=2560&si=C_UjaVagRUrUaVjf',
        feats: [
          { name: 'Great Mothers', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Admiral Raddus with Ezra (Exile) defeating an enemy and with a full squad of Mercenaries',
    keycards: 9,
    sectorBoss: true,
    plan: 'day3',
    sector: 2,
    teams: [
      {
        name: 'Ezra',
        videoThumb: 'uHKDi1QLOig?t=6000&si=B5sN2lYG7xRFguaN',
        boss: 'raddus',
        default: true,
        feats: [
          { name: 'Potency Up', qty: 5 },
          { name: 'Armor Shred', qty: 2 },
        ],
        toons: [          
          { image: 'jml', name: 'Master Luke' },
          { image: 'kam', name: 'KAM' },
          { image: 'gas', name: 'GAS' },
          { image: 'ezra-exile', name: 'Ezra Exile', main: true },
          { image: 'kyle', name: 'Kyle' },
        ]
      },
      {
        name: 'Mercenaries',
        default: true,
        boss: 'raddus',
        videoThumb: 'uHKDi1QLOig?t=6280&si=5U2KtehNbTtb6utI',
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'han', name: 'Han' },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'vandor-chewie', name: 'Vandor Chewie' },
          { image: 'jango', name: 'Jango' },
        ]
      },
    ]
  }
];

export {
  sector2
}