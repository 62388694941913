const sector5 = [
  {
    title: 'Critical Damage Up',
    text: 'Gain Critical Damage Up 50 times',
    keycards: 10,
    qty: 10,
    plan: [
      { day: 5, qty: 2, sector: 5 },
      { day: 6, qty: 2, sector: 5 },
    ],
    sector: 5,
    teams: [
      {
        videoThumb: 'VDXMM_JF_QU?t=988&si=6Xoz9IyCSFT3Ph4H',
        default: true,
        feats: [
          { name: 'Critical Damage Up', qty: 10 },
          { name: 'Evasion', qty: 10 },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi', main: true },
          { image: 'jkr', name: 'Jedi Revan', main: true },
          { image: 'gmy', name: 'Master Yoda' },
          { image: 'hermit', name: 'Hermit Yoda' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=1162&si=6Md24vygXKUiSXki',
        feats: [
          { name: 'Critical Damage Up', qty: 10 },
          { name: 'Evasion', qty: 5 },
        ],
        toons: [
          { image: 'dash', name: 'Dash', main: true },
          { image: 'young-han', name: 'Young Han', main: true },
          { image: 'bam', name: 'BAM' },
          { image: 'han', name: 'Han', main: true },
          { image: 'ig-11', name: 'IG-11' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=1253&si=bNU0lHrHSTlOrUnf',
        feats: [
          { name: 'Critical Damage Up', qty: 5 },
          { name: 'Evasion', qty: 5 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'reva', name: 'Reva' },
          { image: 'gi', name: 'Grand Inquisitor', main: true },
          { image: 'seventh-sister', name: '7th Sister' },
          { image: 'fifth-brother', name: '5th Brother' },
          { image: 'eighth-brother', name: '8th Bro' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=1370&si=slIwRkHLst3iULom',
        feats: [
          { name: 'Critical Damage Up', qty: 5 },
          { name: 'Evasion', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper', main: true },
          { image: 'starck', name: 'Starck', main: true },
          { image: 'snowtrooper', name: 'Snowtrooper', main: true },
          { image: 'shoretrooper', name: 'Shoretrooper' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=1665&si=GUV6uV4KW10-Ado7',
        feats: [
          { name: 'Critical Damage Up', qty: 5 },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'biggs', name: 'Biggs', main: true },
          { image: 'pao', name: 'Pao' },
          { image: 'hoth-scout', name: 'Hoth Scout' },
          { image: 'kyle', name: 'Kyle' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=1854&si=9Y5KCF7AhxJzPvoT',
        feats: [
          { name: 'Critical Damage Up', qty: 5 },
          { name: 'Evasion', qty: 5 },
        ],
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'biggs', name: 'Biggs', main: true },
          { image: 'leia', name: 'Leia', main: true },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=1953&si=SQe41Aq62ZMZtW_z',
        feats: [
          { name: 'Critical Damage Up', qty: 6 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'slkr', name: 'SLKR', main: true },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=2057&si=EWpc9xlZaFZQt7s7',
        feats: [
          { name: 'Critical Damage Up', qty: 10 },
          { name: 'Evasion', qty: 10 },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'rjt', name: 'RJT', main: true },
          { image: 'cal', name: 'Cal', main: true },
          { image: 'fulcrum', name: 'Fulcrum' },
          { image: 'scavenger', name: 'Scavenger Rey' },
        ]
      },
    ]
  },
  {
    title: 'Imperial Remnants',
    text: 'Win 10 battles with a full squad of Imperial Remnants',
    keycards: 15,
    qty: 10,
    plan: [
      { day: 5, qty: 4, sector: 5 },
      { day: 6, qty: 4, sector: 5 },
      { day: 13, qty: 2, sector: 5 },
    ],
    sector: 5,
    teams: [
      {
        videoThumb: 'VDXMM_JF_QU?t=3233&si=mDxPHbpehvtUP11c',
        default: true,
        feats: [
          { name: 'Critical Damage Up', qty: 5 },
          { name: 'Imperial Remnants', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'enoch', name: 'Enoch' },
          { image: 'peridea', name: 'Peridea' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
        ]
      },
      {
        videoThumb: '0NpXGIB23Jw?t=320&si=0GHYyD0sNkEmNSyp',
        feats: [
          { name: 'Imperial Remnants', qty: 1 },
        ],
        toons: [
          { image: 'gideon', name: 'Gideon' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'tfp', name: 'Tie Fighter' },
          { image: 'dark-trooper', name: 'Dark Trooper' },
          { image: 'scout-trooper', name: 'Stormtrooper' },
        ]
      },
    ]
  },
  {
    title: 'Morgan & GM',
    text: 'Win 10 battles with Morgan and Great Mothers surviving',
    keycards: 15,
    qty: 10,
    plan: [
      { day: 5, qty: 2, sector: 5 },
      { day: 6, qty: 2, sector: 5 },
      { day: 12, qty: 2, sector: 5 },
      { day: 13, qty: 2, sector: 5 },
      { day: 14, qty: 2, sector: 5 },
    ],
    sector: 5,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=387&si=LOA_DGprcufva5u8',
        feats: [
          { name: 'Morgan & GM', qty: 1 },
        ],
        toons: [
          { image: 'gas', name: 'GAS' },
          { image: 'rex', name: 'Rex' },
          { image: 'echo', name: 'Echo' },
          { image: 'gm', name: 'Great Mothers', main: true  },
          { image: 'morgan', name: 'Morgan', main: true  },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=3348&si=cExiCk4elruTJsbm',
        default: true,
        feats: [
          { name: 'Morgan & GM', qty: 1 },
          { name: 'Evasion', qty: 10 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers', main: true  },
          { image: 'morgan', name: 'Morgan', main: true  },
          { image: 'zombie', name: 'Zombie' },
          { image: 'spirit', name: 'Spirit' },
          { image: 'merrin', name: 'Merrin' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=3547&si=NwWFwWLkpap3J0Jy',
        feats: [
          { name: 'Critical Damage Up', qty: 6 },
          { name: 'Morgan & GM', qty: 1 },
        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'hux', name: 'Hux' },
          { image: 'gm', name: 'Great Mothers', main: true  },
          { image: 'morgan', name: 'Morgan', main: true  },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
    ]
  },
  {
    title: 'Evasion',
    text: 'Evade at least 100 attacks in battles you won',
    keycards: 15,
    qty: 10,
    plan: [
      { day: 5, qty: 2, sector: 5 },
      { day: 6, qty: 2, sector: 5 },
    ],
    sector: 5,
    teams: [
      {
        videoThumb: 'VDXMM_JF_QU?t=4968&si=otboHp_h2uroCO5A',
        feats: [
          { name: 'Critical Damage Up', qty: 10 },
          { name: 'Evasion', qty: 10 },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'padawan-obi', name: 'Padawan Obi' },
          { image: 'jkr', name: 'Jedi Revan', main: true },
          { image: 'gmy', name: 'Master Yoda', main: true },
          { image: 'hermit', name: 'Hermit Yoda', main: true },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=4510&si=4aEra0FqryDFbOjj',
        feats: [
          { name: 'Critical Damage Up', qty: 10 },
          { name: 'Evasion', qty: 10 },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'rjt', name: 'RJT', main: true },
          { image: 'cal', name: 'Cal', main: true },
          { image: 'fulcrum', name: 'Fulcrum', main: true },
          { image: 'scavenger', name: 'Scavenger Rey', main: true },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=4336&si=XVZOTBOqHhz21p5K',
        feats: [
          { name: 'Evasion', qty: 4 },
        ],
        global: [
          { name: 'Galactic Republic' },
        ],
        toons: [
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'BB Echo' },
          { image: 'tech', name: 'Tech', main: true },
          { image: 'wrecker', name: 'Wrecker' },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=3868&si=7RDMEkHSmvgSGD4y',
        feats: [
          { name: 'Evasion', qty: 10 },
        ],
        global: [
          { name: 'Galactic Republic' },
        ],
        toons: [
          { image: 'jmk', name: 'Master Kenobi', main: true },
          { image: 'mace', name: 'Mace', main: true },
          { image: 'gk', name: 'General Kenobi', main: true },
          { image: 'cat', name: 'Commander AHsoka' },
          { image: 'kelleran', name: 'Kelleran', main: true },
        ]
      },
      {
        feats: [
          { name: 'Evasion', qty: 10 },
        ],
        default: true,
        videoThumb: 'VDXMM_JF_QU?t=4020&si=yTrpoDsD15TDuZSE',
        toons: [
          { image: 'qgj', name: 'Qui Gon Jinn', main: true },
          { image: 'jkl', name: 'Jedi Luke', main: true },
          { image: 'jkr', name: 'Jedi Revan', main: true },
          { image: 'gmy', name: 'Master Yoda', main: true },
          { image: 'hermit', name: 'Hermit Yoda', main: true },
        ]
      }
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Darth Revan with Shin surviving and without Attackers',
    keycards: 9,
    sectorBoss: true,
    plan: 'day5',
    sector: 5,
    teams: [
      {
        name: 'Shin',
        boss: 'dr',
        videoThumb: 'VDXMM_JF_QU?t=2582&si=dwT70r55Wck33gbU',
        default: true,
        feats: [
          { name: 'Critical Damage Up', qty: 6 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'mara', name: 'Mara' },
          { image: 'kru', name: 'KRU' },
          { image: 'vader', name: 'Darth Vader' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        boss: 'dr',
        videoThumb: 'VDXMM_JF_QU?t=2788&si=xZOBLq7fzdsK2V06',
        feats: [
          { name: 'Critical Damage Up', qty: 6 },
        ],
        global: [
          { name: 'Dark UFUs' },
        ],
        toons: [
          { image: 'slkr', name: 'SLKR' },
          { image: 'kru', name: 'KRU' },
          { image: 'kylo', name: 'Kylo' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin', main: true },
        ]
      },
      {
        name: 'No Attackers',
        boss: 'dr',
        default: true,
        videoThumb: 'VDXMM_JF_QU?t=2917&si=6T5016Kk8TSPfKx6',
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'range', name: 'Range' },
          { image: 'shoretrooper', name: 'Shoretrooper' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Boss Nass with Baylan Skoll in your squad and with Light Side only',
    keycards: 12,
    sectorBoss: true,
    plan: 'day6',
    sector: 5,
    teams: [
      {
        name: 'Baylan',
        default: true,
        boss: 'dr',
        videoThumb: '',
        toons: [
          { image: 'cup', name: 'CUP' },
        ]
      },
      {
        name: 'Light Side',
        default: true,
        videoThumb: 'VDXMM_JF_QU?t=5755&si=yhTTiFZt2Owv_rjs',
        boss: 'dr',
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'rex', name: 'Rex' },
          { image: 'fives', name: 'Fives' },
          { image: 'echo', name: 'Echo' },
          { image: 'arc', name: 'ARC' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=6012&si=jCm7jxZgrpkMuJmE',
        boss: 'dr',
        toons: [
          { image: 'cls', name: 'CLS' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: '3po', name: 'C-3PO' },
        ]
      },
      {
        videoThumb: 'VDXMM_JF_QU?t=6170&si=i_e2fVkI2g1W3Yq3',
        boss: 'dr',
        toons: [
          { image: 'hera', name: 'Hera' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'chopper', name: 'Chopper' },
          { image: 'sabine', name: 'Sabine' },
          { image: 'kanan', name: 'Kanan' },
        ]
      }
    ]
  }
];

export {
  sector5
}