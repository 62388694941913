const sector1 = [  
  {
    title: 'Crit Damage Down',
    text: 'Attempt to inflict Critical Damage Down 50 times',
    keycards: 5,
    qty: 50,
    plan: [
      { day: 1, qty: 2, sector: 1 },
      { day: 2, qty: 2, sector: 1 },
    ],
    sector: 1,
    teams: [
      {
        default: true,
        videoThumb: 'ptb8p75cmwk?t=991&si=clMzqteZSACSF-zB',
        feats: [
          { name: 'Crit Damage Down', qty: 10 },
          { name: 'Mercenaries', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'dash', name: 'Dash', main: true },
          { image: 'han', name: 'Han' },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=1150&si=BZUIAbq-n0gSWNPd',
        feats: [
          { name: 'Crit Damage Down', qty: 10 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down', qty: 5 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine'},
          { image: 'fallen-basti', name: 'Fallen Basti', main: true },
          { image: 'tarkin', name: 'Tarkin' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'morgan', name: 'Morgan' },
        ]
      },
    ]
  },

  {
    title: 'Morgan',
    text: 'Win 10 battles with Morgan Elsbeth surviving',
    keycards: 5,
    qty: 10,
    plan: [
      { day: 1, qty: 2, sector: 1 },
      { day: 7, qty: 2, sector: 1 },
      { day: 8, qty: 2, sector: 1 },
      { day: 9, qty: 3, sector: 1 },
    ],
    sector: 1,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: '0NpXGIB23Jw?t=945&si=xpiTGcvcoiWV1UOs',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'nest', name: 'Nest' },
          { image: 'cara', name: 'Cara' },
          { image: 'skiff-lando', name: 'Skiff Lando' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=872&si=0p8QMSQzoyPf9zsn',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'gm', name: 'Great Mothers' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'zombie', name: 'Zombie' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=1597&si=bd6n2zsPB6cBlvPi',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [          
          { image: 'talzin', name: 'Talzin' },
          { image: 'morgan', name: 'Morgan', main: true },
          { image: 'zombie', name: 'Zombie' },
          { image: 'merrin', name: 'Merrin'},
          { image: 'asajj', name: 'Asajj' }
        ]
      },
      {
        default: true,
        videoThumb: 'ptb8p75cmwk?t=1150&si=BZUIAbq-n0gSWNPd',
        feats: [
          { name: 'Crit Damage Down', qty: 10 },
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Crit Chance Down', qty: 5 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine'},
          { image: 'fallen-basti', name: 'Fallen Basti' },
          { image: 'tarkin', name: 'Tarkin' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'morgan', name: 'Morgan', main: true },
        ]
      },
    ]
  },
  {
    title: 'Mercenaries',
    text: 'Wint 10 battles with a full squad of Mercenaries',
    keycards: 5,
    qty: 10,
    plan: [
      { day: 1, qty: 2, sector: 1 },
      { day: 7, qty: 2, sector: 1 },
      { day: 8, qty: 2, sector: 1 },
    ],
    sector: 1,
    teams: [
      {
        videoThumb: 'ptb8p75cmwk?t=991&si=clMzqteZSACSF-zB',
        feats: [
          { name: 'Crit Damage Down', qty: 10 },
          { name: 'Mercenaries', qty: 1 },
        ],
        global: [
          { name: 'Marrok & Shin' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'han', name: 'Han' },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'marrok', name: 'Marrok' },
          { image: 'shin', name: 'Shin' },
        ]
      },
      {
        default: true,
        videoThumb: 'ptb8p75cmwk?t=2340&si=WKdhJj4_i4uNG_Qe',
        feats: [
          { name: 'Mercenaries', qty: 1 },
        ],
        toons: [
          { image: 'aurra', name: 'Aurra' },
          { image: 'fennec', name: 'Fennec' },
          { image: 'jango', name: 'Jango' },
          { image: 'dengar', name: 'Dengar' },
          { image: 'zam', name: 'Zam' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=2617&si=3svBBjpE4bd0zPlK',
        feats: [
          { name: 'Mercenaries', qty: 1 },
        ],
        toons: [
          { image: 'aphra', name: 'Aphra' },
          { image: 'krrsantan', name: 'Krrsantan' },
          { image: 'embo', name: 'Embo' },
          { image: 'canderous', name: 'Candy' },
          { image: 'old-boba', name: 'Old Boba' },
        ]
      },
      {
        videoThumb: 'ptb8p75cmwk?t=2968&si=q-KOHipF3nbkBe_9',
        feats: [
          { name: 'Mercenaries', qty: 1 },
        ],
        toons: [
          { image: 'qira', name: 'Qira' },
          { image: 'l3', name: 'L3' },
          { image: 'vandor-chewie', name: 'Vandor Chewie' },
          { image: 'young-han', name: 'Young Han' },
          { image: 'hondo', name: 'Hondo' },
        ]
      }
    ]
  },
  {
    title: 'Blight',
    text: 'Attempt to inflict or gain Blight 40 times',
    keycards: 5,
    qty: 40,
    plan: [
      { day: 1, qty: 2, sector: 1 }
    ],
    sector: 1,
    teams: [
      {
        videoThumb: 'ptb8p75cmwk?t=2042&si=7ZqqnNZagoHU583i',
        default: true,
        feats: [
          { name: 'Blight', qty: 40 }
        ],
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [
          { image: 'enoch', name: 'Enoch', main: true },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper', main: true },
          { image: 'death-trooper', name: 'Death Trooper' },
        ]
      }
    ]
  },
  {
    title: 'Mini Boss',
    text: `Battle vs Cere with Death Trooper (Peridea) defeating an enemy and without using Attackers in your squad`,
    keycards: 7,
    sectorBoss: true,
    plan: 'day1',
    sector: 1,
    teams: [
      {
        boss: 'cere',
        name: `Peridea`,
        default: true,
        videoThumb: 'ptb8p75cmwk?t=3340&si=R_gyUcB4RjL3KwGn',
        global: [
          { name: 'Crit Chance Down' },
        ],
        toons: [          
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        boss: 'cere',
        videoThumb: 'ptb8p75cmwk?t=3608&si=-HLQ2zHUyPmwQJ-F',
        global: [
          { name: 'Crit Chance Down' },
          { name: 'Nightsisters' },
        ],
        toons: [          
          { image: 'gm', name: 'Great Mothers' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'peridea', name: 'Peridea', main: true },
          { image: 'night-trooper', name: 'Night Trooper' },
          { image: 'morgan', name: 'Morgan' },
        ]
      },
      {
        boss: 'cere',
        name: `No Attackers`,
        default: true,
        videoThumb: 'ptb8p75cmwk?t=4010&si=q7hARVjfHMRaWtcu',
        feats: [
          { name: 'Morgan', qty: 1 },
        ],
        global: [
          { name: 'Nightsisters' },
        ],
        toons: [          
          { image: 'gm', name: 'Great Mothers' },
          { image: 'talzin', name: 'Talzin' },
          { image: 'morgan', name: 'Morgan' },
          { image: 'zombie', name: 'Zombie' },
          { image: 'asajj', name: 'Asajj' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Kelleran Beq with Luthen in your squad and Marrok surviving',
    keycards: 9,
    sectorBoss: true,
    plan: 'day2',
    sector: 1,
    teams: [
      {
        boss: 'kelleran',
        name: 'All Feats',
        default: true,
        videoThumb: 'ptb8p75cmwk?t=6208&si=SuUyJS7ciP6kjH95',
        toons: [
          { image: 'dr', name: 'Darth Revan'  },
          { image: 'malak', name: 'Malak' },
          { image: 'malgus', name: 'Malgus' },
          { image: 'marrok', name: 'Marrok', main: true },
          { image: 'luthen', name: 'Luthen', main: true },
        ]
      },
      {
        boss: 'kelleran',
        videoThumb: 'ptb8p75cmwk?t=6400&si=V94lvlAHQ63udpfu',
        toons: [
          { image: 'jml', name: 'Master Luke'  },
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'wat', name: 'Wat' },
          { image: 'marrok', name: 'Marrok', main: true },
          { image: 'luthen', name: 'Luthen', main: true },
        ]
      },
    ]
  }
];

export {
  sector1
}